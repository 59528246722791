import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

const CopyToClipboard = ({ value, showValue }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="relative">
      <div className="copy-to-clipboard flex items-center">
        <button
          className="border-primary bg-primary border px-8 py-2 rounded-md text-white font-bold"
          id="copy"
          variant="outline"
          onClick={handleCopy}
        >
          {copied ? (
            "Copied!"
          ) : showValue ? (
            <span
              className="whitespace-nowrap overflow-hidden overflow-ellipsis block max-w-full"
              style={{ maxWidth: "250px" }}
            >
              {value}
            </span>
          ) : (
            "Copy"
          )}
        </button>
        <Tooltip id="tooltip" place="right" effect="solid">
          {copied ? "Copied!" : "Copy to clipboard"}
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyToClipboard;
