export const PROD_URL = "https://www.topautodeals.eu:8000";
export const BACKEND_URL = "http://localhost:8000";

export const fuelTypeOptions = [
  { label: "Petrol", id: 1, option: "petrol" },
  { label: "Diesel", id: 2, option: "diesel" },
  { label: "Electric", id: 3, option: "electric" },
  { label: "Hybrid", id: 4, option: "hybrid" },
];

export const countryOptions = [
  { label: "Germany", id: 1, option: "de" },
  { label: "Netherlands", id: 2, option: "nl" },
  { label: "Belgium", id: 3, option: "be" },
  { label: "France", id: 4, option: "fr" },
];

export const gearboxOptions = [
  { label: "Automatic", id: 1, option: "automatic" },
  { label: "Manual", id: 2, option: "manual" },
];

export const yearOptions = Array.from({ length: 30 }, (_, i) => ({
  label: (2025 - i).toString(),
  id: 2025 - i,
  option: (2025 - i).toString(),
}));

export const carBrands = [
  { label: "Toyota", id: 1 },
  { label: "Honda", id: 2 },
  { label: "Ford", id: 3 },
  { label: "Chevrolet", id: 4 },
  { label: "Nissan", id: 5 },
  { label: "BMW", id: 6 },
  { label: "Mercedes-Benz", id: 7 },
  { label: "Volkswagen", id: 8 },
  { label: "Audi", id: 9 },
  { label: "Hyundai", id: 10 },
  { label: "Kia", id: 11 },
  { label: "Lexus", id: 12 },
  { label: "Subaru", id: 13 },
  { label: "Mazda", id: 14 },
  { label: "Dodge", id: 15 },
  { label: "Jeep", id: 16 },
  { label: "Tesla", id: 17 },
  { label: "Porsche", id: 18 },
  { label: "Jaguar", id: 19 },
  { label: "Land Rover", id: 20 },
  { label: "Ferrari", id: 21 },
  { label: "Lamborghini", id: 22 },
  { label: "Bentley", id: 23 },
  { label: "Rolls-Royce", id: 24 },
  { label: "Mitsubishi", id: 25 },
  { label: "Volvo", id: 26 },
  { label: "Infiniti", id: 27 },
  { label: "Acura", id: 28 },
  { label: "Buick", id: 29 },
  { label: "Cadillac", id: 30 },
  { label: "Chrysler", id: 31 },
  { label: "Fiat", id: 32 },
  { label: "Genesis", id: 33 },
  { label: "GMC", id: 34 },
  { label: "Lincoln", id: 35 },
  { label: "Maserati", id: 36 },
  { label: "Mini", id: 37 },
  { label: "Peugeot", id: 38 },
  { label: "Renault", id: 39 },
  { label: "Saab", id: 40 },
  { label: "Suzuki", id: 41 },
  { label: "Alfa Romeo", id: 42 },
  { label: "Aston Martin", id: 43 },
  { label: "Bugatti", id: 44 },
  { label: "Citroën", id: 45 },
  { label: "Hummer", id: 46 },
  { label: "Isuzu", id: 47 },
  { label: "Lotus", id: 48 },
  { label: "McLaren", id: 49 },
  { label: "Pagani", id: 50 },
  { label: "Pontiac", id: 51 },
  { label: "Scion", id: 52 },
  { label: "Smart", id: 53 },
  { label: "Tata", id: 54 },
  { label: "Vauxhall", id: 55 },
  { label: "Skoda", id: 56 },
  { label: "Seat", id: 57 },
];

export const carModels = [
  { label: "520i", id: 0, option: "520i" },
  { label: "Accord", id: 1, option: "Accord" },
  { label: "Civic", id: 2, option: "Civic" },
  { label: "Corolla", id: 3, option: "Corolla" },
  { label: "Camry", id: 4, option: "Camry" },
  { label: "Focus", id: 5, option: "Focus" },
  { label: "Mustang", id: 6, option: "Mustang" },
  { label: "F-150", id: 7, option: "F-150" },
  { label: "Silverado", id: 8, option: "Silverado" },
  { label: "Sentra", id: 9, option: "Sentra" },
  { label: "Altima", id: 10, option: "Altima" },
  { label: "3 Series", id: 11, option: "3 Series" },
  { label: "5 Series", id: 12, option: "5 Series" },
  { label: "7 Series", id: 13, option: "7 Series" },
  { label: "X3", id: 14, option: "X3" },
  { label: "X5", id: 15, option: "X5" },
  { label: "A4", id: 16, option: "A4" },
  { label: "A6", id: 17, option: "A6" },
  { label: "Q5", id: 18, option: "Q5" },
  { label: "Q7", id: 19, option: "Q7" },
  { label: "C-Class", id: 20, option: "C-Class" },
  { label: "E-Class", id: 21, option: "E-Class" },
  { label: "S-Class", id: 22, option: "S-Class" },
  { label: "GLA", id: 23, option: "GLA" },
  { label: "GLC", id: 24, option: "GLC" },
  { label: "Golf", id: 25, option: "Golf" },
  { label: "Passat", id: 26, option: "Passat" },
  { label: "Tiguan", id: 27, option: "Tiguan" },
  { label: "Jetta", id: 28, option: "Jetta" },
  { label: "Ram 1500", id: 29, option: "Ram 1500" },
  { label: "Cherokee", id: 30, option: "Cherokee" },
  { label: "Wrangler", id: 31, option: "Wrangler" },
  { label: "Model S", id: 32, option: "Model S" },
  { label: "Model 3", id: 33, option: "Model 3" },
  { label: "Model X", id: 34, option: "Model X" },
  { label: "Model Y", id: 35, option: "Model Y" },
  { label: "911", id: 36, option: "911" },
  { label: "Cayenne", id: 37, option: "Cayenne" },
  { label: "Macan", id: 38, option: "Macan" },
  { label: "Panamera", id: 39, option: "Panamera" },
  { label: "Range Rover", id: 40, option: "Range Rover" },
  { label: "Discovery", id: 41, option: "Discovery" },
  { label: "Defender", id: 42, option: "Defender" },
  { label: "F-Type", id: 43, option: "F-Type" },
  { label: "XE", id: 44, option: "XE" },
  { label: "XC90", id: 45, option: "XC90" },
  { label: "S60", id: 46, option: "S60" },
  { label: "V60", id: 47, option: "V60" },
  { label: "MX-5", id: 48, option: "MX-5" },
  { label: "CX-5", id: 49, option: "CX-5" },
  { label: "CX-9", id: 50, option: "CX-9" },
  { label: "Outlander", id: 51, option: "Outlander" },
  { label: "Pajero", id: 52, option: "Pajero" },
  { label: "XV", id: 53, option: "XV" },
  { label: "Forester", id: 54, option: "Forester" },
  { label: "Impreza", id: 55, option: "Impreza" },
  { label: "Legacy", id: 56, option: "Legacy" },
  { label: "Fusion", id: 57, option: "Fusion" },
  { label: "Escape", id: 58, option: "Escape" },
  { label: "Explorer", id: 59, option: "Explorer" },
  { label: "Expedition", id: 60, option: "Expedition" },
  { label: "Edge", id: 61, option: "Edge" },
  { label: "Ranger", id: 62, option: "Ranger" },
  { label: "Colorado", id: 63, option: "Colorado" },
  { label: "Tahoe", id: 64, option: "Tahoe" },
  { label: "Equinox", id: 65, option: "Equinox" },
  { label: "Trailblazer", id: 66, option: "Trailblazer" },
  { label: "Cruze", id: 67, option: "Cruze" },
  { label: "Malibu", id: 68, option: "Malibu" },
  { label: "Impala", id: 69, option: "Impala" },
  { label: "Spark", id: 70, option: "Spark" },
  { label: "Volt", id: 71, option: "Volt" },
  { label: "Sonata", id: 72, option: "Sonata" },
  { label: "Elantra", id: 73, option: "Elantra" },
  { label: "Kona", id: 74, option: "Kona" },
  { label: "Tucson", id: 75, option: "Tucson" },
  { label: "Santa Fe", id: 76, option: "Santa Fe" },
  { label: "Palisade", id: 77, option: "Palisade" },
  { label: "Telluride", id: 78, option: "Telluride" },
  { label: "Civic Type R", id: 79, option: "Civic Type R" },
  { label: "Pilot", id: 80, option: "Pilot" },
  { label: "CR-V", id: 81, option: "CR-V" },
  { label: "HR-V", id: 82, option: "HR-V" },
  { label: "Passport", id: 83, option: "Passport" },
  { label: "Odyssey", id: 84, option: "Odyssey" },
  { label: "Ridgeline", id: 85, option: "Ridgeline" },
  { label: "Accord Hybrid", id: 86, option: "Accord Hybrid" },
  { label: "Clarity", id: 87, option: "Clarity" },
  { label: "Insight", id: 88, option: "Insight" },
  { label: "Fit", id: 89, option: "Fit" },
  { label: "Clarity Plug-In Hybrid", id: 90, option: "Clarity Plug-In Hybrid" },
  { label: "CR-Z", id: 91, option: "CR-Z" },
  { label: "Sienna", id: 92, option: "Sienna" },
  { label: "Camry Hybrid", id: 93, option: "Camry Hybrid" },
  { label: "Prius", id: 94, option: "Prius" },
  { label: "Rav4", id: 95, option: "Rav4" },
  { label: "Highlander", id: 96, option: "Highlander" },
  { label: "Land Cruiser", id: 97, option: "Land Cruiser" },
  { label: "Tacoma", id: 98, option: "Tacoma" },
  { label: "4Runner", id: 99, option: "4Runner" },
  { label: "Tundra", id: 100, option: "Tundra" },
  { label: "Yaris", id: 101, option: "Yaris" },
  { label: "Avalon", id: 102, option: "Avalon" },
  { label: "Mirai", id: 103, option: "Mirai" },
  { label: "86", id: 104, option: "86" },
  { label: "Supra", id: 105, option: "Supra" },
  { label: "Sequoia", id: 106, option: "Sequoia" },
  { label: "LX", id: 107, option: "LX" },
  { label: "RX", id: 108, option: "RX" },
  { label: "NX", id: 109, option: "NX" },
  { label: "UX", id: 110, option: "UX" },
  { label: "RC", id: 111, option: "RC" },
  { label: "IS", id: 112, option: "IS" },
  { label: "ES", id: 113, option: "ES" },
  { label: "LS", id: 114, option: "LS" },
  { label: "LC", id: 115, option: "LC" },
  { label: "GS", id: 116, option: "GS" },
  { label: "Camaro", id: 117, option: "Camaro" },
  { label: "Malibu", id: 118, option: "Malibu" },
  { label: "Volt", id: 119, option: "Volt" },
  { label: "Colorado", id: 120, option: "Colorado" },
  { label: "Equinox", id: 121, option: "Equinox" },
  { label: "Trailblazer", id: 122, option: "Trailblazer" },
  { label: "Blazer", id: 123, option: "Blazer" },
  { label: "Silverado 1500", id: 124, option: "Silverado 1500" },
  { label: "Suburban", id: 125, option: "Suburban" },
  { label: "Tahoe", id: 126, option: "Tahoe" },
  { label: "Traverse", id: 127, option: "Traverse" },
  { label: "Sonic", id: 128, option: "Sonic" },
  { label: "Trax", id: 129, option: "Trax" },
  { label: "Impala", id: 130, option: "Impala" },
  { label: "Cruze", id: 131, option: "Cruze" },
  { label: "Express", id: 132, option: "Express" },
  { label: "Equinox", id: 133, option: "Equinox" },
  { label: "Malibu", id: 134, option: "Malibu" },
  { label: "Silverado 1500", id: 135, option: "Silverado 1500" },
  { label: "Tahoe", id: 136, option: "Tahoe" },
  { label: "Traverse", id: 137, option: "Traverse" },
  { label: "EcoSport", id: 138, option: "EcoSport" },
  { label: "Edge", id: 139, option: "Edge" },
  { label: "Escape", id: 140, option: "Escape" },
  { label: "Expedition", id: 141, option: "Expedition" },
  { label: "Explorer", id: 142, option: "Explorer" },
  { label: "F-150", id: 143, option: "F-150" },
  { label: "Fiesta", id: 144, option: "Fiesta" },
  { label: "Focus", id: 145, option: "Focus" },
  { label: "Fusion", id: 146, option: "Fusion" },
  { label: "Mustang", id: 147, option: "Mustang" },
  { label: "Ranger", id: 148, option: "Ranger" },
  { label: "Transit", id: 149, option: "Transit" },
  { label: "Transit Connect", id: 150, option: "Transit Connect" },
  { label: "Ghibli", id: 151, option: "Ghibli" },
  { label: "Levante", id: 152, option: "Levante" },
  { label: "Quattroporte", id: 153, option: "Quattroporte" },
  { label: "GranTurismo", id: 154, option: "GranTurismo" },
  { label: "MX-5 Miata", id: 155, option: "MX-5 Miata" },
  { label: "CX-30", id: 156, option: "CX-30" },
  { label: "CX-9", id: 157, option: "CX-9" },
  { label: "Mazda3", id: 158, option: "Mazda3" },
  { label: "Mazda6", id: 159, option: "Mazda6" },
  { label: "CX-3", id: 160, option: "CX-3" },
  { label: "Forester", id: 161, option: "Forester" },
  { label: "Outback", id: 162, option: "Outback" },
  { label: "Legacy", id: 163, option: "Legacy" },
  { label: "Ascent", id: 164, option: "Ascent" },
  { label: "BRZ", id: 165, option: "BRZ" },
  { label: "WRX", id: 166, option: "WRX" },
  { label: "Accent", id: 167, option: "Accent" },
  { label: "Elantra", id: 168, option: "Elantra" },
  { label: "Ioniq", id: 169, option: "Ioniq" },
  { label: "Kona", id: 170, option: "Kona" },
  { label: "Nexo", id: 171, option: "Nexo" },
  { label: "Palisade", id: 172, option: "Palisade" },
  { label: "Santa Fe", id: 173, option: "Santa Fe" },
  { label: "Sonata", id: 174, option: "Sonata" },
  { label: "Tucson", id: 175, option: "Tucson" },
  { label: "Veloster", id: 176, option: "Veloster" },
  { label: "Venue", id: 177, option: "Venue" },
  { label: "NX", id: 178, option: "NX" },
  { label: "RX", id: 179, option: "RX" },
  { label: "UX", id: 180, option: "UX" },
  { label: "GX", id: 181, option: "GX" },
  { label: "LX", id: 182, option: "LX" },
  { label: "LC", id: 183, option: "LC" },
  { label: "LS", id: 184, option: "LS" },
  { label: "RC", id: 185, option: "RC" },
  { label: "IS", id: 186, option: "IS" },
  { label: "ES", id: 187, option: "ES" },
  { label: "Mirage", id: 188, option: "Mirage" },
  { label: "Outlander", id: 189, option: "Outlander" },
  { label: "Outlander Sport", id: 190, option: "Outlander Sport" },
  { label: "Eclipse Cross", id: 191, option: "Eclipse Cross" },
  { label: "300", id: 192, option: "300" },
  { label: "Pacifica", id: 193, option: "Pacifica" },
  { label: "Voyager", id: 194, option: "Voyager" },
  { label: "Aspen", id: 195, option: "Aspen" },
  { label: "Crossfire", id: 196, option: "Crossfire" },
  { label: "Sebring", id: 197, option: "Sebring" },
  { label: "Challenger", id: 198, option: "Challenger" },
  { label: "Charger", id: 199, option: "Charger" },
  { label: "Durango", id: 200, option: "Durango" },
  { label: "Grand Caravan", id: 201, option: "Grand Caravan" },
  { label: "Journey", id: 202, option: "Journey" },
  { label: "Nitro", id: 203, option: "Nitro" },
  { label: "RAM 1500", id: 204, option: "RAM 1500" },
  { label: "RAM 2500", id: 205, option: "RAM 2500" },
  { label: "RAM 3500", id: 206, option: "RAM 3500" },
  { label: "ProMaster", id: 207, option: "ProMaster" },
  { label: "ProMaster City", id: 208, option: "ProMaster City" },
  { label: "200", id: 209, option: "200" },
  { label: "500", id: 210, option: "500" },
  { label: "500L", id: 211, option: "500L" },
  { label: "500X", id: 212, option: "500X" },
  { label: "Panda", id: 213, option: "Panda" },
  { label: "Tipo", id: 214, option: "Tipo" },
  { label: "Fullback", id: 215, option: "Fullback" },
  { label: "Mii", id: 216, option: "Mii" },
  { label: "Ibiza", id: 217, option: "Ibiza" },
  { label: "Leon", id: 218, option: "Leon" },
  { label: "Arona", id: 219, option: "Arona" },
  { label: "Ateca", id: 220, option: "Ateca" },
  { label: "Tarraco", id: 221, option: "Tarraco" },
  { label: "Alhambra", id: 222, option: "Alhambra" },
  { label: "Scala", id: 223, option: "Scala" },
  { label: "Kamiq", id: 224, option: "Kamiq" },
  { label: "Karoq", id: 225, option: "Karoq" },
  { label: "Kodiaq", id: 226, option: "Kodiaq" },
  { label: "Superb", id: 227, option: "Superb" },
  { label: "Octavia", id: 228, option: "Octavia" },
  { label: "Fabia", id: 229, option: "Fabia" },
  { label: "Citigo", id: 230, option: "Citigo" },
  { label: "Rapid", id: 231, option: "Rapid" },
  { label: "Vision iV", id: 232, option: "Vision iV" },
  { label: "Korando", id: 233, option: "Korando" },
  { label: "Tivoli", id: 234, option: "Tivoli" },
  { label: "Rexton", id: 235, option: "Rexton" },
  { label: "Musso", id: 236, option: "Musso" },
  { label: "Rodius", id: 237, option: "Rodius" },
  { label: "XLV", id: 238, option: "XLV" },
  { label: "911", id: 239, option: "911" },
  { label: "Panamera", id: 240, option: "Panamera" },
  { label: "Cayenne", id: 241, option: "Cayenne" },
  { label: "Macan", id: 242, option: "Macan" },
  { label: "Taycan", id: 243, option: "Taycan" },
  { label: "718", id: 244, option: "718" },
  { label: "Cayman", id: 245, option: "Cayman" },
  { label: "Boxster", id: 246, option: "Boxster" },
  { label: "918 Spyder", id: 247, option: "918 Spyder" },
  { label: "Taycan", id: 248, option: "Taycan" },
  { label: "718", id: 249, option: "718" },
  { label: "Cayman", id: 250, option: "Cayman" },
  { label: "Boxster", id: 251, option: "Boxster" },
  { label: "918 Spyder", id: 252, option: "918 Spyder" },
  { label: "Cullinan", id: 253, option: "Cullinan" },
  { label: "Ghost", id: 254, option: "Ghost" },
  { label: "Wraith", id: 255, option: "Wraith" },
  { label: "Dawn", id: 256, option: "Dawn" },
  { label: "Phantom", id: 257, option: "Phantom" },
  { label: "Bentayga", id: 258, option: "Bentayga" },
  { label: "Continental GT", id: 259, option: "Continental GT" },
  { label: "Flying Spur", id: 260, option: "Flying Spur" },
  { label: "Mulsanne", id: 261, option: "Mulsanne" },
  { label: "Escalade", id: 262, option: "Escalade" },
  { label: "XT6", id: 263, option: "XT6" },
  { label: "CT4", id: 264, option: "CT4" },
  { label: "CT5", id: 265, option: "CT5" },
  { label: "XT4", id: 266, option: "XT4" },
  { label: "XT5", id: 267, option: "XT5" },
  { label: "XTS", id: 268, option: "XTS" },
  { label: "CT6", id: 269, option: "CT6" },
  { label: "ATS", id: 270, option: "ATS" },
  { label: "CTS", id: 271, option: "CTS" },
  { label: "CT6-V", id: 272, option: "CT6-V" },
  { label: "CT4-V", id: 273, option: "CT4-V" },
  { label: "CT5-V", id: 274, option: "CT5-V" },
  { label: "ATS-V", id: 275, option: "ATS-V" },
  { label: "Escalade", id: 276, option: "Escalade" },
  { label: "XT4", id: 277, option: "XT4" },
  { label: "XT5", id: 278, option: "XT5" },
  { label: "XT6", id: 279, option: "XT6" },
  { label: "300", id: 280, option: "300" },
  { label: "Pacifica", id: 281, option: "Pacifica" },
  { label: "Voyager", id: 282, option: "Voyager" },
  { label: "Aspen", id: 283, option: "Aspen" },
  { label: "Crossfire", id: 284, option: "Crossfire" },
  { label: "Sebring", id: 285, option: "Sebring" },
  { label: "Challenger", id: 286, option: "Challenger" },
  { label: "Charger", id: 287, option: "Charger" },
  { label: "Durango", id: 288, option: "Durango" },
  { label: "Grand Caravan", id: 289, option: "Grand Caravan" },
  { label: "Journey", id: 290, option: "Journey" },
  { label: "Nitro", id: 291, option: "Nitro" },
  { label: "RAM 1500", id: 292, option: "RAM 1500" },
  { label: "RAM 2500", id: 293, option: "RAM 2500" },
  { label: "RAM 3500", id: 294, option: "RAM 3500" },
  { label: "ProMaster", id: 295, option: "ProMaster" },
  { label: "ProMaster City", id: 296, option: "ProMaster City" },
  { label: "200", id: 297, option: "200" },
  { label: "500", id: 298, option: "500" },
  { label: "500L", id: 299, option: "500L" },
  { label: "500X", id: 300, option: "500X" },
  { label: "Panda", id: 301, option: "Panda" },
  { label: "Tipo", id: 302, option: "Tipo" },
  { label: "Fullback", id: 303, option: "Fullback" },
  { label: "Mii", id: 304, option: "Mii" },
  { label: "Ibiza", id: 305, option: "Ibiza" },
  { label: "Leon", id: 306, option: "Leon" },
  { label: "Arona", id: 307, option: "Arona" },
  { label: "Ateca", id: 308, option: "Ateca" },
  { label: "Tarraco", id: 309, option: "Tarraco" },
  { label: "Alhambra", id: 310, option: "Alhambra" },
  { label: "Scala", id: 311, option: "Scala" },
  { label: "Kamiq", id: 312, option: "Kamiq" },
  { label: "Karoq", id: 313, option: "Karoq" },
  { label: "Kodiaq", id: 314, option: "Kodiaq" },
  { label: "Superb", id: 315, option: "Superb" },
  { label: "Octavia", id: 316, option: "Octavia" },
  { label: "Fabia", id: 317, option: "Fabia" },
  { label: "Citigo", id: 318, option: "Citigo" },
  { label: "Rapid", id: 319, option: "Rapid" },
  { label: "Vision iV", id: 320, option: "Vision iV" },
  { label: "Korando", id: 321, option: "Korando" },
  { label: "Tivoli", id: 322, option: "Tivoli" },
  { label: "Rexton", id: 323, option: "Rexton" },
  { label: "Musso", id: 324, option: "Musso" },
  { label: "Rodius", id: 325, option: "Rodius" },
  { label: "XLV", id: 326, option: "XLV" },
  { label: "911", id: 327, option: "911" },
  { label: "Panamera", id: 328, option: "Panamera" },
  { label: "Cayenne", id: 329, option: "Cayenne" },
  { label: "Macan", id: 330, option: "Macan" },
  { label: "Taycan", id: 331, option: "Taycan" },
  { label: "718", id: 332, option: "718" },
  { label: "Cayman", id: 333, option: "Cayman" },
  { label: "Boxster", id: 334, option: "Boxster" },
  { label: "918 Spyder", id: 335, option: "918 Spyder" },
  { label: "Taycan", id: 336, option: "Taycan" },
  { label: "718", id: 337, option: "718" },
  { label: "Cayman", id: 338, option: "Cayman" },
  { label: "Boxster", id: 339, option: "Boxster" },
  { label: "918 Spyder", id: 340, option: "918 Spyder" },
  { label: "Cullinan", id: 341, option: "Cullinan" },
  { label: "Ghost", id: 342, option: "Ghost" },
  { label: "Wraith", id: 343, option: "Wraith" },
  { label: "Dawn", id: 344, option: "Dawn" },
  { label: "Phantom", id: 345, option: "Phantom" },
  { label: "Bentayga", id: 346, option: "Bentayga" },
  { label: "Continental GT", id: 347, option: "Continental GT" },
  { label: "Flying Spur", id: 348, option: "Flying Spur" },
  { label: "Mulsanne", id: 349, option: "Mulsanne" },
  { label: "Escalade", id: 350, option: "Escalade" },
  { label: "XT6", id: 351, option: "XT6" },
  { label: "CT4", id: 352, option: "CT4" },
  { label: "CT5", id: 353, option: "CT5" },
  { label: "XT4", id: 354, option: "XT4" },
  { label: "XT5", id: 355, option: "XT5" },
  { label: "XTS", id: 356, option: "XTS" },
  { label: "CT6", id: 357, option: "CT6" },
  { label: "ATS", id: 358, option: "ATS" },
  { label: "CTS", id: 359, option: "CTS" },
  { label: "CT6-V", id: 360, option: "CT6-V" },
  { label: "CT4-V", id: 361, option: "CT4-V" },
  { label: "CT5-V", id: 362, option: "CT5-V" },
  { label: "ATS-V", id: 363, option: "ATS-V" },
  { label: "Escalade", id: 364, option: "Escalade" },
  { label: "XT4", id: 365, option: "XT4" },
  { label: "XT5", id: 366, option: "XT5" },
  { label: "XT6", id: 367, option: "XT6" },
  { label: "300", id: 368, option: "300" },
  { label: "Pacifica", id: 369, option: "Pacifica" },
  { label: "Voyager", id: 370, option: "Voyager" },
  { label: "Aspen", id: 371, option: "Aspen" },
  { label: "Crossfire", id: 372, option: "Crossfire" },
  { label: "Sebring", id: 373, option: "Sebring" },
  { label: "Challenger", id: 374, option: "Challenger" },
  { label: "Charger", id: 375, option: "Charger" },
  { label: "Durango", id: 376, option: "Durango" },
  { label: "Grand Caravan", id: 377, option: "Grand Caravan" },
  { label: "Journey", id: 378, option: "Journey" },
  { label: "Nitro", id: 379, option: "Nitro" },
  { label: "RAM 1500", id: 380, option: "RAM 1500" },
  { label: "RAM 2500", id: 381, option: "RAM 2500" },
  { label: "RAM 3500", id: 382, option: "RAM 3500" },
  { label: "ProMaster", id: 383, option: "ProMaster" },
  { label: "ProMaster City", id: 384, option: "ProMaster City" },
  { label: "200", id: 385, option: "200" },
  { label: "500", id: 386, option: "500" },
  { label: "500L", id: 387, option: "500L" },
  { label: "500X", id: 388, option: "500X" },
  { label: "Panda", id: 389, option: "Panda" },
  { label: "Tipo", id: 390, option: "Tipo" },
  { label: "Fullback", id: 391, option: "Fullback" },
  { label: "Mii", id: 392, option: "Mii" },
  { label: "Ibiza", id: 393, option: "Ibiza" },
  { label: "Leon", id: 394, option: "Leon" },
  { label: "Arona", id: 395, option: "Arona" },
  { label: "Ateca", id: 396, option: "Ateca" },
  { label: "Tarraco", id: 397, option: "Tarraco" },
];
