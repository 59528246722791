import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import axiosInstance from "../axios/axios";

const AdminPage = () => {
  const [codes, setCodes] = useState([]);
  const [newCode, setNewCode] = useState("");
  const [newExpirationDate, setNewExpirationDate] = useState("");
  const [editCodeId, setEditCodeId] = useState("");
  const [editExpirationDate, setEditExpirationDate] = useState("");

  useEffect(() => {
    fetchCodes();
  }, []);

  const fetchCodes = async () => {
    try {
      const response = await axiosInstance.get("/admin/codes", {
        headers: { Authorization: `Bearer Xz8&bK7#qP2!mT4@uW9$eN1^` },
      });
      setCodes(response.data);
    } catch (error) {
      console.error("Error fetching codes:", error);
    }
  };

  const handleAddCode = async () => {
    try {
      await axiosInstance.post(
        "/admin/create-code",
        {
          code: newCode,
          expirationDate: dayjs(newExpirationDate).unix(),
        },
        { headers: { Authorization: `Bearer Xz8&bK7#qP2!mT4@uW9$eN1^` } }
      );
      setNewCode("");
      setNewExpirationDate("");
      fetchCodes();
    } catch (error) {
      console.error("Error adding code:", error);
    }
  };

  const handleEditCode = async () => {
    try {
      await axiosInstance.put(
        `/admin/edit-code/${editCodeId}`,
        { expirationDate: dayjs(editExpirationDate).unix() },
        { headers: { Authorization: `Bearer Xz8&bK7#qP2!mT4@uW9$eN1^` } }
      );
      setEditCodeId("");
      setEditExpirationDate("");
      fetchCodes();
    } catch (error) {
      console.error("Error editing code:", error);
    }
  };

  return (
    <div className="flex flex-col items-center h-screen bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Page</h1>
      <div className="w-full max-w-md bg-white p-4 rounded-md shadow-md mb-4">
        <h2 className="text-xl font-semibold mb-2">Add New Code</h2>
        <input
          type="text"
          placeholder="Code"
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          value={newCode}
          onChange={(e) => setNewCode(e.target.value)}
        />
        <input
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          value={newExpirationDate}
          onChange={(e) => setNewExpirationDate(e.target.value)}
        />
        <button
          className="w-full bg-blue-500 text-white p-2 rounded-md"
          onClick={handleAddCode}
        >
          Add Code
        </button>
      </div>
      <div className="w-full max-w-md bg-white p-4 rounded-md shadow-md mb-4">
        <h2 className="text-xl font-semibold mb-2">
          Edit Code Expiration Date
        </h2>
        <select
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          value={editCodeId}
          onChange={(e) => setEditCodeId(e.target.value)}
        >
          <option value="" disabled>
            Select Code
          </option>
          {codes.map((code) => (
            <option key={code._id} value={code._id}>
              {code.code}
            </option>
          ))}
        </select>
        <input
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          value={editExpirationDate}
          onChange={(e) => setEditExpirationDate(e.target.value)}
        />
        <button
          className="w-full bg-green-500 text-white p-2 rounded-md"
          onClick={handleEditCode}
        >
          Edit Expiration Date
        </button>
      </div>
      <div className="w-full max-w-md bg-white p-4 rounded-md shadow-md">
        <h2 className="text-xl font-semibold mb-2">All Codes</h2>
        <ul>
          {codes.map((code) => (
            <li
              key={code._id}
              className="mb-2 p-2 border border-gray-300 rounded-md"
            >
              <div className="flex justify-between">
                <span>{code.code}</span>
                <span>
                  {dayjs.unix(code.expirationDate).format("YYYY-MM-DD")}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPage;
