import CarItem from "../components/CarItem";
import { isMobile } from "react-device-detect";
import { SearchableDropdown } from "../components/SearchableDropdown";
import { useNavigate } from "react-router-dom";
import {
  carBrands,
  carModels,
  countryOptions,
  fuelTypeOptions,
  gearboxOptions,
  yearOptions,
} from "../util/const";
import SearchFilter from "../components/SearchFilter";
import TelegramComponent from "../components/TelegramComponent";
import { useDebounce } from "use-debounce";
import axiosInstance from "../axios/axios";
import { useEffect, useRef, useState } from "react";
import { transformCarModels } from "../helpers";
import { modelSuggestions } from "../data/modelSuggestions";

const priceOptions = [
  { label: "€0", option: 0 },
  { label: "€5,000", option: 5000 },
  { label: "€10,000", option: 10000 },
  { label: "€20,000", option: 20000 },
  { label: "€30,000", option: 30000 },
  { label: "€40,000", option: 40000 },
  { label: "€50,000", option: 50000 },
];

const HomePage = () => {
  const [cars, setCars] = useState([]);
  const [total, setTotal] = useState(1000);
  const [selectedItem, setSelectedItem] = useState(null);
  const [limit, setLimit] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [model, setModel] = useState("");
  const [fuelType, setFuelType] = useState(null);
  const [gearbox, setGearbox] = useState(null);
  const [country, setCountry] = useState(null);
  const [yearFrom, setYearFrom] = useState(null);
  const [yearTo, setYearTo] = useState(null);
  const [priceFrom, setPriceFrom] = useState(null);
  const [priceTo, setPriceTo] = useState(null);
  const [damaged, setDamaged] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [tgFetch, setTgFetching] = useState(true);
  const [telegram, setTelegram] = useState({
    username: null,
    verified: false,
  });

  // todo: merge into one state
  // const [filter, setFilter] = useState({
  //   searchTerm,
  //   fuelType,
  //   gearbox,
  //   model,
  //   yearFrom,
  //   yearTo,
  //   priceFrom,
  //   priceTo,
  //   damaged,
  // });

  const handleFilterChange = (filterPayload) => {
    const {
      title,
      fuelType,
      transmission,
      model,
      yearFrom,
      yearTo,
      priceFrom,
      priceTo,
      damaged,
    } = filterPayload;

    setSearchTerm(title || "");
    setFuelType(fuelType || "");
    setGearbox(transmission);
    setModel(model);
    setYearFrom(yearFrom || "");
    setYearTo(yearTo || "");
    setPriceFrom(priceFrom || "");
    setPriceTo(priceTo || "");
    setDamaged(damaged);
  };
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 0);

  const fetchTelegramData = async () => {
    try {
      const response = await axiosInstance.get("/telegram-data");
      setTelegram(response.data);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        console.error("Data not found:", e);
        setTelegram(null);
      } else {
        console.error("Error fetching Telegram data:", e);
      }
    } finally {
      setTgFetching(false);
    }
  };

  const fetchFilters = async () => {
    try {
      const response = await axiosInstance.get("/filters");
      setFilters(response.data);
    } catch (e) {
      console.error("error:", e);
    }
  };

  useEffect(() => {
    fetchTelegramData();
    fetchFilters();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setLimit(10);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleScroll = () => {
      const { current } = containerRef;

      if (current) {
        const { scrollTop, clientHeight, scrollHeight } = current;

        if (scrollTop + clientHeight >= scrollHeight * 0.9) {
          if (limit - 32 < total) setLimit((prevLimit) => prevLimit + 30);
        }
      }
    };

    containerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchCars();
  }, [
    debouncedSearchTerm,
    searchTerm,
    limit,
    model,
    fuelType,
    gearbox,
    yearFrom,
    yearTo,
    priceFrom,
    priceTo,
    damaged,
    country,
  ]);

  const fetchCars = async () => {
    if (fetching || total + 32 < limit) {
      return;
    }
    try {
      setFetching(true);
      const response = await axiosInstance.get(
        `/cars?limit=${limit}&title=${model ? model : searchTerm}&fuelType=${
          fuelType || ""
        }&transmission=${gearbox || ""}&yearFrom=${yearFrom || ""}&yearTo=${
          yearTo || ""
        }&priceFrom=${priceFrom || ""}&priceTo=${
          priceTo || ""
        }&damaged=${damaged}&country=${country || ""}&model=${model || ""}`
      );
      setCars(response?.data?.cars);

      !isMobile && setSelectedItem(response.data.cars[0]);
      setTotal(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.data.expired) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } finally {
      setFetching(false);
    }
  };

  const saveSearch = async () => {
    try {
      await axiosInstance.post(
        `/filters?limit=${limit}&title=${debouncedSearchTerm}&fuelType=${
          fuelType || ""
        }&transmission=${gearbox || ""}&yearFrom=${yearFrom || ""}&yearTo=${
          yearTo || ""
        }&priceFrom=${priceFrom || ""}&priceTo=${
          priceTo || ""
        }&damaged=${damaged}&model=${model}&country=${country || ""}`
      );

      fetchFilters();
    } catch (error) {
    } finally {
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const modelItems =
    searchTerm?.length > 0
      ? modelSuggestions.filter((f) => f.brand.includes(searchTerm)) ||
        modelSuggestions
      : modelSuggestions;

  return (
    <div className="flex">
      <div
        ref={containerRef}
        className={`w-full md:w-[60%] max-w-[720px] ${
          selectedItem ? "hidden md:block" : ""
        }  h-screen overflow-y-auto`}
      >
        <div className="bg-gray-900">
          <div className="flex gap-2 md:gap-3  flex-row justify-between flex-wrap px-5 py-5">
            <div className="flex flex-row w-full gap-2">
              <div className="flex-1">
                <SearchableDropdown
                  value={searchTerm}
                  items={[...carBrands]}
                  onChange={setSearchTerm}
                  placeholder="Search by brand or keyword"
                />
              </div>
              <div className="flex-1">
                <SearchableDropdown
                  value={model}
                  items={[...modelItems]}
                  onChange={setModel}
                  placeholder="Search by model"
                />
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex-1">
                <SearchableDropdown
                  value={yearFrom}
                  items={yearOptions}
                  onChange={setYearFrom}
                  placeholder="Year From"
                />
              </div>
              <div className="flex-1">
                <SearchableDropdown
                  value={yearTo}
                  items={yearOptions}
                  onChange={setYearTo}
                  placeholder="Year To"
                />
              </div>
              <div className="flex-1">
                <SearchableDropdown
                  value={priceFrom}
                  items={priceOptions}
                  onChange={setPriceFrom}
                  placeholder="Price From"
                />
              </div>
              <div className="flex-1">
                <SearchableDropdown
                  value={priceTo}
                  items={priceOptions}
                  onChange={setPriceTo}
                  placeholder="Price To"
                />
              </div>
            </div>
            <div className="flex-1">
              <SearchableDropdown
                value={fuelType}
                items={fuelTypeOptions}
                onChange={setFuelType}
                placeholder="Fuel Type"
              />
            </div>
            <div className="flex-1">
              <SearchableDropdown
                value={gearbox}
                items={gearboxOptions}
                onChange={setGearbox}
                placeholder="Gearbox"
              />
            </div>

            <div className="flex-1">
              <SearchableDropdown
                value={country}
                items={countryOptions}
                onChange={setCountry}
                placeholder="Country"
              />
            </div>

            <div className="flex items-center justify-center gap-2 ">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={damaged}
                  onChange={(e) => setDamaged(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-primary"
                />
                <span className="slider round"></span>
              </label>
              <label className="text-gray-200">Damaged</label>
            </div>
          </div>

          <button
            onClick={saveSearch}
            className="bg-primary flex justify-center items-center mx-auto mb-3 px-5 py-2 font-bold text-white rounded-2xl"
          >
            Save search
          </button>

          <div className="w-full  border-t border-b py-5 border-primary ">
            <TelegramComponent
              onSubmit={fetchTelegramData}
              fetching={tgFetch}
              telegram={telegram}
            />
            <SearchFilter
              onDelete={fetchFilters}
              canChange={telegram?.verified && telegram?.username}
              setFilter={handleFilterChange}
              onChange={fetchFilters}
              filters={filters}
            />
          </div>
        </div>

        {cars.map((car, index) => {
          const isSchadeautosDomain = car?.imageUrls
            ? car?.imageUrls[0]?.includes("schadeautos.nl")
            : false;
          return (
            <div
              key={index}
              className={`p-4 flex flex-row justify-center align-center gap-4 border-b cursor-pointer hover:bg-gray-200 ${
                selectedItem && selectedItem._id === car._id
                  ? "bg-primary bg-opacity-20"
                  : ""
              } transition-all`}
              onClick={() => handleItemClick(car)}
            >
              {/* Left side with image */}
              <div className="w-2/3">
                <div
                  key={index}
                  className={`w-auto rounded-lg md:w-[100%] md:h-[auto]  ${
                    isSchadeautosDomain ? "crop-bottom small" : ""
                  }`}
                  style={
                    isSchadeautosDomain ? { height: "calc(100% - 0%)" } : {}
                  }
                >
                  <img
                    src={car?.imageUrls?.length > 0 ? car.imageUrls[0] : ""}
                    alt={car.title || "Car Image"}
                  />
                </div>
              </div>

              {/* Right side */}
              <div className="w-full py-3 px-2 flex flex-col">
                <h2 className="text-lg font-semibold">{car.title}</h2>
                <div className="flex gap-3 justify-center flex-wrap mt-4 mb-4">
                  <p className="px-2 py-2 px-2 text-primary font-bold rounded-md">
                    {car.price}
                  </p>
                  <p className="px-2 py-2 px-2 text-primary font-bold rounded-md">
                    {car.year}
                  </p>
                  <p className="px-2 py-2 px-2 text-primary font-bold rounded-md">
                    {car.mileage}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`w-full${
          selectedItem ? "" : "hidden md-block"
        } md:block h-screen overflow-y-auto`}
      >
        {selectedItem && (
          <CarItem
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
        )}
      </div>
    </div>
  );
};

export default HomePage;
