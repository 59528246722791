import { useEffect, useState } from "react";
import CopyToClipboard from "./tooltip";
import axiosInstance from "../axios/axios";
import moment from "moment";
import { countryOptions } from "../util/const";
const CarItem = ({ selectedItem, setSelectedItem, id }) => {
  const handleClose = () => {
    setSelectedItem(null);
  };
  const [car, setCar] = useState(null);

  useEffect(() => {
    if (selectedItem) {
      setCar(selectedItem);
    }
  }, [selectedItem]);
  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/cars/${id}`)
        .then((response) => {
          setCar(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id]);

  if (!car) {
    return <div></div>;
  }
  const dateString = moment(car.timestamp).format("YYYY-MM-DD");
  const isSchadeautosDomain = car?.imageUrls
    ? car?.imageUrls[0]?.includes("schadeautos.nl")
    : false;

  return (
    <div className="relative m-3 p-8">
      {setSelectedItem && (
        <button
          className="absolute top-2 right-2 text-gray-600"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
      <h2 className="text-lg text-center font-semibold">{car.title}</h2>

      <div className="flex gap-3 justify-center flex-wrap mt-4 mb-4">
        <div className="border border-primary text-primary py-2 px-8 font-bold rounded-md">
          {car.price}
        </div>
        <div className="border   border-primary text-primary  py-2 px-8 font-bold rounded-md">
          {car.year}
        </div>
        <div className="border  border-primary   text-primary  py-2 px-8 font-bold rounded-md">
          {car.transmission}
        </div>
        <div className="border   border-primary text-primary py-2 px-8 font-bold rounded-md">
          {car.mileage}
        </div>
        <div className="border   border-primary text-primary py-2 px-8 font-bold rounded-md">
          {dateString}
        </div>
        <div className="border   border-primary text-primary py-2 px-8 font-bold rounded-md">
          {countryOptions.find((c) => c.option === car.country)?.label}
        </div>
        <CopyToClipboard value={`${window.location.origin}/cars/${car._id}`} />
        {car.link && (
          <a
            className="px-8 font-bold py-2 bg-primary text-white rounded-md"
            href={car.link}
          >
            {car?.link?.split(".")[1]}
          </a>
        )}
      </div>

      <div className="flex justify-between mb-4"></div>
      <div className="flex flex-wrap flex-row gap-2 justify-center">
        {car.imageUrls.map((imageUrl, index) => {
          // if (imageUrl.includes("prods-ads")) {
          //   return <></>;
          // }
          return (
            <div
              key={`#$${index}`}
              className={`w-auto rounded-lg md:w-[40%] md:h-[auto] mb-4 md:mb-0 ${
                isSchadeautosDomain ? "crop-bottom" : ""
              }`}
              style={isSchadeautosDomain ? { height: "calc(100% - 50px)" } : {}}
            >
              <img
                src={
                  // "https://www.schadeautos.nl/cache/picture/8/1581494/0f55bd6b8765470dc546c0ae64a3a41a~v120636699.jpg" ||
                  imageUrl
                }
                alt={`Image ${index}`}
                className="w-full h-auto"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CarItem;
