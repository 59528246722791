import React, { useEffect, useState, useRef } from "react";
import { useDebounce } from "use-debounce";

export const SearchableDropdown = ({
  items,
  onChange,
  value,
  placeholder,
  type = "text",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (selectedItem) {
      setSearchTerm(selectedItem.label);
      setIsOpen(false);
    }
  }, [selectedItem]);

  const filteredItems = items.filter((item) =>
    item?.label?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())
  );

  const handleSelect = (item) => {
    setSelectedItem(item);
    onChange(item.option || item.label);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (filteredItems.length === 0) {
        const newItem = { label: searchTerm, id: searchTerm };
        handleSelect(newItem);
      } else if (filteredItems.length > 0) {
        handleSelect(filteredItems[0]);
      }
    }
  };

  const clearSelection = () => {
    setSearchTerm("");
    onChange("");
    setSelectedItem(null);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Trigger onChange whenever searchTerm changes
    if (!searchTerm) onChange(searchTerm);
  }, [searchTerm, onChange]);

  return (
    <div
      key={`%^${value || placeholder}`}
      className="relative w-full md:w-auto"
      ref={dropdownRef}
    >
      <input
        type={type}
        placeholder={placeholder}
        value={placeholder === "Country" ? searchTerm : value || searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onChange("");
        }}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
        className="w-full p-2 border capitalize text-primary placeholder:text-gray-500 border-primary-light focus:border-primary-light focus:outline-none focus:ring focus:ring-primary-light transition-all rounded-xl"
      />
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1 max-h-60 overflow-y-auto">
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <div
                key={item.id}
                className={`p-4 flex flex-row justify-center align-center gap-4 border-b cursor-pointer hover:bg-gray-200 ${
                  selectedItem && selectedItem.id === item.id
                    ? "bg-primary bg-opacity-20"
                    : ""
                }`}
                onClick={() => handleSelect(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div
              className="p-4 text-center text-gray-500 cursor-pointer hover:bg-gray-200"
              onClick={() =>
                handleSelect({ label: searchTerm, id: searchTerm })
              }
            >
              {`"${searchTerm}"`}
            </div>
          )}
        </div>
      )}
      {(searchTerm || value) && (
        <button
          className="absolute top-0 right-1 text-gray-500"
          onClick={clearSelection}
        >
          &times;
        </button>
      )}
    </div>
  );
};
