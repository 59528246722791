import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import CarPage from "./pages/CarPage";
import AdminPage from "./pages/AdminPage";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem("token");
  return token ? <Element {...rest} /> : <Navigate to="/login" replace />;
};

export default function App() {
  useEffect(() => {}, []);
  return (
    <div className="h-screen">
      <BrowserRouter>
        <Routes>
          <Route element={<LoginPage />} path="/login" />
          <Route element={<PrivateRoute element={HomePage} />} path="/" exact />
          <Route element={<CarPage />} path="/cars/:id" exact />
          <Route element={<PrivateRoute element={AdminPage} />} path="/admin" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
