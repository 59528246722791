import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios/axios";

const LoginPage = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post("/validate-code", { code });
      const { valid } = response.data;

      if (valid) {
        // Code is valid, perform login logic

        localStorage.setItem("token", code);
        navigate("/");
        //  window.location.href = "/";
      } else {
        // Code is not valid, handle accordingly
        console.log("Invalid code");
      }
    } catch (error) {
      console.error("Error validating code:", error);
    }

    // Reset the input field after submission
    //setCode("");
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-md">
        <input
          type="text"
          name="login-code"
          placeholder="Enter code"
          value={code}
          onChange={handleCodeChange}
          className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-primary-light dark:bg-gray-800 dark:text-white"
        />
        <button
          type="submit"
          className="mt-4 w-full transition-all bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
