import React, { useState } from "react";
import axiosInstance from "../axios/axios"; // Assuming axios instance is configured

const SearchFilter = ({
  filters,
  onChange,
  setFilter,
  onDelete,
  canChange,
}) => {
  const [showAll, setShowAll] = useState(false);

  const toggleFilter = async (id) => {
    if (!canChange) {
    }
    try {
      const response = await axiosInstance.put(`/filters/${id}/toggle-enabled`);
      const updatedFilter = response.data.filter;

      onChange(
        filters.map((filter) =>
          filter.id === id
            ? { ...filter, active: updatedFilter.enabled }
            : filter
        )
      );
    } catch (error) {
      console.error("Error toggling filter state:", error);
      // Handle error as per your application's requirements
    }
  };

  const removeFilter = async (id) => {
    await axiosInstance.delete(`/filters/${id}`);
    await onDelete();
  };

  const displayedFilters = showAll ? filters : filters?.slice(0, 3);

  if (!filters || filters?.length < 1) {
    return <div></div>;
  }

  return (
    <div className="text-white border-primary border-t mt-4 p-4 w-full ">
      <div className="d-flex items-center mb-4 justify-between flex">
        <h2 className="text-xl font-bold ">My searches ({filters?.length})</h2>
        <span className="text-gray-400 text-sm">Telegram notifications</span>
      </div>
      <div>
        {displayedFilters?.map((filter) => (
          <div
            key={filter.id}
            className="flex items-center align-center relative justify-between mb-2 cursor-pointer bg-primary-dark hover:bg-primary p-2 rounded-2xl transition-all"
            onClick={() => {
              setFilter(filter);
            }}
          >
            <div className="flex flex-row items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click event from bubbling
                  removeFilter(filter._id);
                }}
                className="text-red-500 hover:text-red-200 border border-red-300 rounded-full w-5 h-5 flex items-center justify-center transition"
              >
                <span className="mb-0.5">&times;</span>
              </button>
              <span className="ml-2">
                <b>{filter.title}</b> {filter.model}
              </span>
              <span className="text-gray-400 text-sm ml-2">
                {filter.fuelType} {filter.transmission} {filter.year}{" "}
                {filter.damaged && "Damaged"}
              </span>
            </div>
            <div className="flex items-center">
              {filter.count > 0 && (
                <span className="bg-green-500 text-white rounded-full px-2 py-1 text-xs mr-2">
                  +{filter.count}
                </span>
              )}
              <label
                className="switch"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click event from bubbling
                }}
              >
                <input
                  type="checkbox"
                  checked={filter.enabled}
                  disabled={!canChange}
                  onChange={() => toggleFilter(filter._id)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center">
        {filters?.length > 3 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="text-blue-500 hover:underline"
          >
            Show {showAll ? "less" : `all (${filters.length})`} &raquo;
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
