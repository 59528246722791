export const modelSuggestions = [
  {
    label: "-Other-",
    value: "-Other-",
    brand: "-Other-",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "AC",
  },
  {
    label: "Ace",
    value: "Ace",
    brand: "AC",
  },
  {
    label: "Aceca",
    value: "Aceca",
    brand: "AC",
  },
  {
    label: "Cobra",
    value: "Cobra",
    brand: "AC",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Acura",
  },
  {
    label: "CL",
    value: "CL",
    brand: "Acura",
  },
  {
    label: "EL",
    value: "EL",
    brand: "Acura",
  },
  {
    label: "ILX",
    value: "ILX",
    brand: "Acura",
  },
  {
    label: "Integra",
    value: "Integra",
    brand: "Acura",
  },
  {
    label: "MDX",
    value: "MDX",
    brand: "Acura",
  },
  {
    label: "NSX",
    value: "NSX",
    brand: "Acura",
  },
  {
    label: "RDX",
    value: "RDX",
    brand: "Acura",
  },
  {
    label: "RL",
    value: "RL",
    brand: "Acura",
  },
  {
    label: "RLX",
    value: "RLX",
    brand: "Acura",
  },
  {
    label: "RSX",
    value: "RSX",
    brand: "Acura",
  },
  {
    label: "SLX",
    value: "SLX",
    brand: "Acura",
  },
  {
    label: "TL",
    value: "TL",
    brand: "Acura",
  },
  {
    label: "TLX",
    value: "TLX",
    brand: "Acura",
  },
  {
    label: "TSX",
    value: "TSX",
    brand: "Acura",
  },
  {
    label: "Vigor",
    value: "Vigor",
    brand: "Acura",
  },
  {
    label: "ZDX",
    value: "ZDX",
    brand: "Acura",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Aixam",
  },
  {
    label: "400",
    value: "400",
    brand: "Aixam",
  },
  {
    label: "500",
    value: "500",
    brand: "Aixam",
  },
  {
    label: "A741",
    value: "A741",
    brand: "Aixam",
  },
  {
    label: "A751",
    value: "A751",
    brand: "Aixam",
  },
  {
    label: "City",
    value: "City",
    brand: "Aixam",
  },
  {
    label: "Crossline",
    value: "Crossline",
    brand: "Aixam",
  },
  {
    label: "Ligier",
    value: "Ligier",
    brand: "Aixam",
  },
  {
    label: "Scouty",
    value: "Scouty",
    brand: "Aixam",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Alfa Romeo",
  },
  {
    label: "145",
    value: "145",
    brand: "Alfa Romeo",
  },
  {
    label: "146",
    value: "146",
    brand: "Alfa Romeo",
  },
  {
    label: "147",
    value: "147",
    brand: "Alfa Romeo",
  },
  {
    label: "155",
    value: "155",
    brand: "Alfa Romeo",
  },
  {
    label: "156",
    value: "156",
    brand: "Alfa Romeo",
  },
  {
    label: "159",
    value: "159",
    brand: "Alfa Romeo",
  },
  {
    label: "164",
    value: "164",
    brand: "Alfa Romeo",
  },
  {
    label: "166",
    value: "166",
    brand: "Alfa Romeo",
  },
  {
    label: "33",
    value: "33",
    brand: "Alfa Romeo",
  },
  {
    label: "4C",
    value: "4C",
    brand: "Alfa Romeo",
  },
  {
    label: "75",
    value: "75",
    brand: "Alfa Romeo",
  },
  {
    label: "8C",
    value: "8C",
    brand: "Alfa Romeo",
  },
  {
    label: "90",
    value: "90",
    brand: "Alfa Romeo",
  },
  {
    label: "Alfasud",
    value: "Alfasud",
    brand: "Alfa Romeo",
  },
  {
    label: "Alfetta",
    value: "Alfetta",
    brand: "Alfa Romeo",
  },
  {
    label: "Arna",
    value: "Arna",
    brand: "Alfa Romeo",
  },
  {
    label: "Brera",
    value: "Brera",
    brand: "Alfa Romeo",
  },
  {
    label: "Crosswagon Q4",
    value: "Crosswagon Q4",
    brand: "Alfa Romeo",
  },
  {
    label: "Giulia",
    value: "Giulia",
    brand: "Alfa Romeo",
  },
  {
    label: "Giulietta",
    value: "Giulietta",
    brand: "Alfa Romeo",
  },
  {
    label: "GT",
    value: "GT",
    brand: "Alfa Romeo",
  },
  {
    label: "GTV",
    value: "GTV",
    brand: "Alfa Romeo",
  },
  {
    label: "Junior",
    value: "Junior",
    brand: "Alfa Romeo",
  },
  {
    label: "Mito",
    value: "Mito",
    brand: "Alfa Romeo",
  },
  {
    label: "RZ/SZ",
    value: "RZ/SZ",
    brand: "Alfa Romeo",
  },
  {
    label: "Spider",
    value: "Spider",
    brand: "Alfa Romeo",
  },
  {
    label: "Sportwagon",
    value: "Sportwagon",
    brand: "Alfa Romeo",
  },
  {
    label: "Sprint",
    value: "Sprint",
    brand: "Alfa Romeo",
  },
  {
    label: "Stelvio",
    value: "Stelvio",
    brand: "Alfa Romeo",
  },
  {
    label: "Tonale",
    value: "Tonale",
    brand: "Alfa Romeo",
  },
  {
    label: "ATX340ED",
    value: "ATX340ED",
    brand: "Alke",
  },
  {
    label: " ATX 340E",
    value: " ATX 340E",
    brand: "Alke",
  },
  {
    label: "B10",
    value: "B10",
    brand: "Alpina",
  },
  {
    label: "B12",
    value: "B12",
    brand: "Alpina",
  },
  {
    label: "B3",
    value: "B3",
    brand: "Alpina",
  },
  {
    label: "B4",
    value: "B4",
    brand: "Alpina",
  },
  {
    label: "B5",
    value: "B5",
    brand: "Alpina",
  },
  {
    label: "B6",
    value: "B6",
    brand: "Alpina",
  },
  {
    label: "B7",
    value: "B7",
    brand: "Alpina",
  },
  {
    label: "B8",
    value: "B8",
    brand: "Alpina",
  },
  {
    label: "D10",
    value: "D10",
    brand: "Alpina",
  },
  {
    label: "D3",
    value: "D3",
    brand: "Alpina",
  },
  {
    label: "D5",
    value: "D5",
    brand: "Alpina",
  },
  {
    label: "Roadster S",
    value: "Roadster S",
    brand: "Alpina",
  },
  {
    label: "XD3",
    value: "XD3",
    brand: "Alpina",
  },
  {
    label: "XB7",
    value: "XB7",
    brand: "Alpina",
  },
  {
    label: "A110",
    value: "A110",
    brand: "Alpine",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "AMC",
  },
  {
    label: "Ambassador",
    value: "Ambassador",
    brand: "AMC",
  },
  {
    label: "Concord",
    value: "Concord",
    brand: "AMC",
  },
  {
    label: "Eagle",
    value: "Eagle",
    brand: "AMC",
  },
  {
    label: "Gremlin",
    value: "Gremlin",
    brand: "AMC",
  },
  {
    label: "Javelin",
    value: "Javelin",
    brand: "AMC",
  },
  {
    label: "Matador",
    value: "Matador",
    brand: "AMC",
  },
  {
    label: "Pacer",
    value: "Pacer",
    brand: "AMC",
  },
  {
    label: "Rambler",
    value: "Rambler",
    brand: "AMC",
  },
  {
    label: "Rebel",
    value: "Rebel",
    brand: "AMC",
  },
  {
    label: "Spirit",
    value: "Spirit",
    brand: "AMC",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "ARO",
  },
  {
    label: "10",
    value: "10",
    brand: "ARO",
  },
  {
    label: "24",
    value: "24",
    brand: "ARO",
  },
  {
    label: "243",
    value: "243",
    brand: "ARO",
  },
  {
    label: "244",
    value: "244",
    brand: "ARO",
  },
  {
    label: "245",
    value: "245",
    brand: "ARO",
  },
  {
    label: "246",
    value: "246",
    brand: "ARO",
  },
  {
    label: "K450",
    value: "K450",
    brand: "ARO",
  },
  {
    label: "Spartana",
    value: "Spartana",
    brand: "ARO",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Asia",
  },
  {
    label: "Hi-Topic",
    value: "Hi-Topic",
    brand: "Asia",
  },
  {
    label: "Retona",
    value: "Retona",
    brand: "Asia",
  },
  {
    label: "Rocsta",
    value: "Rocsta",
    brand: "Asia",
  },
  {
    label: "Towner",
    value: "Towner",
    brand: "Asia",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Aston Martin",
  },
  {
    label: "AR1",
    value: "AR1",
    brand: "Aston Martin",
  },
  {
    label: "Cygnet",
    value: "Cygnet",
    brand: "Aston Martin",
  },
  {
    label: "DB",
    value: "DB",
    brand: "Aston Martin",
  },
  {
    label: "DB11",
    value: "DB11",
    brand: "Aston Martin",
  },
  {
    label: "DB12",
    value: "DB12",
    brand: "Aston Martin",
  },
  {
    label: "DB7",
    value: "DB7",
    brand: "Aston Martin",
  },
  {
    label: "DB9",
    value: "DB9",
    brand: "Aston Martin",
  },
  {
    label: "DBS",
    value: "DBS",
    brand: "Aston Martin",
  },
  {
    label: "DBX",
    value: "DBX",
    brand: "Aston Martin",
  },
  {
    label: "Lagonda",
    value: "Lagonda",
    brand: "Aston Martin",
  },
  {
    label: "Rapide",
    value: "Rapide",
    brand: "Aston Martin",
  },
  {
    label: "Valhalla",
    value: "Valhalla",
    brand: "Aston Martin",
  },
  {
    label: "Vanquish",
    value: "Vanquish",
    brand: "Aston Martin",
  },
  {
    label: "Vantage",
    value: "Vantage",
    brand: "Aston Martin",
  },
  {
    label: "Virage",
    value: "Virage",
    brand: "Aston Martin",
  },
  {
    label: "Volante",
    value: "Volante",
    brand: "Aston Martin",
  },
  {
    label: "Zagato",
    value: "Zagato",
    brand: "Aston Martin",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Audi",
  },
  {
    label: "100",
    value: "100",
    brand: "Audi",
  },
  {
    label: "200",
    value: "200",
    brand: "Audi",
  },
  {
    label: "4000",
    value: "4000",
    brand: "Audi",
  },
  {
    label: "5000",
    value: "5000",
    brand: "Audi",
  },
  {
    label: "80",
    value: "80",
    brand: "Audi",
  },
  {
    label: "90",
    value: "90",
    brand: "Audi",
  },
  {
    label: "A1",
    value: "A1",
    brand: "Audi",
  },
  {
    label: "A2",
    value: "A2",
    brand: "Audi",
  },
  {
    label: "A3",
    value: "A3",
    brand: "Audi",
  },
  {
    label: "A4",
    value: "A4",
    brand: "Audi",
  },
  {
    label: "A4 ALLROAD",
    value: "A4 ALLROAD",
    brand: "Audi",
  },
  {
    label: "A5",
    value: "A5",
    brand: "Audi",
  },
  {
    label: "A5 SPORTBACK",
    value: "A5 SPORTBACK",
    brand: "Audi",
  },
  {
    label: "A6",
    value: "A6",
    brand: "Audi",
  },
  {
    label: "A6 ALLROAD",
    value: "A6 ALLROAD",
    brand: "Audi",
  },
  {
    label: "A7",
    value: "A7",
    brand: "Audi",
  },
  {
    label: "A8",
    value: "A8",
    brand: "Audi",
  },
  {
    label: "Allroad",
    value: "Allroad",
    brand: "Audi",
  },
  {
    label: "Cabriolet",
    value: "Cabriolet",
    brand: "Audi",
  },
  {
    label: "Coupe",
    value: "Coupe",
    brand: "Audi",
  },
  {
    label: "e-tron",
    value: "e-tron",
    brand: "Audi",
  },
  {
    label: "e-tron GT",
    value: "e-tron GT",
    brand: "Audi",
  },
  {
    label: "e-tron S",
    value: "e-tron S",
    brand: "Audi",
  },
  {
    label: "e-tron Sportback",
    value: "e-tron Sportback",
    brand: "Audi",
  },
  {
    label: "Q2",
    value: "Q2",
    brand: "Audi",
  },
  {
    label: "Q3",
    value: "Q3",
    brand: "Audi",
  },
  {
    label: "Q3 Sportback",
    value: "Q3 Sportback",
    brand: "Audi",
  },
  {
    label: "Q4 e-tron",
    value: "Q4 e-tron",
    brand: "Audi",
  },
  {
    label: "Q5",
    value: "Q5",
    brand: "Audi",
  },
  {
    label: "Q5 Sportback",
    value: "Q5 Sportback",
    brand: "Audi",
  },
  {
    label: "Q7",
    value: "Q7",
    brand: "Audi",
  },
  {
    label: "Q8",
    value: "Q8",
    brand: "Audi",
  },
  {
    label: "Quattro",
    value: "Quattro",
    brand: "Audi",
  },
  {
    label: "R8",
    value: "R8",
    brand: "Audi",
  },
  {
    label: "RS Q3",
    value: "RS Q3",
    brand: "Audi",
  },
  {
    label: "RS2",
    value: "RS2",
    brand: "Audi",
  },
  {
    label: "RS3",
    value: "RS3",
    brand: "Audi",
  },
  {
    label: "RS4",
    value: "RS4",
    brand: "Audi",
  },
  {
    label: "RS5",
    value: "RS5",
    brand: "Audi",
  },
  {
    label: "RS6",
    value: "RS6",
    brand: "Audi",
  },
  {
    label: "RS7",
    value: "RS7",
    brand: "Audi",
  },
  {
    label: "RSQ8",
    value: "RSQ8",
    brand: "Audi",
  },
  {
    label: "S1",
    value: "S1",
    brand: "Audi",
  },
  {
    label: "S2",
    value: "S2",
    brand: "Audi",
  },
  {
    label: "S3",
    value: "S3",
    brand: "Audi",
  },
  {
    label: "S4",
    value: "S4",
    brand: "Audi",
  },
  {
    label: "S5",
    value: "S5",
    brand: "Audi",
  },
  {
    label: "S6",
    value: "S6",
    brand: "Audi",
  },
  {
    label: "S7",
    value: "S7",
    brand: "Audi",
  },
  {
    label: "S8",
    value: "S8",
    brand: "Audi",
  },
  {
    label: "SQ2",
    value: "SQ2",
    brand: "Audi",
  },
  {
    label: "SQ5",
    value: "SQ5",
    brand: "Audi",
  },
  {
    label: "SQ7",
    value: "SQ7",
    brand: "Audi",
  },
  {
    label: "SQ8",
    value: "SQ8",
    brand: "Audi",
  },
  {
    label: "SQ8 SPORTBACK ",
    value: "SQ8 SPORTBACK ",
    brand: "Audi",
  },
  {
    label: "TT",
    value: "TT",
    brand: "Audi",
  },
  {
    label: "TT RS",
    value: "TT RS",
    brand: "Audi",
  },
  {
    label: "TTS",
    value: "TTS",
    brand: "Audi",
  },
  {
    label: "V8",
    value: "V8",
    brand: "Audi",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Austin Rover",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Austin-Healey",
  },
  {
    label: "MK 3000",
    value: "MK 3000",
    brand: "Austin-Healey",
  },
  {
    label: "Senova X35",
    value: "Senova X35",
    brand: "Baic",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "BELLIER",
  },
  {
    label: "B8",
    value: "B8",
    brand: "BELLIER",
  },
  {
    label: "VX 550",
    value: "VX 550",
    brand: "BELLIER",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Bentley",
  },
  {
    label: "Arnage",
    value: "Arnage",
    brand: "Bentley",
  },
  {
    label: "Azure",
    value: "Azure",
    brand: "Bentley",
  },
  {
    label: "Bentayga",
    value: "Bentayga",
    brand: "Bentley",
  },
  {
    label: "Brooklands",
    value: "Brooklands",
    brand: "Bentley",
  },
  {
    label: "Continental",
    value: "Continental",
    brand: "Bentley",
  },
  {
    label: "Continental GT",
    value: "Continental GT",
    brand: "Bentley",
  },
  {
    label: "Eight",
    value: "Eight",
    brand: "Bentley",
  },
  {
    label: "Flying Spur",
    value: "Flying Spur",
    brand: "Bentley",
  },
  {
    label: "Mulsanne",
    value: "Mulsanne",
    brand: "Bentley",
  },
  {
    label: "S1",
    value: "S1",
    brand: "Bentley",
  },
  {
    label: "Turbo R",
    value: "Turbo R",
    brand: "Bentley",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "BMW",
  },
  {
    label: "114",
    value: "114",
    brand: "BMW",
  },
  {
    label: "116",
    value: "116",
    brand: "BMW",
  },
  {
    label: "118",
    value: "118",
    brand: "BMW",
  },
  {
    label: "120",
    value: "120",
    brand: "BMW",
  },
  {
    label: "123",
    value: "123",
    brand: "BMW",
  },
  {
    label: "125",
    value: "125",
    brand: "BMW",
  },
  {
    label: "128",
    value: "128",
    brand: "BMW",
  },
  {
    label: "130",
    value: "130",
    brand: "BMW",
  },
  {
    label: "135",
    value: "135",
    brand: "BMW",
  },
  {
    label: "M135",
    value: "M135",
    brand: "BMW",
  },
  {
    label: "M140",
    value: "M140",
    brand: "BMW",
  },
  {
    label: "1M Coupe",
    value: "1M Coupe",
    brand: "BMW",
  },
  {
    label: "214 Active Tourer",
    value: "214 Active Tourer",
    brand: "BMW",
  },
  {
    label: "214 Gran Tourer",
    value: "214 Gran Tourer",
    brand: "BMW",
  },
  {
    label: "216 Active Tourer",
    value: "216 Active Tourer",
    brand: "BMW",
  },
  {
    label: "216 Gran Tourer",
    value: "216 Gran Tourer",
    brand: "BMW",
  },
  {
    label: "218",
    value: "218",
    brand: "BMW",
  },
  {
    label: "218 Active Tourer",
    value: "218 Active Tourer",
    brand: "BMW",
  },
  {
    label: "218 Gran Tourer",
    value: "218 Gran Tourer",
    brand: "BMW",
  },
  {
    label: "220",
    value: "220",
    brand: "BMW",
  },
  {
    label: "220 Active Tourer",
    value: "220 Active Tourer",
    brand: "BMW",
  },
  {
    label: "220 Gran Tourer",
    value: "220 Gran Tourer",
    brand: "BMW",
  },
  {
    label: "225",
    value: "225",
    brand: "BMW",
  },
  {
    label: "225 Active Tourer",
    value: "225 Active Tourer",
    brand: "BMW",
  },
  {
    label: "228",
    value: "228",
    brand: "BMW",
  },
  {
    label: "230",
    value: "230",
    brand: "BMW",
  },
  {
    label: "M235",
    value: "M235",
    brand: "BMW",
  },
  {
    label: "M240",
    value: "M240",
    brand: "BMW",
  },
  {
    label: "315",
    value: "315",
    brand: "BMW",
  },
  {
    label: "316",
    value: "316",
    brand: "BMW",
  },
  {
    label: "318",
    value: "318",
    brand: "BMW",
  },
  {
    label: "318 Gran turismo",
    value: "318 Gran turismo",
    brand: "BMW",
  },
  {
    label: "320",
    value: "320",
    brand: "BMW",
  },
  {
    label: "320 Gran turismo",
    value: "320 Gran turismo",
    brand: "BMW",
  },
  {
    label: "323",
    value: "323",
    brand: "BMW",
  },
  {
    label: "324",
    value: "324",
    brand: "BMW",
  },
  {
    label: "325",
    value: "325",
    brand: "BMW",
  },
  {
    label: "325 Gran turismo",
    value: "325 Gran turismo",
    brand: "BMW",
  },
  {
    label: "327",
    value: "327",
    brand: "BMW",
  },
  {
    label: "328",
    value: "328",
    brand: "BMW",
  },
  {
    label: "328 Gran turismo",
    value: "328 Gran turismo",
    brand: "BMW",
  },
  {
    label: "330",
    value: "330",
    brand: "BMW",
  },
  {
    label: "330 Gran turismo",
    value: "330 Gran turismo",
    brand: "BMW",
  },
  {
    label: "335",
    value: "335",
    brand: "BMW",
  },
  {
    label: "335 Gran turismo",
    value: "335 Gran turismo",
    brand: "BMW",
  },
  {
    label: "340",
    value: "340",
    brand: "BMW",
  },
  {
    label: "340 Gran turismo",
    value: "340 Gran turismo",
    brand: "BMW",
  },
  {
    label: "346",
    value: "346",
    brand: "BMW",
  },
  {
    label: "Active Hybrid 3",
    value: "Active Hybrid 3",
    brand: "BMW",
  },
  {
    label: "M340d",
    value: "M340d",
    brand: "BMW",
  },
  {
    label: "M340i",
    value: "M340i",
    brand: "BMW",
  },
  {
    label: "418",
    value: "418",
    brand: "BMW",
  },
  {
    label: "418 Gran Coupe",
    value: "418 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "420",
    value: "420",
    brand: "BMW",
  },
  {
    label: "420 Gran Coupe",
    value: "420 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "425",
    value: "425",
    brand: "BMW",
  },
  {
    label: "425 Gran Coupe",
    value: "425 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "428",
    value: "428",
    brand: "BMW",
  },
  {
    label: "428 Gran Coupe",
    value: "428 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "430",
    value: "430",
    brand: "BMW",
  },
  {
    label: "430 Gran Coupe",
    value: "430 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "435",
    value: "435",
    brand: "BMW",
  },
  {
    label: "435 Gran Coupe",
    value: "435 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "440",
    value: "440",
    brand: "BMW",
  },
  {
    label: "440 Gran Coupe",
    value: "440 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "M440",
    value: "M440",
    brand: "BMW",
  },
  {
    label: "518",
    value: "518",
    brand: "BMW",
  },
  {
    label: "520",
    value: "520",
    brand: "BMW",
  },
  {
    label: "520 Gran turismo",
    value: "520 Gran turismo",
    brand: "BMW",
  },
  {
    label: "523",
    value: "523",
    brand: "BMW",
  },
  {
    label: "524",
    value: "524",
    brand: "BMW",
  },
  {
    label: "525",
    value: "525",
    brand: "BMW",
  },
  {
    label: "528",
    value: "528",
    brand: "BMW",
  },
  {
    label: "530",
    value: "530",
    brand: "BMW",
  },
  {
    label: "530 Gran Turismo",
    value: "530 Gran Turismo",
    brand: "BMW",
  },
  {
    label: "535",
    value: "535",
    brand: "BMW",
  },
  {
    label: "535 Gran Turismo",
    value: "535 Gran Turismo",
    brand: "BMW",
  },
  {
    label: "540",
    value: "540",
    brand: "BMW",
  },
  {
    label: "545",
    value: "545",
    brand: "BMW",
  },
  {
    label: "550",
    value: "550",
    brand: "BMW",
  },
  {
    label: "550 Gran turismo",
    value: "550 Gran turismo",
    brand: "BMW",
  },
  {
    label: "M550",
    value: "M550",
    brand: "BMW",
  },
  {
    label: "Active Hybrid 5",
    value: "Active Hybrid 5",
    brand: "BMW",
  },
  {
    label: "620 Gran Turismo",
    value: "620 Gran Turismo",
    brand: "BMW",
  },
  {
    label: "628",
    value: "628",
    brand: "BMW",
  },
  {
    label: "630",
    value: "630",
    brand: "BMW",
  },
  {
    label: "630 Gran Turismo",
    value: "630 Gran Turismo",
    brand: "BMW",
  },
  {
    label: "632",
    value: "632",
    brand: "BMW",
  },
  {
    label: "633",
    value: "633",
    brand: "BMW",
  },
  {
    label: "635",
    value: "635",
    brand: "BMW",
  },
  {
    label: "640",
    value: "640",
    brand: "BMW",
  },
  {
    label: "640 Gran Coupe",
    value: "640 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "640 Gran Turismo",
    value: "640 Gran Turismo",
    brand: "BMW",
  },
  {
    label: "645",
    value: "645",
    brand: "BMW",
  },
  {
    label: "650",
    value: "650",
    brand: "BMW",
  },
  {
    label: "650 Gran Coupe",
    value: "650 Gran Coupe",
    brand: "BMW",
  },
  {
    label: "725",
    value: "725",
    brand: "BMW",
  },
  {
    label: "728",
    value: "728",
    brand: "BMW",
  },
  {
    label: "730",
    value: "730",
    brand: "BMW",
  },
  {
    label: "732",
    value: "732",
    brand: "BMW",
  },
  {
    label: "733",
    value: "733",
    brand: "BMW",
  },
  {
    label: "735",
    value: "735",
    brand: "BMW",
  },
  {
    label: "740",
    value: "740",
    brand: "BMW",
  },
  {
    label: "745",
    value: "745",
    brand: "BMW",
  },
  {
    label: "750",
    value: "750",
    brand: "BMW",
  },
  {
    label: "760",
    value: "760",
    brand: "BMW",
  },
  {
    label: "Active Hybrid 7",
    value: "Active Hybrid 7",
    brand: "BMW",
  },
  {
    label: "M760",
    value: "M760",
    brand: "BMW",
  },
  {
    label: "840",
    value: "840",
    brand: "BMW",
  },
  {
    label: "850",
    value: "850",
    brand: "BMW",
  },
  {
    label: "M850",
    value: "M850",
    brand: "BMW",
  },
  {
    label: "i3",
    value: "i3",
    brand: "BMW",
  },
  {
    label: "i3s",
    value: "i3s",
    brand: "BMW",
  },
  {
    label: "i4",
    value: "i4",
    brand: "BMW",
  },
  {
    label: "i8",
    value: "i8",
    brand: "BMW",
  },
  {
    label: "iX",
    value: "iX",
    brand: "BMW",
  },
  {
    label: "iX3",
    value: "iX3",
    brand: "BMW",
  },
  {
    label: "M Coupe",
    value: "M Coupe",
    brand: "BMW",
  },
  {
    label: "M Roadster",
    value: "M Roadster",
    brand: "BMW",
  },
  {
    label: "M1",
    value: "M1",
    brand: "BMW",
  },
  {
    label: "M2",
    value: "M2",
    brand: "BMW",
  },
  {
    label: "M3",
    value: "M3",
    brand: "BMW",
  },
  {
    label: "M4",
    value: "M4",
    brand: "BMW",
  },
  {
    label: "M5",
    value: "M5",
    brand: "BMW",
  },
  {
    label: "M6",
    value: "M6",
    brand: "BMW",
  },
  {
    label: "M8",
    value: "M8",
    brand: "BMW",
  },
  {
    label: "X1",
    value: "X1",
    brand: "BMW",
  },
  {
    label: "X2",
    value: "X2",
    brand: "BMW",
  },
  {
    label: "X3",
    value: "X3",
    brand: "BMW",
  },
  {
    label: "X3 M",
    value: "X3 M",
    brand: "BMW",
  },
  {
    label: "X4",
    value: "X4",
    brand: "BMW",
  },
  {
    label: "X4 M",
    value: "X4 M",
    brand: "BMW",
  },
  {
    label: "X5",
    value: "X5",
    brand: "BMW",
  },
  {
    label: "X5 M",
    value: "X5 M",
    brand: "BMW",
  },
  {
    label: "X6",
    value: "X6",
    brand: "BMW",
  },
  {
    label: "X6 M",
    value: "X6 M",
    brand: "BMW",
  },
  {
    label: "X7",
    value: "X7",
    brand: "BMW",
  },
  {
    label: "XM",
    value: "XM",
    brand: "BMW",
  },
  {
    label: "Z1",
    value: "Z1",
    brand: "BMW",
  },
  {
    label: "Z3",
    value: "Z3",
    brand: "BMW",
  },
  {
    label: "Z3 M",
    value: "Z3 M",
    brand: "BMW",
  },
  {
    label: "Z4",
    value: "Z4",
    brand: "BMW",
  },
  {
    label: "Z4 M",
    value: "Z4 M",
    brand: "BMW",
  },
  {
    label: "Z8",
    value: "Z8",
    brand: "BMW",
  },
  {
    label: "1502",
    value: "1502",
    brand: "BMW",
  },
  {
    label: "1602",
    value: "1602",
    brand: "BMW",
  },
  {
    label: "1802",
    value: "1802",
    brand: "BMW",
  },
  {
    label: "2002",
    value: "2002",
    brand: "BMW",
  },
  {
    label: "2800CS",
    value: "2800CS",
    brand: "BMW",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Bolloré",
  },
  {
    label: "Bluecar",
    value: "Bluecar",
    brand: "Bolloré",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Briliance",
  },
  {
    label: "Andere",
    value: "Andere",
    brand: "Briliance",
  },
  {
    label: "BC3",
    value: "BC3",
    brand: "Briliance",
  },
  {
    label: "BS2",
    value: "BS2",
    brand: "Briliance",
  },
  {
    label: "BS4",
    value: "BS4",
    brand: "Briliance",
  },
  {
    label: "BS6",
    value: "BS6",
    brand: "Briliance",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Buick",
  },
  {
    label: "Cascada",
    value: "Cascada",
    brand: "Buick",
  },
  {
    label: "Century",
    value: "Century",
    brand: "Buick",
  },
  {
    label: "Electra",
    value: "Electra",
    brand: "Buick",
  },
  {
    label: "Enclave",
    value: "Enclave",
    brand: "Buick",
  },
  {
    label: "Encore",
    value: "Encore",
    brand: "Buick",
  },
  {
    label: "Envision",
    value: "Envision",
    brand: "Buick",
  },
  {
    label: "LaCrosse",
    value: "LaCrosse",
    brand: "Buick",
  },
  {
    label: "Le Sabre",
    value: "Le Sabre",
    brand: "Buick",
  },
  {
    label: "LeSabre",
    value: "LeSabre",
    brand: "Buick",
  },
  {
    label: "Lucerne",
    value: "Lucerne",
    brand: "Buick",
  },
  {
    label: "Park Avenue",
    value: "Park Avenue",
    brand: "Buick",
  },
  {
    label: "Rainier",
    value: "Rainier",
    brand: "Buick",
  },
  {
    label: "Regal",
    value: "Regal",
    brand: "Buick",
  },
  {
    label: "Rendezvous",
    value: "Rendezvous",
    brand: "Buick",
  },
  {
    label: "Riviera",
    value: "Riviera",
    brand: "Buick",
  },
  {
    label: "Roadmaster",
    value: "Roadmaster",
    brand: "Buick",
  },
  {
    label: "Skylark",
    value: "Skylark",
    brand: "Buick",
  },
  {
    label: "Super",
    value: "Super",
    brand: "Buick",
  },
  {
    label: "Terraza",
    value: "Terraza",
    brand: "Buick",
  },
  {
    label: "Verano",
    value: "Verano",
    brand: "Buick",
  },
  {
    label: "Wildcat",
    value: "Wildcat",
    brand: "Buick",
  },
  {
    label: "Tang",
    value: "Tang",
    brand: "BYD",
  },
  {
    label: "e6",
    value: "e6",
    brand: "BYD",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Cadillac",
  },
  {
    label: "Allante",
    value: "Allante",
    brand: "Cadillac",
  },
  {
    label: "ATS",
    value: "ATS",
    brand: "Cadillac",
  },
  {
    label: "BLS",
    value: "BLS",
    brand: "Cadillac",
  },
  {
    label: "Brougham",
    value: "Brougham",
    brand: "Cadillac",
  },
  {
    label: "Catera",
    value: "Catera",
    brand: "Cadillac",
  },
  {
    label: "CT6",
    value: "CT6",
    brand: "Cadillac",
  },
  {
    label: "CTS",
    value: "CTS",
    brand: "Cadillac",
  },
  {
    label: "DeVille",
    value: "DeVille",
    brand: "Cadillac",
  },
  {
    label: "DTS",
    value: "DTS",
    brand: "Cadillac",
  },
  {
    label: "Eldorado",
    value: "Eldorado",
    brand: "Cadillac",
  },
  {
    label: "ELR",
    value: "ELR",
    brand: "Cadillac",
  },
  {
    label: "Escalade",
    value: "Escalade",
    brand: "Cadillac",
  },
  {
    label: "Fleetwood",
    value: "Fleetwood",
    brand: "Cadillac",
  },
  {
    label: "Seville",
    value: "Seville",
    brand: "Cadillac",
  },
  {
    label: "SRX",
    value: "SRX",
    brand: "Cadillac",
  },
  {
    label: "STS",
    value: "STS",
    brand: "Cadillac",
  },
  {
    label: "Vizon",
    value: "Vizon",
    brand: "Cadillac",
  },
  {
    label: "XLR",
    value: "XLR",
    brand: "Cadillac",
  },
  {
    label: "XT4",
    value: "XT4",
    brand: "Cadillac",
  },
  {
    label: "XT5",
    value: "XT5",
    brand: "Cadillac",
  },
  {
    label: "XT6",
    value: "XT6",
    brand: "Cadillac",
  },
  {
    label: "XTS",
    value: "XTS",
    brand: "Cadillac",
  },
  {
    label: "Barooder",
    value: "Barooder",
    brand: "Chatenet",
  },
  {
    label: "CH26",
    value: "CH26",
    brand: "Chatenet",
  },
  {
    label: "CH28",
    value: "CH28",
    brand: "Chatenet",
  },
  {
    label: "CH30",
    value: "CH30",
    brand: "Chatenet",
  },
  {
    label: "CH39 Sporteevo",
    value: "CH39 Sporteevo",
    brand: "Chatenet",
  },
  {
    label: "Media",
    value: "Media",
    brand: "Chatenet",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Chevrolet",
  },
  {
    label: "2500",
    value: "2500",
    brand: "Chevrolet",
  },
  {
    label: "Alero",
    value: "Alero",
    brand: "Chevrolet",
  },
  {
    label: "Astro",
    value: "Astro",
    brand: "Chevrolet",
  },
  {
    label: "Avalanche",
    value: "Avalanche",
    brand: "Chevrolet",
  },
  {
    label: "Aveo",
    value: "Aveo",
    brand: "Chevrolet",
  },
  {
    label: "Bel Air",
    value: "Bel Air",
    brand: "Chevrolet",
  },
  {
    label: "Beretta",
    value: "Beretta",
    brand: "Chevrolet",
  },
  {
    label: "Blazer",
    value: "Blazer",
    brand: "Chevrolet",
  },
  {
    label: "Bolt",
    value: "Bolt",
    brand: "Chevrolet",
  },
  {
    label: "C1500",
    value: "C1500",
    brand: "Chevrolet",
  },
  {
    label: "Camaro",
    value: "Camaro",
    brand: "Chevrolet",
  },
  {
    label: "Caprice",
    value: "Caprice",
    brand: "Chevrolet",
  },
  {
    label: "Captiva",
    value: "Captiva",
    brand: "Chevrolet",
  },
  {
    label: "Cavalier",
    value: "Cavalier",
    brand: "Chevrolet",
  },
  {
    label: "Chevelle",
    value: "Chevelle",
    brand: "Chevrolet",
  },
  {
    label: "Chevy Van",
    value: "Chevy Van",
    brand: "Chevrolet",
  },
  {
    label: "Citation",
    value: "Citation",
    brand: "Chevrolet",
  },
  {
    label: "Cobalt",
    value: "Cobalt",
    brand: "Chevrolet",
  },
  {
    label: "Colorado",
    value: "Colorado",
    brand: "Chevrolet",
  },
  {
    label: "Corsica",
    value: "Corsica",
    brand: "Chevrolet",
  },
  {
    label: "Corvette",
    value: "Corvette",
    brand: "Chevrolet",
  },
  {
    label: "Cruze",
    value: "Cruze",
    brand: "Chevrolet",
  },
  {
    label: "El Camino",
    value: "El Camino",
    brand: "Chevrolet",
  },
  {
    label: "Epica",
    value: "Epica",
    brand: "Chevrolet",
  },
  {
    label: "Equinox",
    value: "Equinox",
    brand: "Chevrolet",
  },
  {
    label: "Evanda",
    value: "Evanda",
    brand: "Chevrolet",
  },
  {
    label: "Express",
    value: "Express",
    brand: "Chevrolet",
  },
  {
    label: "HHR",
    value: "HHR",
    brand: "Chevrolet",
  },
  {
    label: "Impala",
    value: "Impala",
    brand: "Chevrolet",
  },
  {
    label: "Kalos",
    value: "Kalos",
    brand: "Chevrolet",
  },
  {
    label: "Lacetti",
    value: "Lacetti",
    brand: "Chevrolet",
  },
  {
    label: "Lumina",
    value: "Lumina",
    brand: "Chevrolet",
  },
  {
    label: "M-1009",
    value: "M-1009",
    brand: "Chevrolet",
  },
  {
    label: "Malibu",
    value: "Malibu",
    brand: "Chevrolet",
  },
  {
    label: "Matiz",
    value: "Matiz",
    brand: "Chevrolet",
  },
  {
    label: "Metro",
    value: "Metro",
    brand: "Chevrolet",
  },
  {
    label: "Monte Carlo",
    value: "Monte Carlo",
    brand: "Chevrolet",
  },
  {
    label: "Nubira",
    value: "Nubira",
    brand: "Chevrolet",
  },
  {
    label: "Orlando",
    value: "Orlando",
    brand: "Chevrolet",
  },
  {
    label: "Pickup",
    value: "Pickup",
    brand: "Chevrolet",
  },
  {
    label: "Rezzo",
    value: "Rezzo",
    brand: "Chevrolet",
  },
  {
    label: "S-10",
    value: "S-10",
    brand: "Chevrolet",
  },
  {
    label: "Silverado",
    value: "Silverado",
    brand: "Chevrolet",
  },
  {
    label: "Sonic",
    value: "Sonic",
    brand: "Chevrolet",
  },
  {
    label: "Spark",
    value: "Spark",
    brand: "Chevrolet",
  },
  {
    label: "Special Deluxe",
    value: "Special Deluxe",
    brand: "Chevrolet",
  },
  {
    label: "SS",
    value: "SS",
    brand: "Chevrolet",
  },
  {
    label: "SSR",
    value: "SSR",
    brand: "Chevrolet",
  },
  {
    label: "Suburban",
    value: "Suburban",
    brand: "Chevrolet",
  },
  {
    label: "Tacuma",
    value: "Tacuma",
    brand: "Chevrolet",
  },
  {
    label: "Tahoe",
    value: "Tahoe",
    brand: "Chevrolet",
  },
  {
    label: "Tracker",
    value: "Tracker",
    brand: "Chevrolet",
  },
  {
    label: "TrailBlazer",
    value: "TrailBlazer",
    brand: "Chevrolet",
  },
  {
    label: "Trans Sport",
    value: "Trans Sport",
    brand: "Chevrolet",
  },
  {
    label: "Traverse",
    value: "Traverse",
    brand: "Chevrolet",
  },
  {
    label: "Trax",
    value: "Trax",
    brand: "Chevrolet",
  },
  {
    label: "Uplander",
    value: "Uplander",
    brand: "Chevrolet",
  },
  {
    label: "Venture",
    value: "Venture",
    brand: "Chevrolet",
  },
  {
    label: "Volt",
    value: "Volt",
    brand: "Chevrolet",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Chrysler",
  },
  {
    label: "200",
    value: "200",
    brand: "Chrysler",
  },
  {
    label: "300",
    value: "300",
    brand: "Chrysler",
  },
  {
    label: "300C",
    value: "300C",
    brand: "Chrysler",
  },
  {
    label: "300M",
    value: "300M",
    brand: "Chrysler",
  },
  {
    label: "Aspen",
    value: "Aspen",
    brand: "Chrysler",
  },
  {
    label: "Cirrus",
    value: "Cirrus",
    brand: "Chrysler",
  },
  {
    label: "Concorde",
    value: "Concorde",
    brand: "Chrysler",
  },
  {
    label: "Conquest",
    value: "Conquest",
    brand: "Chrysler",
  },
  {
    label: "Crossfire",
    value: "Crossfire",
    brand: "Chrysler",
  },
  {
    label: "Daytona",
    value: "Daytona",
    brand: "Chrysler",
  },
  {
    label: "Daytona Shelby",
    value: "Daytona Shelby",
    brand: "Chrysler",
  },
  {
    label: "ES",
    value: "ES",
    brand: "Chrysler",
  },
  {
    label: "Grand Voyager",
    value: "Grand Voyager",
    brand: "Chrysler",
  },
  {
    label: "Imperial",
    value: "Imperial",
    brand: "Chrysler",
  },
  {
    label: "Intrepid",
    value: "Intrepid",
    brand: "Chrysler",
  },
  {
    label: "Le Baron",
    value: "Le Baron",
    brand: "Chrysler",
  },
  {
    label: "LHS",
    value: "LHS",
    brand: "Chrysler",
  },
  {
    label: "Neon",
    value: "Neon",
    brand: "Chrysler",
  },
  {
    label: "New Yorker",
    value: "New Yorker",
    brand: "Chrysler",
  },
  {
    label: "Newport",
    value: "Newport",
    brand: "Chrysler",
  },
  {
    label: "Pacifica",
    value: "Pacifica",
    brand: "Chrysler",
  },
  {
    label: "Plymouth",
    value: "Plymouth",
    brand: "Chrysler",
  },
  {
    label: "Prowler",
    value: "Prowler",
    brand: "Chrysler",
  },
  {
    label: "PT Cruiser",
    value: "PT Cruiser",
    brand: "Chrysler",
  },
  {
    label: "Saratoga",
    value: "Saratoga",
    brand: "Chrysler",
  },
  {
    label: "Sebring",
    value: "Sebring",
    brand: "Chrysler",
  },
  {
    label: "Stratus",
    value: "Stratus",
    brand: "Chrysler",
  },
  {
    label: "Town & Country",
    value: "Town & Country",
    brand: "Chrysler",
  },
  {
    label: "Valiant",
    value: "Valiant",
    brand: "Chrysler",
  },
  {
    label: "Viper",
    value: "Viper",
    brand: "Chrysler",
  },
  {
    label: "Vision",
    value: "Vision",
    brand: "Chrysler",
  },
  {
    label: "Voyager",
    value: "Voyager",
    brand: "Chrysler",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Citroen",
  },
  {
    label: "2 CV",
    value: "2 CV",
    brand: "Citroen",
  },
  {
    label: "Ami Pop",
    value: "Ami Pop",
    brand: "Citroen",
  },
  {
    label: "AX",
    value: "AX",
    brand: "Citroen",
  },
  {
    label: "Berlingo",
    value: "Berlingo",
    brand: "Citroen",
  },
  {
    label: "BX",
    value: "BX",
    brand: "Citroen",
  },
  {
    label: "C-Crosser",
    value: "C-Crosser",
    brand: "Citroen",
  },
  {
    label: "C-Elysee",
    value: "C-Elysee",
    brand: "Citroen",
  },
  {
    label: "C-Zero",
    value: "C-Zero",
    brand: "Citroen",
  },
  {
    label: "C1",
    value: "C1",
    brand: "Citroen",
  },
  {
    label: "C15",
    value: "C15",
    brand: "Citroen",
  },
  {
    label: "C2",
    value: "C2",
    brand: "Citroen",
  },
  {
    label: "C25",
    value: "C25",
    brand: "Citroen",
  },
  {
    label: "C3",
    value: "C3",
    brand: "Citroen",
  },
  {
    label: "C3 Aircross",
    value: "C3 Aircross",
    brand: "Citroen",
  },
  {
    label: "C3 Picasso",
    value: "C3 Picasso",
    brand: "Citroen",
  },
  {
    label: "C3 Pluriel",
    value: "C3 Pluriel",
    brand: "Citroen",
  },
  {
    label: "C4",
    value: "C4",
    brand: "Citroen",
  },
  {
    label: "C4 Aircross",
    value: "C4 Aircross",
    brand: "Citroen",
  },
  {
    label: "C4 Cactus",
    value: "C4 Cactus",
    brand: "Citroen",
  },
  {
    label: "C4 Picasso",
    value: "C4 Picasso",
    brand: "Citroen",
  },
  {
    label: "C4 X",
    value: "C4 X",
    brand: "Citroen",
  },
  {
    label: "C5",
    value: "C5",
    brand: "Citroen",
  },
  {
    label: "C5 Aircross",
    value: "C5 Aircross",
    brand: "Citroen",
  },
  {
    label: "C5 X",
    value: "C5 X",
    brand: "Citroen",
  },
  {
    label: "C6",
    value: "C6",
    brand: "Citroen",
  },
  {
    label: "C8",
    value: "C8",
    brand: "Citroen",
  },
  {
    label: "CX",
    value: "CX",
    brand: "Citroen",
  },
  {
    label: "DS",
    value: "DS",
    brand: "Citroen",
  },
  {
    label: "DS3",
    value: "DS3",
    brand: "Citroen",
  },
  {
    label: "DS3 Crossback",
    value: "DS3 Crossback",
    brand: "Citroen",
  },
  {
    label: "DS4",
    value: "DS4",
    brand: "Citroen",
  },
  {
    label: "DS5",
    value: "DS5",
    brand: "Citroen",
  },
  {
    label: "DS7",
    value: "DS7",
    brand: "Citroen",
  },
  {
    label: "DS7 Crossback",
    value: "DS7 Crossback",
    brand: "Citroen",
  },
  {
    label: "e-Berlingo",
    value: "e-Berlingo",
    brand: "Citroen",
  },
  {
    label: "e-C4",
    value: "e-C4",
    brand: "Citroen",
  },
  {
    label: "e-Mehari",
    value: "e-Mehari",
    brand: "Citroen",
  },
  {
    label: "Evasion",
    value: "Evasion",
    brand: "Citroen",
  },
  {
    label: "Grand C4 Picasso",
    value: "Grand C4 Picasso",
    brand: "Citroen",
  },
  {
    label: "GSA",
    value: "GSA",
    brand: "Citroen",
  },
  {
    label: "Jumper",
    value: "Jumper",
    brand: "Citroen",
  },
  {
    label: "Jumpy",
    value: "Jumpy",
    brand: "Citroen",
  },
  {
    label: "Nemo",
    value: "Nemo",
    brand: "Citroen",
  },
  {
    label: "Saxo",
    value: "Saxo",
    brand: "Citroen",
  },
  {
    label: "SM",
    value: "SM",
    brand: "Citroen",
  },
  {
    label: "Spacetourer",
    value: "Spacetourer",
    brand: "Citroen",
  },
  {
    label: "Synergie",
    value: "Synergie",
    brand: "Citroen",
  },
  {
    label: "Visa",
    value: "Visa",
    brand: "Citroen",
  },
  {
    label: "Xantia",
    value: "Xantia",
    brand: "Citroen",
  },
  {
    label: "XM",
    value: "XM",
    brand: "Citroen",
  },
  {
    label: "Xsara",
    value: "Xsara",
    brand: "Citroen",
  },
  {
    label: "Xsara Picasso",
    value: "Xsara Picasso",
    brand: "Citroen",
  },
  {
    label: "ZX",
    value: "ZX",
    brand: "Citroen",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Cobra",
  },
  {
    label: "AC",
    value: "AC",
    brand: "Cobra",
  },
  {
    label: "Ateca",
    value: "Ateca",
    brand: "Cupra",
  },
  {
    label: "Born",
    value: "Born",
    brand: "Cupra",
  },
  {
    label: "Formentor",
    value: "Formentor",
    brand: "Cupra",
  },
  {
    label: "Leon",
    value: "Leon",
    brand: "Cupra",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Dacia",
  },
  {
    label: "Dokker",
    value: "Dokker",
    brand: "Dacia",
  },
  {
    label: "Duster",
    value: "Duster",
    brand: "Dacia",
  },
  {
    label: "Jogger",
    value: "Jogger",
    brand: "Dacia",
  },
  {
    label: "Lodgy",
    value: "Lodgy",
    brand: "Dacia",
  },
  {
    label: "Logan",
    value: "Logan",
    brand: "Dacia",
  },
  {
    label: "Sandero",
    value: "Sandero",
    brand: "Dacia",
  },
  {
    label: "Spring",
    value: "Spring",
    brand: "Dacia",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Daewoo",
  },
  {
    label: "Espero",
    value: "Espero",
    brand: "Daewoo",
  },
  {
    label: "Evanda",
    value: "Evanda",
    brand: "Daewoo",
  },
  {
    label: "Istana",
    value: "Istana",
    brand: "Daewoo",
  },
  {
    label: "Kalos",
    value: "Kalos",
    brand: "Daewoo",
  },
  {
    label: "Korando",
    value: "Korando",
    brand: "Daewoo",
  },
  {
    label: "Labo",
    value: "Labo",
    brand: "Daewoo",
  },
  {
    label: "Lacetti",
    value: "Lacetti",
    brand: "Daewoo",
  },
  {
    label: "Lanos",
    value: "Lanos",
    brand: "Daewoo",
  },
  {
    label: "Leganza",
    value: "Leganza",
    brand: "Daewoo",
  },
  {
    label: "Lublin",
    value: "Lublin",
    brand: "Daewoo",
  },
  {
    label: "Magnus",
    value: "Magnus",
    brand: "Daewoo",
  },
  {
    label: "Matiz",
    value: "Matiz",
    brand: "Daewoo",
  },
  {
    label: "Musso",
    value: "Musso",
    brand: "Daewoo",
  },
  {
    label: "Nexia",
    value: "Nexia",
    brand: "Daewoo",
  },
  {
    label: "Nubira",
    value: "Nubira",
    brand: "Daewoo",
  },
  {
    label: "Polonez",
    value: "Polonez",
    brand: "Daewoo",
  },
  {
    label: "Prince",
    value: "Prince",
    brand: "Daewoo",
  },
  {
    label: "Racer",
    value: "Racer",
    brand: "Daewoo",
  },
  {
    label: "Rexton",
    value: "Rexton",
    brand: "Daewoo",
  },
  {
    label: "Rezzo",
    value: "Rezzo",
    brand: "Daewoo",
  },
  {
    label: "Tacuma",
    value: "Tacuma",
    brand: "Daewoo",
  },
  {
    label: "Tico",
    value: "Tico",
    brand: "Daewoo",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "DAF",
  },
  {
    label: "33",
    value: "33",
    brand: "DAF",
  },
  {
    label: "44",
    value: "44",
    brand: "DAF",
  },
  {
    label: "46",
    value: "46",
    brand: "DAF",
  },
  {
    label: "55",
    value: "55",
    brand: "DAF",
  },
  {
    label: "600",
    value: "600",
    brand: "DAF",
  },
  {
    label: "1000",
    value: "1000",
    brand: "Daihatsu",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Daihatsu",
  },
  {
    label: "Applause",
    value: "Applause",
    brand: "Daihatsu",
  },
  {
    label: "Charade",
    value: "Charade",
    brand: "Daihatsu",
  },
  {
    label: "Charmant",
    value: "Charmant",
    brand: "Daihatsu",
  },
  {
    label: "Copen",
    value: "Copen",
    brand: "Daihatsu",
  },
  {
    label: "Cuore",
    value: "Cuore",
    brand: "Daihatsu",
  },
  {
    label: "Delta",
    value: "Delta",
    brand: "Daihatsu",
  },
  {
    label: "Feroza",
    value: "Feroza",
    brand: "Daihatsu",
  },
  {
    label: "Freeclimber",
    value: "Freeclimber",
    brand: "Daihatsu",
  },
  {
    label: "Gran Move",
    value: "Gran Move",
    brand: "Daihatsu",
  },
  {
    label: "Hi Jet",
    value: "Hi Jet",
    brand: "Daihatsu",
  },
  {
    label: "Materia",
    value: "Materia",
    brand: "Daihatsu",
  },
  {
    label: "Max",
    value: "Max",
    brand: "Daihatsu",
  },
  {
    label: "Mira",
    value: "Mira",
    brand: "Daihatsu",
  },
  {
    label: "Move",
    value: "Move",
    brand: "Daihatsu",
  },
  {
    label: "Rocky",
    value: "Rocky",
    brand: "Daihatsu",
  },
  {
    label: "Sirion",
    value: "Sirion",
    brand: "Daihatsu",
  },
  {
    label: "Sparcar",
    value: "Sparcar",
    brand: "Daihatsu",
  },
  {
    label: "Terios",
    value: "Terios",
    brand: "Daihatsu",
  },
  {
    label: "Trevis",
    value: "Trevis",
    brand: "Daihatsu",
  },
  {
    label: "Valera",
    value: "Valera",
    brand: "Daihatsu",
  },
  {
    label: "YRV",
    value: "YRV",
    brand: "Daihatsu",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Datsun",
  },
  {
    label: "240z",
    value: "240z",
    brand: "Datsun",
  },
  {
    label: "260z",
    value: "260z",
    brand: "Datsun",
  },
  {
    label: "280z",
    value: "280z",
    brand: "Datsun",
  },
  {
    label: "DMC-12",
    value: "DMC-12",
    brand: "Delorean",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Desoto",
  },
  {
    label: "Airflow",
    value: "Airflow",
    brand: "Desoto",
  },
  {
    label: "Airstream",
    value: "Airstream",
    brand: "Desoto",
  },
  {
    label: "Custom",
    value: "Custom",
    brand: "Desoto",
  },
  {
    label: "Deluxe",
    value: "Deluxe",
    brand: "Desoto",
  },
  {
    label: "Fireflite",
    value: "Fireflite",
    brand: "Desoto",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Dodge",
  },
  {
    label: "Avenger",
    value: "Avenger",
    brand: "Dodge",
  },
  {
    label: "Caliber",
    value: "Caliber",
    brand: "Dodge",
  },
  {
    label: "Caravan",
    value: "Caravan",
    brand: "Dodge",
  },
  {
    label: "Challenger",
    value: "Challenger",
    brand: "Dodge",
  },
  {
    label: "Charger",
    value: "Charger",
    brand: "Dodge",
  },
  {
    label: "Dakota",
    value: "Dakota",
    brand: "Dodge",
  },
  {
    label: "Dart",
    value: "Dart",
    brand: "Dodge",
  },
  {
    label: "Demon",
    value: "Demon",
    brand: "Dodge",
  },
  {
    label: "Durango",
    value: "Durango",
    brand: "Dodge",
  },
  {
    label: "Grand Caravan",
    value: "Grand Caravan",
    brand: "Dodge",
  },
  {
    label: "Hornet",
    value: "Hornet",
    brand: "Dodge",
  },
  {
    label: "Intrepid",
    value: "Intrepid",
    brand: "Dodge",
  },
  {
    label: "Journey",
    value: "Journey",
    brand: "Dodge",
  },
  {
    label: "Magnum",
    value: "Magnum",
    brand: "Dodge",
  },
  {
    label: "Neon",
    value: "Neon",
    brand: "Dodge",
  },
  {
    label: "Nitro",
    value: "Nitro",
    brand: "Dodge",
  },
  {
    label: "Ram",
    value: "Ram",
    brand: "Dodge",
  },
  {
    label: "Spirit",
    value: "Spirit",
    brand: "Dodge",
  },
  {
    label: "Stealth",
    value: "Stealth",
    brand: "Dodge",
  },
  {
    label: "Stratus",
    value: "Stratus",
    brand: "Dodge",
  },
  {
    label: "Viper",
    value: "Viper",
    brand: "Dodge",
  },
  {
    label: "Voyager",
    value: "Voyager",
    brand: "Dodge",
  },
  {
    label: "E20",
    value: "E20",
    brand: "Dorcen",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "DR Motor",
  },
  {
    label: "DR5",
    value: "DR5",
    brand: "DR Motor",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "DS Automobiles",
  },
  {
    label: "DS3",
    value: "DS3",
    brand: "DS Automobiles",
  },
  {
    label: "DS4",
    value: "DS4",
    brand: "DS Automobiles",
  },
  {
    label: "DS4 Crossback",
    value: "DS4 Crossback",
    brand: "DS Automobiles",
  },
  {
    label: "DS5",
    value: "DS5",
    brand: "DS Automobiles",
  },
  {
    label: "DS7 Crossback",
    value: "DS7 Crossback",
    brand: "DS Automobiles",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Eagle",
  },
  {
    label: "Summit",
    value: "Summit",
    brand: "Eagle",
  },
  {
    label: "Summit Wagon",
    value: "Summit Wagon",
    brand: "Eagle",
  },
  {
    label: "Talon",
    value: "Talon",
    brand: "Eagle",
  },
  {
    label: "Vision",
    value: "Vision",
    brand: "Eagle",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Excalibur",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Fiat",
  },
  {
    label: "124",
    value: "124",
    brand: "Fiat",
  },
  {
    label: "126",
    value: "126",
    brand: "Fiat",
  },
  {
    label: "127",
    value: "127",
    brand: "Fiat",
  },
  {
    label: "131",
    value: "131",
    brand: "Fiat",
  },
  {
    label: "500",
    value: "500",
    brand: "Fiat",
  },
  {
    label: "500 Abarth",
    value: "500 Abarth",
    brand: "Fiat",
  },
  {
    label: "500L",
    value: "500L",
    brand: "Fiat",
  },
  {
    label: "500X",
    value: "500X",
    brand: "Fiat",
  },
  {
    label: "850 Spider",
    value: "850 Spider",
    brand: "Fiat",
  },
  {
    label: "Albea",
    value: "Albea",
    brand: "Fiat",
  },
  {
    label: "Barchetta",
    value: "Barchetta",
    brand: "Fiat",
  },
  {
    label: "Brava",
    value: "Brava",
    brand: "Fiat",
  },
  {
    label: "Bravo",
    value: "Bravo",
    brand: "Fiat",
  },
  {
    label: "Cinquecento",
    value: "Cinquecento",
    brand: "Fiat",
  },
  {
    label: "Coupe",
    value: "Coupe",
    brand: "Fiat",
  },
  {
    label: "Croma",
    value: "Croma",
    brand: "Fiat",
  },
  {
    label: "Dino",
    value: "Dino",
    brand: "Fiat",
  },
  {
    label: "Doblo",
    value: "Doblo",
    brand: "Fiat",
  },
  {
    label: "Ducato",
    value: "Ducato",
    brand: "Fiat",
  },
  {
    label: "Duna",
    value: "Duna",
    brand: "Fiat",
  },
  {
    label: "Fiorino",
    value: "Fiorino",
    brand: "Fiat",
  },
  {
    label: "Freemont",
    value: "Freemont",
    brand: "Fiat",
  },
  {
    label: "Fullback",
    value: "Fullback",
    brand: "Fiat",
  },
  {
    label: "Grande Punto",
    value: "Grande Punto",
    brand: "Fiat",
  },
  {
    label: "Idea",
    value: "Idea",
    brand: "Fiat",
  },
  {
    label: "Linea",
    value: "Linea",
    brand: "Fiat",
  },
  {
    label: "Marea",
    value: "Marea",
    brand: "Fiat",
  },
  {
    label: "Marengo",
    value: "Marengo",
    brand: "Fiat",
  },
  {
    label: "Multipla",
    value: "Multipla",
    brand: "Fiat",
  },
  {
    label: "Palio",
    value: "Palio",
    brand: "Fiat",
  },
  {
    label: "Palio Weekend",
    value: "Palio Weekend",
    brand: "Fiat",
  },
  {
    label: "Panda",
    value: "Panda",
    brand: "Fiat",
  },
  {
    label: "Punto",
    value: "Punto",
    brand: "Fiat",
  },
  {
    label: "Punto Evo",
    value: "Punto Evo",
    brand: "Fiat",
  },
  {
    label: "Qubo",
    value: "Qubo",
    brand: "Fiat",
  },
  {
    label: "Qubo",
    value: "Qubo",
    brand: "Fiat",
  },
  {
    label: "Regata",
    value: "Regata",
    brand: "Fiat",
  },
  {
    label: "Ritmo",
    value: "Ritmo",
    brand: "Fiat",
  },
  {
    label: "Scudo",
    value: "Scudo",
    brand: "Fiat",
  },
  {
    label: "Sedici",
    value: "Sedici",
    brand: "Fiat",
  },
  {
    label: "Seicento",
    value: "Seicento",
    brand: "Fiat",
  },
  {
    label: "Stilo",
    value: "Stilo",
    brand: "Fiat",
  },
  {
    label: "Strada",
    value: "Strada",
    brand: "Fiat",
  },
  {
    label: "Talento",
    value: "Talento",
    brand: "Fiat",
  },
  {
    label: "Tempra",
    value: "Tempra",
    brand: "Fiat",
  },
  {
    label: "Tipo",
    value: "Tipo",
    brand: "Fiat",
  },
  {
    label: "Ulysse",
    value: "Ulysse",
    brand: "Fiat",
  },
  {
    label: "Uno",
    value: "Uno",
    brand: "Fiat",
  },
  {
    label: "X 1/9",
    value: "X 1/9",
    brand: "Fiat",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Fisker",
  },
  {
    label: "Atlantic",
    value: "Atlantic",
    brand: "Fisker",
  },
  {
    label: "Karma",
    value: "Karma",
    brand: "Fisker",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Ford",
  },
  {
    label: "Aerostar",
    value: "Aerostar",
    brand: "Ford",
  },
  {
    label: "Aspire",
    value: "Aspire",
    brand: "Ford",
  },
  {
    label: "B-MAX",
    value: "B-MAX",
    brand: "Ford",
  },
  {
    label: "Bronco",
    value: "Bronco",
    brand: "Ford",
  },
  {
    label: "C-MAX",
    value: "C-MAX",
    brand: "Ford",
  },
  {
    label: "Capri",
    value: "Capri",
    brand: "Ford",
  },
  {
    label: "Caravan",
    value: "Caravan",
    brand: "Ford",
  },
  {
    label: "Cargo",
    value: "Cargo",
    brand: "Ford",
  },
  {
    label: "Club Wagon",
    value: "Club Wagon",
    brand: "Ford",
  },
  {
    label: "Connect Tourneo",
    value: "Connect Tourneo",
    brand: "Ford",
  },
  {
    label: "Contour",
    value: "Contour",
    brand: "Ford",
  },
  {
    label: "Cougar",
    value: "Cougar",
    brand: "Ford",
  },
  {
    label: "Courier",
    value: "Courier",
    brand: "Ford",
  },
  {
    label: "Crown Victoria",
    value: "Crown Victoria",
    brand: "Ford",
  },
  {
    label: "Econoline",
    value: "Econoline",
    brand: "Ford",
  },
  {
    label: "Econovan",
    value: "Econovan",
    brand: "Ford",
  },
  {
    label: "EcoSport",
    value: "EcoSport",
    brand: "Ford",
  },
  {
    label: "Edge",
    value: "Edge",
    brand: "Ford",
  },
  {
    label: "Escape",
    value: "Escape",
    brand: "Ford",
  },
  {
    label: "Escort",
    value: "Escort",
    brand: "Ford",
  },
  {
    label: "Excursion",
    value: "Excursion",
    brand: "Ford",
  },
  {
    label: "Expedition",
    value: "Expedition",
    brand: "Ford",
  },
  {
    label: "Explorer",
    value: "Explorer",
    brand: "Ford",
  },
  {
    label: "Express",
    value: "Express",
    brand: "Ford",
  },
  {
    label: "F-100",
    value: "F-100",
    brand: "Ford",
  },
  {
    label: "F-150",
    value: "F-150",
    brand: "Ford",
  },
  {
    label: "F-250",
    value: "F-250",
    brand: "Ford",
  },
  {
    label: "F-350",
    value: "F-350",
    brand: "Ford",
  },
  {
    label: "F-450",
    value: "F-450",
    brand: "Ford",
  },
  {
    label: "F-470",
    value: "F-470",
    brand: "Ford",
  },
  {
    label: "F-700",
    value: "F-700",
    brand: "Ford",
  },
  {
    label: "Fairlane",
    value: "Fairlane",
    brand: "Ford",
  },
  {
    label: "Falcon",
    value: "Falcon",
    brand: "Ford",
  },
  {
    label: "Fiesta",
    value: "Fiesta",
    brand: "Ford",
  },
  {
    label: "Five Hundred",
    value: "Five Hundred",
    brand: "Ford",
  },
  {
    label: "Flex",
    value: "Flex",
    brand: "Ford",
  },
  {
    label: "Focus",
    value: "Focus",
    brand: "Ford",
  },
  {
    label: "Focus RS",
    value: "Focus RS",
    brand: "Ford",
  },
  {
    label: "Focus ST",
    value: "Focus ST",
    brand: "Ford",
  },
  {
    label: "Freestar",
    value: "Freestar",
    brand: "Ford",
  },
  {
    label: "Freestyle",
    value: "Freestyle",
    brand: "Ford",
  },
  {
    label: "Fusion",
    value: "Fusion",
    brand: "Ford",
  },
  {
    label: "Galaxy",
    value: "Galaxy",
    brand: "Ford",
  },
  {
    label: "Granada",
    value: "Granada",
    brand: "Ford",
  },
  {
    label: "Grand C-MAX",
    value: "Grand C-MAX",
    brand: "Ford",
  },
  {
    label: "GT",
    value: "GT",
    brand: "Ford",
  },
  {
    label: "Ka",
    value: "Ka",
    brand: "Ford",
  },
  {
    label: "Ka+",
    value: "Ka+",
    brand: "Ford",
  },
  {
    label: "Kuga",
    value: "Kuga",
    brand: "Ford",
  },
  {
    label: "LTD",
    value: "LTD",
    brand: "Ford",
  },
  {
    label: "Maverick",
    value: "Maverick",
    brand: "Ford",
  },
  {
    label: "Mercury",
    value: "Mercury",
    brand: "Ford",
  },
  {
    label: "Mondeo",
    value: "Mondeo",
    brand: "Ford",
  },
  {
    label: "Mustang",
    value: "Mustang",
    brand: "Ford",
  },
  {
    label: "Mustang Mach-E",
    value: "Mustang Mach-E",
    brand: "Ford",
  },
  {
    label: "Orion",
    value: "Orion",
    brand: "Ford",
  },
  {
    label: "Probe",
    value: "Probe",
    brand: "Ford",
  },
  {
    label: "Puma",
    value: "Puma",
    brand: "Ford",
  },
  {
    label: "Ranchero",
    value: "Ranchero",
    brand: "Ford",
  },
  {
    label: "Ranger",
    value: "Ranger",
    brand: "Ford",
  },
  {
    label: "Raptor",
    value: "Raptor",
    brand: "Ford",
  },
  {
    label: "S-MAX",
    value: "S-MAX",
    brand: "Ford",
  },
  {
    label: "Scorpio",
    value: "Scorpio",
    brand: "Ford",
  },
  {
    label: "Sierra",
    value: "Sierra",
    brand: "Ford",
  },
  {
    label: "Sportka",
    value: "Sportka",
    brand: "Ford",
  },
  {
    label: "Streetka",
    value: "Streetka",
    brand: "Ford",
  },
  {
    label: "Superduty",
    value: "Superduty",
    brand: "Ford",
  },
  {
    label: "Taunus",
    value: "Taunus",
    brand: "Ford",
  },
  {
    label: "Taurus",
    value: "Taurus",
    brand: "Ford",
  },
  {
    label: "Tempo",
    value: "Tempo",
    brand: "Ford",
  },
  {
    label: "Thunderbird",
    value: "Thunderbird",
    brand: "Ford",
  },
  {
    label: "Tourneo",
    value: "Tourneo",
    brand: "Ford",
  },
  {
    label: "Tourneo Connect",
    value: "Tourneo Connect",
    brand: "Ford",
  },
  {
    label: "Tourneo Custom",
    value: "Tourneo Custom",
    brand: "Ford",
  },
  {
    label: "Transit",
    value: "Transit",
    brand: "Ford",
  },
  {
    label: "Transit Connect",
    value: "Transit Connect",
    brand: "Ford",
  },
  {
    label: "Transit Courier",
    value: "Transit Courier",
    brand: "Ford",
  },
  {
    label: "Transit Custom",
    value: "Transit Custom",
    brand: "Ford",
  },
  {
    label: "Windstar",
    value: "Windstar",
    brand: "Ford",
  },
  {
    label: "22",
    value: "22",
    brand: "GAZ",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "GAZ",
  },
  {
    label: "11-73",
    value: "11-73",
    brand: "GAZ",
  },
  {
    label: "12 ZiM",
    value: "12 ZiM",
    brand: "GAZ",
  },
  {
    label: "12B",
    value: "12B",
    brand: "GAZ",
  },
  {
    label: "13",
    value: "13",
    brand: "GAZ",
  },
  {
    label: "14",
    value: "14",
    brand: "GAZ",
  },
  {
    label: "21",
    value: "21",
    brand: "GAZ",
  },
  {
    label: "2110",
    value: "2110",
    brand: "GAZ",
  },
  {
    label: "24",
    value: "24",
    brand: "GAZ",
  },
  {
    label: "2410",
    value: "2410",
    brand: "GAZ",
  },
  {
    label: "2752",
    value: "2752",
    brand: "GAZ",
  },
  {
    label: "3110",
    value: "3110",
    brand: "GAZ",
  },
  {
    label: "32213",
    value: "32213",
    brand: "GAZ",
  },
  {
    label: "3302",
    value: "3302",
    brand: "GAZ",
  },
  {
    label: "3308",
    value: "3308",
    brand: "GAZ",
  },
  {
    label: "53",
    value: "53",
    brand: "GAZ",
  },
  {
    label: "61",
    value: "61",
    brand: "GAZ",
  },
  {
    label: "64",
    value: "64",
    brand: "GAZ",
  },
  {
    label: "66",
    value: "66",
    brand: "GAZ",
  },
  {
    label: "67",
    value: "67",
    brand: "GAZ",
  },
  {
    label: "69A",
    value: "69A",
    brand: "GAZ",
  },
  {
    label: "Chaika",
    value: "Chaika",
    brand: "GAZ",
  },
  {
    label: "Gazelle",
    value: "Gazelle",
    brand: "GAZ",
  },
  {
    label: "M-20 Pobeda",
    value: "M-20 Pobeda",
    brand: "GAZ",
  },
  {
    label: "M1",
    value: "M1",
    brand: "GAZ",
  },
  {
    label: "Volga",
    value: "Volga",
    brand: "GAZ",
  },
  {
    label: "G70",
    value: "G70",
    brand: "Genesis",
  },
  {
    label: "G80",
    value: "G80",
    brand: "Genesis",
  },
  {
    label: "G90",
    value: "G90",
    brand: "Genesis",
  },
  {
    label: "GV60",
    value: "GV60",
    brand: "Genesis",
  },
  {
    label: "GV70",
    value: "GV70",
    brand: "Genesis",
  },
  {
    label: "GV80",
    value: "GV80",
    brand: "Genesis",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Geo",
  },
  {
    label: "Metro",
    value: "Metro",
    brand: "Geo",
  },
  {
    label: "Prizm",
    value: "Prizm",
    brand: "Geo",
  },
  {
    label: "Spectrum",
    value: "Spectrum",
    brand: "Geo",
  },
  {
    label: "Storm",
    value: "Storm",
    brand: "Geo",
  },
  {
    label: "Tracker",
    value: "Tracker",
    brand: "Geo",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "GMC",
  },
  {
    label: "Acadia",
    value: "Acadia",
    brand: "GMC",
  },
  {
    label: "Canyon",
    value: "Canyon",
    brand: "GMC",
  },
  {
    label: "Envoy",
    value: "Envoy",
    brand: "GMC",
  },
  {
    label: "Jimmy",
    value: "Jimmy",
    brand: "GMC",
  },
  {
    label: "Safari",
    value: "Safari",
    brand: "GMC",
  },
  {
    label: "Savana",
    value: "Savana",
    brand: "GMC",
  },
  {
    label: "Sierra",
    value: "Sierra",
    brand: "GMC",
  },
  {
    label: "Sonoma",
    value: "Sonoma",
    brand: "GMC",
  },
  {
    label: "Suburban",
    value: "Suburban",
    brand: "GMC",
  },
  {
    label: "Terrain",
    value: "Terrain",
    brand: "GMC",
  },
  {
    label: "Yukon",
    value: "Yukon",
    brand: "GMC",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Gonow",
  },
  {
    label: "GA200",
    value: "GA200",
    brand: "Gonow",
  },
  {
    label: "GX6",
    value: "GX6",
    brand: "Gonow",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Great Wall",
  },
  {
    label: "Deer",
    value: "Deer",
    brand: "Great Wall",
  },
  {
    label: "Hover",
    value: "Hover",
    brand: "Great Wall",
  },
  {
    label: "Pegasus",
    value: "Pegasus",
    brand: "Great Wall",
  },
  {
    label: "Socool",
    value: "Socool",
    brand: "Great Wall",
  },
  {
    label: "Wingle",
    value: "Wingle",
    brand: "Great Wall",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Honda",
  },
  {
    label: "Accord",
    value: "Accord",
    brand: "Honda",
  },
  {
    label: "Aerodeck",
    value: "Aerodeck",
    brand: "Honda",
  },
  {
    label: "Avancier",
    value: "Avancier",
    brand: "Honda",
  },
  {
    label: "Capa",
    value: "Capa",
    brand: "Honda",
  },
  {
    label: "City",
    value: "City",
    brand: "Honda",
  },
  {
    label: "Civic",
    value: "Civic",
    brand: "Honda",
  },
  {
    label: "Clarity",
    value: "Clarity",
    brand: "Honda",
  },
  {
    label: "Concerto",
    value: "Concerto",
    brand: "Honda",
  },
  {
    label: "CR-V",
    value: "CR-V",
    brand: "Honda",
  },
  {
    label: "Crosstour",
    value: "Crosstour",
    brand: "Honda",
  },
  {
    label: "CRX",
    value: "CRX",
    brand: "Honda",
  },
  {
    label: "CRZ",
    value: "CRZ",
    brand: "Honda",
  },
  {
    label: "Domani",
    value: "Domani",
    brand: "Honda",
  },
  {
    label: "E",
    value: "E",
    brand: "Honda",
  },
  {
    label: "Element",
    value: "Element",
    brand: "Honda",
  },
  {
    label: "F- MX",
    value: "F- MX",
    brand: "Honda",
  },
  {
    label: "FIT",
    value: "FIT",
    brand: "Honda",
  },
  {
    label: "FR-V",
    value: "FR-V",
    brand: "Honda",
  },
  {
    label: "HR-V",
    value: "HR-V",
    brand: "Honda",
  },
  {
    label: "Insight",
    value: "Insight",
    brand: "Honda",
  },
  {
    label: "Integra",
    value: "Integra",
    brand: "Honda",
  },
  {
    label: "Jazz",
    value: "Jazz",
    brand: "Honda",
  },
  {
    label: "Jazz Crosstar",
    value: "Jazz Crosstar",
    brand: "Honda",
  },
  {
    label: "Lagreat",
    value: "Lagreat",
    brand: "Honda",
  },
  {
    label: "Legend",
    value: "Legend",
    brand: "Honda",
  },
  {
    label: "Logo",
    value: "Logo",
    brand: "Honda",
  },
  {
    label: "NSX",
    value: "NSX",
    brand: "Honda",
  },
  {
    label: "Odyssey",
    value: "Odyssey",
    brand: "Honda",
  },
  {
    label: "Passport",
    value: "Passport",
    brand: "Honda",
  },
  {
    label: "Pilot",
    value: "Pilot",
    brand: "Honda",
  },
  {
    label: "Prelude",
    value: "Prelude",
    brand: "Honda",
  },
  {
    label: "Ridgeline",
    value: "Ridgeline",
    brand: "Honda",
  },
  {
    label: "S2000",
    value: "S2000",
    brand: "Honda",
  },
  {
    label: "Shuttle",
    value: "Shuttle",
    brand: "Honda",
  },
  {
    label: "Stream",
    value: "Stream",
    brand: "Honda",
  },
  {
    label: "Today",
    value: "Today",
    brand: "Honda",
  },
  {
    label: "E-HS9",
    value: "E-HS9",
    brand: "Hongqi",
  },
  {
    label: "E-QM5",
    value: "E-QM5",
    brand: "Hongqi",
  },
  {
    label: "H5",
    value: "H5",
    brand: "Hongqi",
  },
  {
    label: "H7",
    value: "H7",
    brand: "Hongqi",
  },
  {
    label: "H9",
    value: "H9",
    brand: "Hongqi",
  },
  {
    label: "HS7",
    value: "HS7",
    brand: "Hongqi",
  },
  {
    label: "Ousado",
    value: "Ousado",
    brand: "Hongqi",
  },
  {
    label: "Hollywood",
    value: "Hollywood",
    brand: "Hudson",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Hummer",
  },
  {
    label: "H1",
    value: "H1",
    brand: "Hummer",
  },
  {
    label: "H2",
    value: "H2",
    brand: "Hummer",
  },
  {
    label: "H3",
    value: "H3",
    brand: "Hummer",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Hyundai",
  },
  {
    label: "Accent",
    value: "Accent",
    brand: "Hyundai",
  },
  {
    label: "Atos",
    value: "Atos",
    brand: "Hyundai",
  },
  {
    label: "Azera",
    value: "Azera",
    brand: "Hyundai",
  },
  {
    label: "Bayon",
    value: "Bayon",
    brand: "Hyundai",
  },
  {
    label: "Coupe",
    value: "Coupe",
    brand: "Hyundai",
  },
  {
    label: "Elantra",
    value: "Elantra",
    brand: "Hyundai",
  },
  {
    label: "Entourage",
    value: "Entourage",
    brand: "Hyundai",
  },
  {
    label: "Equus",
    value: "Equus",
    brand: "Hyundai",
  },
  {
    label: "Excel",
    value: "Excel",
    brand: "Hyundai",
  },
  {
    label: "Galloper",
    value: "Galloper",
    brand: "Hyundai",
  },
  {
    label: "Genesis",
    value: "Genesis",
    brand: "Hyundai",
  },
  {
    label: "Getz",
    value: "Getz",
    brand: "Hyundai",
  },
  {
    label: "Grand Santa Fe",
    value: "Grand Santa Fe",
    brand: "Hyundai",
  },
  {
    label: "Grandeur",
    value: "Grandeur",
    brand: "Hyundai",
  },
  {
    label: "H1",
    value: "H1",
    brand: "Hyundai",
  },
  {
    label: "H1 Travel",
    value: "H1 Travel",
    brand: "Hyundai",
  },
  {
    label: "H100",
    value: "H100",
    brand: "Hyundai",
  },
  {
    label: "H200",
    value: "H200",
    brand: "Hyundai",
  },
  {
    label: "H350",
    value: "H350",
    brand: "Hyundai",
  },
  {
    label: "i10",
    value: "i10",
    brand: "Hyundai",
  },
  {
    label: "i20",
    value: "i20",
    brand: "Hyundai",
  },
  {
    label: "i20 Active",
    value: "i20 Active",
    brand: "Hyundai",
  },
  {
    label: "i30",
    value: "i30",
    brand: "Hyundai",
  },
  {
    label: "i40",
    value: "i40",
    brand: "Hyundai",
  },
  {
    label: "i50",
    value: "i50",
    brand: "Hyundai",
  },
  {
    label: "IONIQ",
    value: "IONIQ",
    brand: "Hyundai",
  },
  {
    label: "IONIQ 5",
    value: "IONIQ 5",
    brand: "Hyundai",
  },
  {
    label: "IONIQ 6",
    value: "IONIQ 6",
    brand: "Hyundai",
  },
  {
    label: "ix20",
    value: "ix20",
    brand: "Hyundai",
  },
  {
    label: "ix35",
    value: "ix35",
    brand: "Hyundai",
  },
  {
    label: "ix55",
    value: "ix55",
    brand: "Hyundai",
  },
  {
    label: "Kona",
    value: "Kona",
    brand: "Hyundai",
  },
  {
    label: "Lantra",
    value: "Lantra",
    brand: "Hyundai",
  },
  {
    label: "Lavita",
    value: "Lavita",
    brand: "Hyundai",
  },
  {
    label: "Marcia",
    value: "Marcia",
    brand: "Hyundai",
  },
  {
    label: "Matrix",
    value: "Matrix",
    brand: "Hyundai",
  },
  {
    label: "Palisade",
    value: "Palisade",
    brand: "Hyundai",
  },
  {
    label: "Pony",
    value: "Pony",
    brand: "Hyundai",
  },
  {
    label: "S-Coupe",
    value: "S-Coupe",
    brand: "Hyundai",
  },
  {
    label: "Santa Fe",
    value: "Santa Fe",
    brand: "Hyundai",
  },
  {
    label: "Santamo",
    value: "Santamo",
    brand: "Hyundai",
  },
  {
    label: "Solaris",
    value: "Solaris",
    brand: "Hyundai",
  },
  {
    label: "Sonata",
    value: "Sonata",
    brand: "Hyundai",
  },
  {
    label: "Starex",
    value: "Starex",
    brand: "Hyundai",
  },
  {
    label: "Staria",
    value: "Staria",
    brand: "Hyundai",
  },
  {
    label: "Terracan",
    value: "Terracan",
    brand: "Hyundai",
  },
  {
    label: "Tiburon",
    value: "Tiburon",
    brand: "Hyundai",
  },
  {
    label: "Trajet",
    value: "Trajet",
    brand: "Hyundai",
  },
  {
    label: "Tucson",
    value: "Tucson",
    brand: "Hyundai",
  },
  {
    label: "Veloster",
    value: "Veloster",
    brand: "Hyundai",
  },
  {
    label: "Veracruz",
    value: "Veracruz",
    brand: "Hyundai",
  },
  {
    label: "XG",
    value: "XG",
    brand: "Hyundai",
  },
  {
    label: "XG 350",
    value: "XG 350",
    brand: "Hyundai",
  },
  {
    label: "XG30",
    value: "XG30",
    brand: "Hyundai",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Infiniti",
  },
  {
    label: "EX",
    value: "EX",
    brand: "Infiniti",
  },
  {
    label: "EX30",
    value: "EX30",
    brand: "Infiniti",
  },
  {
    label: "EX35",
    value: "EX35",
    brand: "Infiniti",
  },
  {
    label: "EX37",
    value: "EX37",
    brand: "Infiniti",
  },
  {
    label: "FX",
    value: "FX",
    brand: "Infiniti",
  },
  {
    label: "FX30",
    value: "FX30",
    brand: "Infiniti",
  },
  {
    label: "FX35",
    value: "FX35",
    brand: "Infiniti",
  },
  {
    label: "FX37",
    value: "FX37",
    brand: "Infiniti",
  },
  {
    label: "FX45",
    value: "FX45",
    brand: "Infiniti",
  },
  {
    label: "FX50",
    value: "FX50",
    brand: "Infiniti",
  },
  {
    label: "G20",
    value: "G20",
    brand: "Infiniti",
  },
  {
    label: "G35",
    value: "G35",
    brand: "Infiniti",
  },
  {
    label: "G37",
    value: "G37",
    brand: "Infiniti",
  },
  {
    label: "I30",
    value: "I30",
    brand: "Infiniti",
  },
  {
    label: "I35",
    value: "I35",
    brand: "Infiniti",
  },
  {
    label: "J30",
    value: "J30",
    brand: "Infiniti",
  },
  {
    label: "JX35",
    value: "JX35",
    brand: "Infiniti",
  },
  {
    label: "M30",
    value: "M30",
    brand: "Infiniti",
  },
  {
    label: "M35/45",
    value: "M35/45",
    brand: "Infiniti",
  },
  {
    label: "M37",
    value: "M37",
    brand: "Infiniti",
  },
  {
    label: "M56",
    value: "M56",
    brand: "Infiniti",
  },
  {
    label: "Q30",
    value: "Q30",
    brand: "Infiniti",
  },
  {
    label: "Q45",
    value: "Q45",
    brand: "Infiniti",
  },
  {
    label: "Q50",
    value: "Q50",
    brand: "Infiniti",
  },
  {
    label: "Q60",
    value: "Q60",
    brand: "Infiniti",
  },
  {
    label: "Q70",
    value: "Q70",
    brand: "Infiniti",
  },
  {
    label: "QX30",
    value: "QX30",
    brand: "Infiniti",
  },
  {
    label: "QX4",
    value: "QX4",
    brand: "Infiniti",
  },
  {
    label: "QX50",
    value: "QX50",
    brand: "Infiniti",
  },
  {
    label: "QX55",
    value: "QX55",
    brand: "Infiniti",
  },
  {
    label: "QX56",
    value: "QX56",
    brand: "Infiniti",
  },
  {
    label: "QX60",
    value: "QX60",
    brand: "Infiniti",
  },
  {
    label: "QX70",
    value: "QX70",
    brand: "Infiniti",
  },
  {
    label: "QX80",
    value: "QX80",
    brand: "Infiniti",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "International",
  },
  {
    label: "Scout",
    value: "Scout",
    brand: "International",
  },
  {
    label: "Scout II",
    value: "Scout II",
    brand: "International",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Isuzu",
  },
  {
    label: "Amigo",
    value: "Amigo",
    brand: "Isuzu",
  },
  {
    label: "Ascender",
    value: "Ascender",
    brand: "Isuzu",
  },
  {
    label: "Aska",
    value: "Aska",
    brand: "Isuzu",
  },
  {
    label: "Axiom",
    value: "Axiom",
    brand: "Isuzu",
  },
  {
    label: "Bighorn",
    value: "Bighorn",
    brand: "Isuzu",
  },
  {
    label: "Campo",
    value: "Campo",
    brand: "Isuzu",
  },
  {
    label: "D-Max",
    value: "D-Max",
    brand: "Isuzu",
  },
  {
    label: "Gemini",
    value: "Gemini",
    brand: "Isuzu",
  },
  {
    label: "HiLander",
    value: "HiLander",
    brand: "Isuzu",
  },
  {
    label: "Midi",
    value: "Midi",
    brand: "Isuzu",
  },
  {
    label: "MU",
    value: "MU",
    brand: "Isuzu",
  },
  {
    label: "NPR",
    value: "NPR",
    brand: "Isuzu",
  },
  {
    label: "Pick Up",
    value: "Pick Up",
    brand: "Isuzu",
  },
  {
    label: "Rodeo",
    value: "Rodeo",
    brand: "Isuzu",
  },
  {
    label: "Trooper",
    value: "Trooper",
    brand: "Isuzu",
  },
  {
    label: "Turkuaz",
    value: "Turkuaz",
    brand: "Isuzu",
  },
  {
    label: "Vehi Cross",
    value: "Vehi Cross",
    brand: "Isuzu",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Iveco",
  },
  {
    label: "35-12",
    value: "35-12",
    brand: "Iveco",
  },
  {
    label: "Daily",
    value: "Daily",
    brand: "Iveco",
  },
  {
    label: "TurboDaily",
    value: "TurboDaily",
    brand: "Iveco",
  },
  {
    label: "Massif",
    value: "Massif",
    brand: "Iveco",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "JAC",
  },
  {
    label: "iEV7S",
    value: "iEV7S",
    brand: "JAC",
  },
  {
    label: "J6",
    value: "J6",
    brand: "JAC",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Jaguar",
  },
  {
    label: "Daimler",
    value: "Daimler",
    brand: "Jaguar",
  },
  {
    label: "E-Pace",
    value: "E-Pace",
    brand: "Jaguar",
  },
  {
    label: "E-Type",
    value: "E-Type",
    brand: "Jaguar",
  },
  {
    label: "F-Pace",
    value: "F-Pace",
    brand: "Jaguar",
  },
  {
    label: "F-Type",
    value: "F-Type",
    brand: "Jaguar",
  },
  {
    label: "I-Pace",
    value: "I-Pace",
    brand: "Jaguar",
  },
  {
    label: "MK II",
    value: "MK II",
    brand: "Jaguar",
  },
  {
    label: "S-Type",
    value: "S-Type",
    brand: "Jaguar",
  },
  {
    label: "Sovereign",
    value: "Sovereign",
    brand: "Jaguar",
  },
  {
    label: "Vanden Plas",
    value: "Vanden Plas",
    brand: "Jaguar",
  },
  {
    label: "X-Type",
    value: "X-Type",
    brand: "Jaguar",
  },
  {
    label: "XE",
    value: "XE",
    brand: "Jaguar",
  },
  {
    label: "XF",
    value: "XF",
    brand: "Jaguar",
  },
  {
    label: "XJ",
    value: "XJ",
    brand: "Jaguar",
  },
  {
    label: "XJ-Series",
    value: "XJ-Series",
    brand: "Jaguar",
  },
  {
    label: "XJ12",
    value: "XJ12",
    brand: "Jaguar",
  },
  {
    label: "XJ40",
    value: "XJ40",
    brand: "Jaguar",
  },
  {
    label: "XJ6",
    value: "XJ6",
    brand: "Jaguar",
  },
  {
    label: "XJ8",
    value: "XJ8",
    brand: "Jaguar",
  },
  {
    label: "XJR",
    value: "XJR",
    brand: "Jaguar",
  },
  {
    label: "XJS",
    value: "XJS",
    brand: "Jaguar",
  },
  {
    label: "XK",
    value: "XK",
    brand: "Jaguar",
  },
  {
    label: "XK-Series",
    value: "XK-Series",
    brand: "Jaguar",
  },
  {
    label: "XK8",
    value: "XK8",
    brand: "Jaguar",
  },
  {
    label: "XKR",
    value: "XKR",
    brand: "Jaguar",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Jeep",
  },
  {
    label: "Avenger",
    value: "Avenger",
    brand: "Jeep",
  },
  {
    label: "Cherokee",
    value: "Cherokee",
    brand: "Jeep",
  },
  {
    label: "CJ",
    value: "CJ",
    brand: "Jeep",
  },
  {
    label: "Comanche",
    value: "Comanche",
    brand: "Jeep",
  },
  {
    label: "Commander",
    value: "Commander",
    brand: "Jeep",
  },
  {
    label: "Compass",
    value: "Compass",
    brand: "Jeep",
  },
  {
    label: "Gladiator",
    value: "Gladiator",
    brand: "Jeep",
  },
  {
    label: "Grand Cherokee",
    value: "Grand Cherokee",
    brand: "Jeep",
  },
  {
    label: "Grand Wagoneer",
    value: "Grand Wagoneer",
    brand: "Jeep",
  },
  {
    label: "Liberty",
    value: "Liberty",
    brand: "Jeep",
  },
  {
    label: "Patriot",
    value: "Patriot",
    brand: "Jeep",
  },
  {
    label: "Renegade",
    value: "Renegade",
    brand: "Jeep",
  },
  {
    label: "Wagoneer",
    value: "Wagoneer",
    brand: "Jeep",
  },
  {
    label: "Willys",
    value: "Willys",
    brand: "Jeep",
  },
  {
    label: "Wrangler",
    value: "Wrangler",
    brand: "Jeep",
  },
  {
    label: "UFO",
    value: "UFO",
    brand: "Jonway",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Kia",
  },
  {
    label: "Avella",
    value: "Avella",
    brand: "Kia",
  },
  {
    label: "Besta",
    value: "Besta",
    brand: "Kia",
  },
  {
    label: "Borrego",
    value: "Borrego",
    brand: "Kia",
  },
  {
    label: "Cadenza",
    value: "Cadenza",
    brand: "Kia",
  },
  {
    label: "Carens",
    value: "Carens",
    brand: "Kia",
  },
  {
    label: "Carnival",
    value: "Carnival",
    brand: "Kia",
  },
  {
    label: "cee'd",
    value: "cee'd",
    brand: "Kia",
  },
  {
    label: "Ceed Plus",
    value: "Ceed Plus",
    brand: "Kia",
  },
  {
    label: "Cerato",
    value: "Cerato",
    brand: "Kia",
  },
  {
    label: "Clarus",
    value: "Clarus",
    brand: "Kia",
  },
  {
    label: "Elan",
    value: "Elan",
    brand: "Kia",
  },
  {
    label: "EV6",
    value: "EV6",
    brand: "Kia",
  },
  {
    label: "Forte",
    value: "Forte",
    brand: "Kia",
  },
  {
    label: "Joice",
    value: "Joice",
    brand: "Kia",
  },
  {
    label: "K2500",
    value: "K2500",
    brand: "Kia",
  },
  {
    label: "K2700",
    value: "K2700",
    brand: "Kia",
  },
  {
    label: "K5",
    value: "K5",
    brand: "Kia",
  },
  {
    label: "Leo",
    value: "Leo",
    brand: "Kia",
  },
  {
    label: "Magentis",
    value: "Magentis",
    brand: "Kia",
  },
  {
    label: "Mentor",
    value: "Mentor",
    brand: "Kia",
  },
  {
    label: "Niro",
    value: "Niro",
    brand: "Kia",
  },
  {
    label: "Opirus",
    value: "Opirus",
    brand: "Kia",
  },
  {
    label: "Optima",
    value: "Optima",
    brand: "Kia",
  },
  {
    label: "Picanto",
    value: "Picanto",
    brand: "Kia",
  },
  {
    label: "Pregio",
    value: "Pregio",
    brand: "Kia",
  },
  {
    label: "Pride",
    value: "Pride",
    brand: "Kia",
  },
  {
    label: "Pro cee'd",
    value: "Pro cee'd",
    brand: "Kia",
  },
  {
    label: "Quoris",
    value: "Quoris",
    brand: "Kia",
  },
  {
    label: "Retona",
    value: "Retona",
    brand: "Kia",
  },
  {
    label: "Rio",
    value: "Rio",
    brand: "Kia",
  },
  {
    label: "Roadster",
    value: "Roadster",
    brand: "Kia",
  },
  {
    label: "Rocsta",
    value: "Rocsta",
    brand: "Kia",
  },
  {
    label: "Rondo",
    value: "Rondo",
    brand: "Kia",
  },
  {
    label: "Sedona",
    value: "Sedona",
    brand: "Kia",
  },
  {
    label: "Sephia",
    value: "Sephia",
    brand: "Kia",
  },
  {
    label: "Shuma",
    value: "Shuma",
    brand: "Kia",
  },
  {
    label: "Sorento",
    value: "Sorento",
    brand: "Kia",
  },
  {
    label: "Soul",
    value: "Soul",
    brand: "Kia",
  },
  {
    label: "Spectra",
    value: "Spectra",
    brand: "Kia",
  },
  {
    label: "Sportage",
    value: "Sportage",
    brand: "Kia",
  },
  {
    label: "Stinger",
    value: "Stinger",
    brand: "Kia",
  },
  {
    label: "Stonic",
    value: "Stonic",
    brand: "Kia",
  },
  {
    label: "Telluride",
    value: "Telluride",
    brand: "Kia",
  },
  {
    label: "Venga",
    value: "Venga",
    brand: "Kia",
  },
  {
    label: "XCeed",
    value: "XCeed",
    brand: "Kia",
  },
  {
    label: "X-BOW",
    value: "X-BOW",
    brand: "KTM",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Lada",
  },
  {
    label: "110",
    value: "110",
    brand: "Lada",
  },
  {
    label: "111",
    value: "111",
    brand: "Lada",
  },
  {
    label: "112",
    value: "112",
    brand: "Lada",
  },
  {
    label: "1200",
    value: "1200",
    brand: "Lada",
  },
  {
    label: "2101",
    value: "2101",
    brand: "Lada",
  },
  {
    label: "21011",
    value: "21011",
    brand: "Lada",
  },
  {
    label: "21013",
    value: "21013",
    brand: "Lada",
  },
  {
    label: "2102",
    value: "2102",
    brand: "Lada",
  },
  {
    label: "2103",
    value: "2103",
    brand: "Lada",
  },
  {
    label: "2104",
    value: "2104",
    brand: "Lada",
  },
  {
    label: "2105",
    value: "2105",
    brand: "Lada",
  },
  {
    label: "21051",
    value: "21051",
    brand: "Lada",
  },
  {
    label: "21053",
    value: "21053",
    brand: "Lada",
  },
  {
    label: "2106",
    value: "2106",
    brand: "Lada",
  },
  {
    label: "21061",
    value: "21061",
    brand: "Lada",
  },
  {
    label: "21063",
    value: "21063",
    brand: "Lada",
  },
  {
    label: "2107",
    value: "2107",
    brand: "Lada",
  },
  {
    label: "2108",
    value: "2108",
    brand: "Lada",
  },
  {
    label: "2109",
    value: "2109",
    brand: "Lada",
  },
  {
    label: "2110",
    value: "2110",
    brand: "Lada",
  },
  {
    label: "2111",
    value: "2111",
    brand: "Lada",
  },
  {
    label: "21110",
    value: "21110",
    brand: "Lada",
  },
  {
    label: "21111",
    value: "21111",
    brand: "Lada",
  },
  {
    label: "2112",
    value: "2112",
    brand: "Lada",
  },
  {
    label: "2115",
    value: "2115",
    brand: "Lada",
  },
  {
    label: "2120",
    value: "2120",
    brand: "Lada",
  },
  {
    label: "2121",
    value: "2121",
    brand: "Lada",
  },
  {
    label: "21213",
    value: "21213",
    brand: "Lada",
  },
  {
    label: "21214",
    value: "21214",
    brand: "Lada",
  },
  {
    label: "21215",
    value: "21215",
    brand: "Lada",
  },
  {
    label: "2123",
    value: "2123",
    brand: "Lada",
  },
  {
    label: "2129",
    value: "2129",
    brand: "Lada",
  },
  {
    label: "2131",
    value: "2131",
    brand: "Lada",
  },
  {
    label: "2141",
    value: "2141",
    brand: "Lada",
  },
  {
    label: "2329",
    value: "2329",
    brand: "Lada",
  },
  {
    label: "Kalina",
    value: "Kalina",
    brand: "Lada",
  },
  {
    label: "Niva",
    value: "Niva",
    brand: "Lada",
  },
  {
    label: "Nova",
    value: "Nova",
    brand: "Lada",
  },
  {
    label: "Oka",
    value: "Oka",
    brand: "Lada",
  },
  {
    label: "Priora",
    value: "Priora",
    brand: "Lada",
  },
  {
    label: "Samara",
    value: "Samara",
    brand: "Lada",
  },
  {
    label: "Vesta",
    value: "Vesta",
    brand: "Lada",
  },
  {
    label: "Vis",
    value: "Vis",
    brand: "Lada",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Lancia",
  },
  {
    label: "Beta",
    value: "Beta",
    brand: "Lancia",
  },
  {
    label: "Dedra",
    value: "Dedra",
    brand: "Lancia",
  },
  {
    label: "Delta",
    value: "Delta",
    brand: "Lancia",
  },
  {
    label: "Flaminia",
    value: "Flaminia",
    brand: "Lancia",
  },
  {
    label: "Fulvia",
    value: "Fulvia",
    brand: "Lancia",
  },
  {
    label: "Gamma",
    value: "Gamma",
    brand: "Lancia",
  },
  {
    label: "Kappa",
    value: "Kappa",
    brand: "Lancia",
  },
  {
    label: "Lybra",
    value: "Lybra",
    brand: "Lancia",
  },
  {
    label: "Musa",
    value: "Musa",
    brand: "Lancia",
  },
  {
    label: "Phedra",
    value: "Phedra",
    brand: "Lancia",
  },
  {
    label: "Prisma",
    value: "Prisma",
    brand: "Lancia",
  },
  {
    label: "Stratos",
    value: "Stratos",
    brand: "Lancia",
  },
  {
    label: "Thema",
    value: "Thema",
    brand: "Lancia",
  },
  {
    label: "Thesis",
    value: "Thesis",
    brand: "Lancia",
  },
  {
    label: "Voyager",
    value: "Voyager",
    brand: "Lancia",
  },
  {
    label: "Y",
    value: "Y",
    brand: "Lancia",
  },
  {
    label: "Ypsilon",
    value: "Ypsilon",
    brand: "Lancia",
  },
  {
    label: "Zeta",
    value: "Zeta",
    brand: "Lancia",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Land Rover",
  },
  {
    label: "Defender",
    value: "Defender",
    brand: "Land Rover",
  },
  {
    label: "Discovery",
    value: "Discovery",
    brand: "Land Rover",
  },
  {
    label: "Discovery Sport",
    value: "Discovery Sport",
    brand: "Land Rover",
  },
  {
    label: "Evoque",
    value: "Evoque",
    brand: "Land Rover",
  },
  {
    label: "Freelander",
    value: "Freelander",
    brand: "Land Rover",
  },
  {
    label: "Range Rover",
    value: "Range Rover",
    brand: "Land Rover",
  },
  {
    label: "Range Rover Sport",
    value: "Range Rover Sport",
    brand: "Land Rover",
  },
  {
    label: "Range Rover Velar",
    value: "Range Rover Velar",
    brand: "Land Rover",
  },
  {
    label: "CV9",
    value: "CV9",
    brand: "Landwind",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Landwind",
  },
  {
    label: "S",
    value: "S",
    brand: "Landwind",
  },
  {
    label: "SC 2",
    value: "SC 2",
    brand: "Landwind",
  },
  {
    label: "SC 4",
    value: "SC 4",
    brand: "Landwind",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Lexus",
  },
  {
    label: "CT 200h",
    value: "CT 200h",
    brand: "Lexus",
  },
  {
    label: "ES 300",
    value: "ES 300",
    brand: "Lexus",
  },
  {
    label: "ES 300h",
    value: "ES 300h",
    brand: "Lexus",
  },
  {
    label: "ES 330",
    value: "ES 330",
    brand: "Lexus",
  },
  {
    label: "ES 350",
    value: "ES 350",
    brand: "Lexus",
  },
  {
    label: "ES 350h",
    value: "ES 350h",
    brand: "Lexus",
  },
  {
    label: "GS 200t",
    value: "GS 200t",
    brand: "Lexus",
  },
  {
    label: "GS 250",
    value: "GS 250",
    brand: "Lexus",
  },
  {
    label: "GS 300",
    value: "GS 300",
    brand: "Lexus",
  },
  {
    label: "GS 300h",
    value: "GS 300h",
    brand: "Lexus",
  },
  {
    label: "GS 350",
    value: "GS 350",
    brand: "Lexus",
  },
  {
    label: "GS 400",
    value: "GS 400",
    brand: "Lexus",
  },
  {
    label: "GS 430",
    value: "GS 430",
    brand: "Lexus",
  },
  {
    label: "GS 450",
    value: "GS 450",
    brand: "Lexus",
  },
  {
    label: "GS 450h",
    value: "GS 450h",
    brand: "Lexus",
  },
  {
    label: "GS 460",
    value: "GS 460",
    brand: "Lexus",
  },
  {
    label: "GS-F",
    value: "GS-F",
    brand: "Lexus",
  },
  {
    label: "GX 460",
    value: "GX 460",
    brand: "Lexus",
  },
  {
    label: "GX 470",
    value: "GX 470",
    brand: "Lexus",
  },
  {
    label: "GX 640",
    value: "GX 640",
    brand: "Lexus",
  },
  {
    label: "HS 250",
    value: "HS 250",
    brand: "Lexus",
  },
  {
    label: "IS 200",
    value: "IS 200",
    brand: "Lexus",
  },
  {
    label: "IS 300h",
    value: "IS 300h",
    brand: "Lexus",
  },
  {
    label: "IS 220",
    value: "IS 220",
    brand: "Lexus",
  },
  {
    label: "IS 250",
    value: "IS 250",
    brand: "Lexus",
  },
  {
    label: "IS 300",
    value: "IS 300",
    brand: "Lexus",
  },
  {
    label: "IS 350",
    value: "IS 350",
    brand: "Lexus",
  },
  {
    label: "IS-F",
    value: "IS-F",
    brand: "Lexus",
  },
  {
    label: "LC 500",
    value: "LC 500",
    brand: "Lexus",
  },
  {
    label: "LC 500h",
    value: "LC 500h",
    brand: "Lexus",
  },
  {
    label: "LFA",
    value: "LFA",
    brand: "Lexus",
  },
  {
    label: "LS 400",
    value: "LS 400",
    brand: "Lexus",
  },
  {
    label: "LS 430",
    value: "LS 430",
    brand: "Lexus",
  },
  {
    label: "LS 460",
    value: "LS 460",
    brand: "Lexus",
  },
  {
    label: "LS 500",
    value: "LS 500",
    brand: "Lexus",
  },
  {
    label: "LS 500 h",
    value: "LS 500 h",
    brand: "Lexus",
  },
  {
    label: "LS 600 h",
    value: "LS 600 h",
    brand: "Lexus",
  },
  {
    label: "LX 450",
    value: "LX 450",
    brand: "Lexus",
  },
  {
    label: "LX 470",
    value: "LX 470",
    brand: "Lexus",
  },
  {
    label: "LX 570",
    value: "LX 570",
    brand: "Lexus",
  },
  {
    label: "NX 200t",
    value: "NX 200t",
    brand: "Lexus",
  },
  {
    label: "NX 300",
    value: "NX 300",
    brand: "Lexus",
  },
  {
    label: "NX 300h",
    value: "NX 300h",
    brand: "Lexus",
  },
  {
    label: "NX 350h",
    value: "NX 350h",
    brand: "Lexus",
  },
  {
    label: "NX 450h+",
    value: "NX 450h+",
    brand: "Lexus",
  },
  {
    label: "RC 200t",
    value: "RC 200t",
    brand: "Lexus",
  },
  {
    label: "RC 300",
    value: "RC 300",
    brand: "Lexus",
  },
  {
    label: "RC 300h",
    value: "RC 300h",
    brand: "Lexus",
  },
  {
    label: "RC 350",
    value: "RC 350",
    brand: "Lexus",
  },
  {
    label: "RC-F",
    value: "RC-F",
    brand: "Lexus",
  },
  {
    label: "RX 200t",
    value: "RX 200t",
    brand: "Lexus",
  },
  {
    label: "RX 300",
    value: "RX 300",
    brand: "Lexus",
  },
  {
    label: "RX 330",
    value: "RX 330",
    brand: "Lexus",
  },
  {
    label: "RX 350",
    value: "RX 350",
    brand: "Lexus",
  },
  {
    label: "RX 400h",
    value: "RX 400h",
    brand: "Lexus",
  },
  {
    label: "RX 450h",
    value: "RX 450h",
    brand: "Lexus",
  },
  {
    label: "RX 450h+",
    value: "RX 450h+",
    brand: "Lexus",
  },
  {
    label: "RX 500h",
    value: "RX 500h",
    brand: "Lexus",
  },
  {
    label: "RZ 450e",
    value: "RZ 450e",
    brand: "Lexus",
  },
  {
    label: "SC 400",
    value: "SC 400",
    brand: "Lexus",
  },
  {
    label: "SC 300",
    value: "SC 300",
    brand: "Lexus",
  },
  {
    label: "SC 430",
    value: "SC 430",
    brand: "Lexus",
  },
  {
    label: "SL",
    value: "SL",
    brand: "Lexus",
  },
  {
    label: "UX 200",
    value: "UX 200",
    brand: "Lexus",
  },
  {
    label: "UX 250h",
    value: "UX 250h",
    brand: "Lexus",
  },
  {
    label: "UX 300e",
    value: "UX 300e",
    brand: "Lexus",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Ligier",
  },
  {
    label: "Ambra",
    value: "Ambra",
    brand: "Ligier",
  },
  {
    label: "Andere",
    value: "Andere",
    brand: "Ligier",
  },
  {
    label: "ixo",
    value: "ixo",
    brand: "Ligier",
  },
  {
    label: "JS 50",
    value: "JS 50",
    brand: "Ligier",
  },
  {
    label: "Nova",
    value: "Nova",
    brand: "Ligier",
  },
  {
    label: "Optima",
    value: "Optima",
    brand: "Ligier",
  },
  {
    label: "X - Too",
    value: "X - Too",
    brand: "Ligier",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Lincoln",
  },
  {
    label: "Aviator",
    value: "Aviator",
    brand: "Lincoln",
  },
  {
    label: "Continental",
    value: "Continental",
    brand: "Lincoln",
  },
  {
    label: "Corsair",
    value: "Corsair",
    brand: "Lincoln",
  },
  {
    label: "LS",
    value: "LS",
    brand: "Lincoln",
  },
  {
    label: "Mark",
    value: "Mark",
    brand: "Lincoln",
  },
  {
    label: "MKC",
    value: "MKC",
    brand: "Lincoln",
  },
  {
    label: "MKS",
    value: "MKS",
    brand: "Lincoln",
  },
  {
    label: "MKT",
    value: "MKT",
    brand: "Lincoln",
  },
  {
    label: "MKX",
    value: "MKX",
    brand: "Lincoln",
  },
  {
    label: "MKZ",
    value: "MKZ",
    brand: "Lincoln",
  },
  {
    label: "Nautilus",
    value: "Nautilus",
    brand: "Lincoln",
  },
  {
    label: "Navigator",
    value: "Navigator",
    brand: "Lincoln",
  },
  {
    label: "Town Car",
    value: "Town Car",
    brand: "Lincoln",
  },
  {
    label: "Zephyr",
    value: "Zephyr",
    brand: "Lincoln",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Lotus",
  },
  {
    label: "Cortina",
    value: "Cortina",
    brand: "Lotus",
  },
  {
    label: "Eagle",
    value: "Eagle",
    brand: "Lotus",
  },
  {
    label: "Elan",
    value: "Elan",
    brand: "Lotus",
  },
  {
    label: "Elise",
    value: "Elise",
    brand: "Lotus",
  },
  {
    label: "Elite",
    value: "Elite",
    brand: "Lotus",
  },
  {
    label: "Esprit",
    value: "Esprit",
    brand: "Lotus",
  },
  {
    label: "Europa",
    value: "Europa",
    brand: "Lotus",
  },
  {
    label: "Evora",
    value: "Evora",
    brand: "Lotus",
  },
  {
    label: "Exige",
    value: "Exige",
    brand: "Lotus",
  },
  {
    label: "Super Seven",
    value: "Super Seven",
    brand: "Lotus",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "LuAZ",
  },
  {
    label: "1302",
    value: "1302",
    brand: "LuAZ",
  },
  {
    label: "967M",
    value: "967M",
    brand: "LuAZ",
  },
  {
    label: "969M",
    value: "969M",
    brand: "LuAZ",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mahindra",
  },
  {
    label: "X 500",
    value: "X 500",
    brand: "Mahindra",
  },
  {
    label: "TGE",
    value: "TGE",
    brand: "MAN",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Maserati",
  },
  {
    label: "222",
    value: "222",
    brand: "Maserati",
  },
  {
    label: "224",
    value: "224",
    brand: "Maserati",
  },
  {
    label: "228",
    value: "228",
    brand: "Maserati",
  },
  {
    label: "3200",
    value: "3200",
    brand: "Maserati",
  },
  {
    label: "3200 GT",
    value: "3200 GT",
    brand: "Maserati",
  },
  {
    label: "418",
    value: "418",
    brand: "Maserati",
  },
  {
    label: "420",
    value: "420",
    brand: "Maserati",
  },
  {
    label: "4200",
    value: "4200",
    brand: "Maserati",
  },
  {
    label: "422",
    value: "422",
    brand: "Maserati",
  },
  {
    label: "424",
    value: "424",
    brand: "Maserati",
  },
  {
    label: "430",
    value: "430",
    brand: "Maserati",
  },
  {
    label: "Biturbo",
    value: "Biturbo",
    brand: "Maserati",
  },
  {
    label: "Ghibli",
    value: "Ghibli",
    brand: "Maserati",
  },
  {
    label: "GranCabrio",
    value: "GranCabrio",
    brand: "Maserati",
  },
  {
    label: "Gransport",
    value: "Gransport",
    brand: "Maserati",
  },
  {
    label: "GranTurismo",
    value: "GranTurismo",
    brand: "Maserati",
  },
  {
    label: "Grecale",
    value: "Grecale",
    brand: "Maserati",
  },
  {
    label: "Levante",
    value: "Levante",
    brand: "Maserati",
  },
  {
    label: "MC20",
    value: "MC20",
    brand: "Maserati",
  },
  {
    label: "Quattroporte",
    value: "Quattroporte",
    brand: "Maserati",
  },
  {
    label: "Spyder",
    value: "Spyder",
    brand: "Maserati",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Maybach",
  },
  {
    label: "57",
    value: "57",
    brand: "Maybach",
  },
  {
    label: "62",
    value: "62",
    brand: "Maybach",
  },
  {
    label: "GLS 600",
    value: "GLS 600",
    brand: "Maybach",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mazda",
  },
  {
    label: "121",
    value: "121",
    brand: "Mazda",
  },
  {
    label: "2",
    value: "2",
    brand: "Mazda",
  },
  {
    label: "3",
    value: "3",
    brand: "Mazda",
  },
  {
    label: "323",
    value: "323",
    brand: "Mazda",
  },
  {
    label: "323F",
    value: "323F",
    brand: "Mazda",
  },
  {
    label: "5",
    value: "5",
    brand: "Mazda",
  },
  {
    label: "6",
    value: "6",
    brand: "Mazda",
  },
  {
    label: "626",
    value: "626",
    brand: "Mazda",
  },
  {
    label: "929",
    value: "929",
    brand: "Mazda",
  },
  {
    label: "B Series",
    value: "B Series",
    brand: "Mazda",
  },
  {
    label: "Bongo",
    value: "Bongo",
    brand: "Mazda",
  },
  {
    label: "BT-50",
    value: "BT-50",
    brand: "Mazda",
  },
  {
    label: "Cronos",
    value: "Cronos",
    brand: "Mazda",
  },
  {
    label: "CX-3",
    value: "CX-3",
    brand: "Mazda",
  },
  {
    label: "CX-30",
    value: "CX-30",
    brand: "Mazda",
  },
  {
    label: "CX-5",
    value: "CX-5",
    brand: "Mazda",
  },
  {
    label: "CX-60",
    value: "CX-60",
    brand: "Mazda",
  },
  {
    label: "CX-7",
    value: "CX-7",
    brand: "Mazda",
  },
  {
    label: "CX-9",
    value: "CX-9",
    brand: "Mazda",
  },
  {
    label: "Demio",
    value: "Demio",
    brand: "Mazda",
  },
  {
    label: "Eunos",
    value: "Eunos",
    brand: "Mazda",
  },
  {
    label: "Lantis",
    value: "Lantis",
    brand: "Mazda",
  },
  {
    label: "Miata",
    value: "Miata",
    brand: "Mazda",
  },
  {
    label: "Millenia",
    value: "Millenia",
    brand: "Mazda",
  },
  {
    label: "MPV",
    value: "MPV",
    brand: "Mazda",
  },
  {
    label: "MX-3",
    value: "MX-3",
    brand: "Mazda",
  },
  {
    label: "MX-30",
    value: "MX-30",
    brand: "Mazda",
  },
  {
    label: "MX-5",
    value: "MX-5",
    brand: "Mazda",
  },
  {
    label: "MX-6",
    value: "MX-6",
    brand: "Mazda",
  },
  {
    label: "PickUp",
    value: "PickUp",
    brand: "Mazda",
  },
  {
    label: "Premacy",
    value: "Premacy",
    brand: "Mazda",
  },
  {
    label: "Protege",
    value: "Protege",
    brand: "Mazda",
  },
  {
    label: "Rustler",
    value: "Rustler",
    brand: "Mazda",
  },
  {
    label: "RX-6",
    value: "RX-6",
    brand: "Mazda",
  },
  {
    label: "RX-7",
    value: "RX-7",
    brand: "Mazda",
  },
  {
    label: "RX-8",
    value: "RX-8",
    brand: "Mazda",
  },
  {
    label: "Tribute",
    value: "Tribute",
    brand: "Mazda",
  },
  {
    label: "Xedos 6",
    value: "Xedos 6",
    brand: "Mazda",
  },
  {
    label: "Xedos 9",
    value: "Xedos 9",
    brand: "Mazda",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mercedes-Benz",
  },
  {
    label: "100",
    value: "100",
    brand: "Mercedes-Benz",
  },
  {
    label: "108",
    value: "108",
    brand: "Mercedes-Benz",
  },
  {
    label: "110",
    value: "110",
    brand: "Mercedes-Benz",
  },
  {
    label: "123",
    value: "123",
    brand: "Mercedes-Benz",
  },
  {
    label: "124",
    value: "124",
    brand: "Mercedes-Benz",
  },
  {
    label: "190",
    value: "190",
    brand: "Mercedes-Benz",
  },
  {
    label: "200",
    value: "200",
    brand: "Mercedes-Benz",
  },
  {
    label: "207",
    value: "207",
    brand: "Mercedes-Benz",
  },
  {
    label: "208",
    value: "208",
    brand: "Mercedes-Benz",
  },
  {
    label: "211",
    value: "211",
    brand: "Mercedes-Benz",
  },
  {
    label: "212",
    value: "212",
    brand: "Mercedes-Benz",
  },
  {
    label: "220",
    value: "220",
    brand: "Mercedes-Benz",
  },
  {
    label: "230",
    value: "230",
    brand: "Mercedes-Benz",
  },
  {
    label: "240",
    value: "240",
    brand: "Mercedes-Benz",
  },
  {
    label: "250",
    value: "250",
    brand: "Mercedes-Benz",
  },
  {
    label: "260",
    value: "260",
    brand: "Mercedes-Benz",
  },
  {
    label: "270",
    value: "270",
    brand: "Mercedes-Benz",
  },
  {
    label: "280",
    value: "280",
    brand: "Mercedes-Benz",
  },
  {
    label: "290",
    value: "290",
    brand: "Mercedes-Benz",
  },
  {
    label: "300",
    value: "300",
    brand: "Mercedes-Benz",
  },
  {
    label: "300 GD",
    value: "300 GD",
    brand: "Mercedes-Benz",
  },
  {
    label: "307",
    value: "307",
    brand: "Mercedes-Benz",
  },
  {
    label: "308",
    value: "308",
    brand: "Mercedes-Benz",
  },
  {
    label: "320",
    value: "320",
    brand: "Mercedes-Benz",
  },
  {
    label: "350",
    value: "350",
    brand: "Mercedes-Benz",
  },
  {
    label: "380",
    value: "380",
    brand: "Mercedes-Benz",
  },
  {
    label: "400",
    value: "400",
    brand: "Mercedes-Benz",
  },
  {
    label: "416",
    value: "416",
    brand: "Mercedes-Benz",
  },
  {
    label: "420",
    value: "420",
    brand: "Mercedes-Benz",
  },
  {
    label: "450",
    value: "450",
    brand: "Mercedes-Benz",
  },
  {
    label: "500",
    value: "500",
    brand: "Mercedes-Benz",
  },
  {
    label: "560",
    value: "560",
    brand: "Mercedes-Benz",
  },
  {
    label: "600",
    value: "600",
    brand: "Mercedes-Benz",
  },
  {
    label: "608",
    value: "608",
    brand: "Mercedes-Benz",
  },
  {
    label: "A140",
    value: "A140",
    brand: "Mercedes-Benz",
  },
  {
    label: "A150",
    value: "A150",
    brand: "Mercedes-Benz",
  },
  {
    label: "A160",
    value: "A160",
    brand: "Mercedes-Benz",
  },
  {
    label: "A170",
    value: "A170",
    brand: "Mercedes-Benz",
  },
  {
    label: "A180",
    value: "A180",
    brand: "Mercedes-Benz",
  },
  {
    label: "A190",
    value: "A190",
    brand: "Mercedes-Benz",
  },
  {
    label: "A200",
    value: "A200",
    brand: "Mercedes-Benz",
  },
  {
    label: "A210",
    value: "A210",
    brand: "Mercedes-Benz",
  },
  {
    label: "A220",
    value: "A220",
    brand: "Mercedes-Benz",
  },
  {
    label: "A250",
    value: "A250",
    brand: "Mercedes-Benz",
  },
  {
    label: "A35 AMG",
    value: "A35 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "A45 AMG",
    value: "A45 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "AMG GT",
    value: "AMG GT",
    brand: "Mercedes-Benz",
  },
  {
    label: "AMG GT C",
    value: "AMG GT C",
    brand: "Mercedes-Benz",
  },
  {
    label: "AMG GT R",
    value: "AMG GT R",
    brand: "Mercedes-Benz",
  },
  {
    label: "AMG GT S",
    value: "AMG GT S",
    brand: "Mercedes-Benz",
  },
  {
    label: "AMG SL43",
    value: "AMG SL43",
    brand: "Mercedes-Benz",
  },
  {
    label: "Atego",
    value: "Atego",
    brand: "Mercedes-Benz",
  },
  {
    label: "B Electric Drive",
    value: "B Electric Drive",
    brand: "Mercedes-Benz",
  },
  {
    label: "B150",
    value: "B150",
    brand: "Mercedes-Benz",
  },
  {
    label: "B160",
    value: "B160",
    brand: "Mercedes-Benz",
  },
  {
    label: "B170",
    value: "B170",
    brand: "Mercedes-Benz",
  },
  {
    label: "B180",
    value: "B180",
    brand: "Mercedes-Benz",
  },
  {
    label: "B200",
    value: "B200",
    brand: "Mercedes-Benz",
  },
  {
    label: "B220",
    value: "B220",
    brand: "Mercedes-Benz",
  },
  {
    label: "B250",
    value: "B250",
    brand: "Mercedes-Benz",
  },
  {
    label: "C160",
    value: "C160",
    brand: "Mercedes-Benz",
  },
  {
    label: "C180",
    value: "C180",
    brand: "Mercedes-Benz",
  },
  {
    label: "C200",
    value: "C200",
    brand: "Mercedes-Benz",
  },
  {
    label: "C220",
    value: "C220",
    brand: "Mercedes-Benz",
  },
  {
    label: "C230",
    value: "C230",
    brand: "Mercedes-Benz",
  },
  {
    label: "C240",
    value: "C240",
    brand: "Mercedes-Benz",
  },
  {
    label: "C250",
    value: "C250",
    brand: "Mercedes-Benz",
  },
  {
    label: "C270",
    value: "C270",
    brand: "Mercedes-Benz",
  },
  {
    label: "C280",
    value: "C280",
    brand: "Mercedes-Benz",
  },
  {
    label: "C30 AMG",
    value: "C30 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C300",
    value: "C300",
    brand: "Mercedes-Benz",
  },
  {
    label: "C32 AMG",
    value: "C32 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C320",
    value: "C320",
    brand: "Mercedes-Benz",
  },
  {
    label: "C350",
    value: "C350",
    brand: "Mercedes-Benz",
  },
  {
    label: "C36 AMG",
    value: "C36 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C400",
    value: "C400",
    brand: "Mercedes-Benz",
  },
  {
    label: "C43 AMG",
    value: "C43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C450",
    value: "C450",
    brand: "Mercedes-Benz",
  },
  {
    label: "C55 AMG",
    value: "C55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C63 AMG",
    value: "C63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "C klasė SC",
    value: "C klasė SC",
    brand: "Mercedes-Benz",
  },
  {
    label: "CE200",
    value: "CE200",
    brand: "Mercedes-Benz",
  },
  {
    label: "CE220",
    value: "CE220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CE230",
    value: "CE230",
    brand: "Mercedes-Benz",
  },
  {
    label: "CE300",
    value: "CE300",
    brand: "Mercedes-Benz",
  },
  {
    label: "Citan",
    value: "Citan",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL180",
    value: "CL180",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL200",
    value: "CL200",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL220",
    value: "CL220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL230",
    value: "CL230",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL420",
    value: "CL420",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL500",
    value: "CL500",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL55 AMG",
    value: "CL55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL550",
    value: "CL550",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL600",
    value: "CL600",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL63 AMG",
    value: "CL63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CL65 AMG",
    value: "CL65 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA180",
    value: "CLA180",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA200",
    value: "CLA200",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA220",
    value: "CLA220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA250",
    value: "CLA250",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA35 AMG",
    value: "CLA35 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLA45 AMG",
    value: "CLA45 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC160",
    value: "CLC160",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC180",
    value: "CLC180",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC200",
    value: "CLC200",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC220",
    value: "CLC220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC230",
    value: "CLC230",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC250",
    value: "CLC250",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLC350",
    value: "CLC350",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK200",
    value: "CLK200",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK220",
    value: "CLK220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK230",
    value: "CLK230",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK240",
    value: "CLK240",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK270",
    value: "CLK270",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK280",
    value: "CLK280",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK320",
    value: "CLK320",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK350",
    value: "CLK350",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK430",
    value: "CLK430",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK500",
    value: "CLK500",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK55 AMG",
    value: "CLK55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK550",
    value: "CLK550",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLK63 AMG",
    value: "CLK63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS220",
    value: "CLS220",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS250",
    value: "CLS250",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS280",
    value: "CLS280",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS300",
    value: "CLS300",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS320",
    value: "CLS320",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS350",
    value: "CLS350",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS400",
    value: "CLS400",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS450",
    value: "CLS450",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS500",
    value: "CLS500",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS53 AMG",
    value: "CLS53 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS55 AMG",
    value: "CLS55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS550",
    value: "CLS550",
    brand: "Mercedes-Benz",
  },
  {
    label: "CLS63 AMG",
    value: "CLS63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E200",
    value: "E200",
    brand: "Mercedes-Benz",
  },
  {
    label: "E220",
    value: "E220",
    brand: "Mercedes-Benz",
  },
  {
    label: "E230",
    value: "E230",
    brand: "Mercedes-Benz",
  },
  {
    label: "E240",
    value: "E240",
    brand: "Mercedes-Benz",
  },
  {
    label: "E250",
    value: "E250",
    brand: "Mercedes-Benz",
  },
  {
    label: "E260",
    value: "E260",
    brand: "Mercedes-Benz",
  },
  {
    label: "E270",
    value: "E270",
    brand: "Mercedes-Benz",
  },
  {
    label: "E280",
    value: "E280",
    brand: "Mercedes-Benz",
  },
  {
    label: "E290",
    value: "E290",
    brand: "Mercedes-Benz",
  },
  {
    label: "E300",
    value: "E300",
    brand: "Mercedes-Benz",
  },
  {
    label: "E320",
    value: "E320",
    brand: "Mercedes-Benz",
  },
  {
    label: "E350",
    value: "E350",
    brand: "Mercedes-Benz",
  },
  {
    label: "E36 AMG",
    value: "E36 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E400",
    value: "E400",
    brand: "Mercedes-Benz",
  },
  {
    label: "E420",
    value: "E420",
    brand: "Mercedes-Benz",
  },
  {
    label: "E43 AMG",
    value: "E43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E430",
    value: "E430",
    brand: "Mercedes-Benz",
  },
  {
    label: "E450",
    value: "E450",
    brand: "Mercedes-Benz",
  },
  {
    label: "E50 AMG",
    value: "E50 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E500",
    value: "E500",
    brand: "Mercedes-Benz",
  },
  {
    label: "E53",
    value: "E53",
    brand: "Mercedes-Benz",
  },
  {
    label: "E55",
    value: "E55",
    brand: "Mercedes-Benz",
  },
  {
    label: "E55 AMG",
    value: "E55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E550",
    value: "E550",
    brand: "Mercedes-Benz",
  },
  {
    label: "E60 AMG",
    value: "E60 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E63 AMG",
    value: "E63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "E-Class All-Terrain",
    value: "E-Class All-Terrain",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQA",
    value: "EQA",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQB",
    value: "EQB",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQC",
    value: "EQC",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQE",
    value: "EQE",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQS",
    value: "EQS",
    brand: "Mercedes-Benz",
  },
  {
    label: "EQV",
    value: "EQV",
    brand: "Mercedes-Benz",
  },
  {
    label: "G230",
    value: "G230",
    brand: "Mercedes-Benz",
  },
  {
    label: "G240",
    value: "G240",
    brand: "Mercedes-Benz",
  },
  {
    label: "G250",
    value: "G250",
    brand: "Mercedes-Benz",
  },
  {
    label: "G270",
    value: "G270",
    brand: "Mercedes-Benz",
  },
  {
    label: "G280",
    value: "G280",
    brand: "Mercedes-Benz",
  },
  {
    label: "G290",
    value: "G290",
    brand: "Mercedes-Benz",
  },
  {
    label: "G300",
    value: "G300",
    brand: "Mercedes-Benz",
  },
  {
    label: "G320",
    value: "G320",
    brand: "Mercedes-Benz",
  },
  {
    label: "G350",
    value: "G350",
    brand: "Mercedes-Benz",
  },
  {
    label: "G400",
    value: "G400",
    brand: "Mercedes-Benz",
  },
  {
    label: "G500",
    value: "G500",
    brand: "Mercedes-Benz",
  },
  {
    label: "G55 AMG",
    value: "G55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "G550",
    value: "G550",
    brand: "Mercedes-Benz",
  },
  {
    label: "G63 AMG",
    value: "G63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "G65 AMG",
    value: "G65 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL320",
    value: "GL320",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL350",
    value: "GL350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL350 BlueTEC",
    value: "GL350 BlueTEC",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL400",
    value: "GL400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL420",
    value: "GL420",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL450",
    value: "GL450",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL500",
    value: "GL500",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL550",
    value: "GL550",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL55 AMG",
    value: "GL55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GL63 AMG",
    value: "GL63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLA180",
    value: "GLA180",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLA200",
    value: "GLA200",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLA220",
    value: "GLA220",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLA250",
    value: "GLA250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLA45 AMG",
    value: "GLA45 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLB180",
    value: "GLB180",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLB200",
    value: "GLB200",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLB220",
    value: "GLB220",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLB250",
    value: "GLB250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLB35 AMG",
    value: "GLB35 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 200 ",
    value: "GLC Coupe 200 ",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 220",
    value: "GLC Coupe 220",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 250",
    value: "GLC Coupe 250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 300",
    value: "GLC Coupe 300",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 350",
    value: "GLC Coupe 350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 400",
    value: "GLC Coupe 400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 43 AMG",
    value: "GLC Coupe 43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC Coupe 63 AMG",
    value: "GLC Coupe 63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC200",
    value: "GLC200",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC220",
    value: "GLC220",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC250",
    value: "GLC250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC300",
    value: "GLC300",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC350",
    value: "GLC350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC400",
    value: "GLC400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC43 AMG",
    value: "GLC43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLC63 AMG",
    value: "GLC63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 250",
    value: "GLE Coupe 250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 350",
    value: "GLE Coupe 350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 400",
    value: "GLE Coupe 400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 43 AMG",
    value: "GLE Coupe 43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 450",
    value: "GLE Coupe 450",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 500",
    value: "GLE Coupe 500",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 580",
    value: "GLE Coupe 580",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 53 AMG",
    value: "GLE Coupe 53 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE Coupe 63 AMG",
    value: "GLE Coupe 63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE250",
    value: "GLE250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE300",
    value: "GLE300",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE350",
    value: "GLE350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE400",
    value: "GLE400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE43 AMG",
    value: "GLE43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE450",
    value: "GLE450",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE500",
    value: "GLE500",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE53 AMG",
    value: "GLE53 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE580",
    value: "GLE580",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLE63 AMG",
    value: "GLE63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK200",
    value: "GLK200",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK220",
    value: "GLK220",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK250",
    value: "GLK250",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK280",
    value: "GLK280",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK300",
    value: "GLK300",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK320",
    value: "GLK320",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLK350",
    value: "GLK350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS350",
    value: "GLS350",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS400",
    value: "GLS400",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS450",
    value: "GLS450",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS500",
    value: "GLS500",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS550",
    value: "GLS550",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS580",
    value: "GLS580",
    brand: "Mercedes-Benz",
  },
  {
    label: "GLS63 AMG",
    value: "GLS63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "M klasė",
    value: "M klasė",
    brand: "Mercedes-Benz",
  },
  {
    label: "Maybach GLS 600",
    value: "Maybach GLS 600",
    brand: "Mercedes-Benz",
  },
  {
    label: "MB100",
    value: "MB100",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML230",
    value: "ML230",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML250",
    value: "ML250",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML270",
    value: "ML270",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML280",
    value: "ML280",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML300",
    value: "ML300",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML320",
    value: "ML320",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML350",
    value: "ML350",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML400",
    value: "ML400",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML420",
    value: "ML420",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML430",
    value: "ML430",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML450",
    value: "ML450",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML500",
    value: "ML500",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML53 AMG",
    value: "ML53 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML55 AMG",
    value: "ML55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML550",
    value: "ML550",
    brand: "Mercedes-Benz",
  },
  {
    label: "ML63 AMG",
    value: "ML63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "R280",
    value: "R280",
    brand: "Mercedes-Benz",
  },
  {
    label: "R300",
    value: "R300",
    brand: "Mercedes-Benz",
  },
  {
    label: "R320",
    value: "R320",
    brand: "Mercedes-Benz",
  },
  {
    label: "R350",
    value: "R350",
    brand: "Mercedes-Benz",
  },
  {
    label: "R500",
    value: "R500",
    brand: "Mercedes-Benz",
  },
  {
    label: "R63 AMG",
    value: "R63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "S250",
    value: "S250",
    brand: "Mercedes-Benz",
  },
  {
    label: "S260",
    value: "S260",
    brand: "Mercedes-Benz",
  },
  {
    label: "S280",
    value: "S280",
    brand: "Mercedes-Benz",
  },
  {
    label: "S300",
    value: "S300",
    brand: "Mercedes-Benz",
  },
  {
    label: "S320",
    value: "S320",
    brand: "Mercedes-Benz",
  },
  {
    label: "S350",
    value: "S350",
    brand: "Mercedes-Benz",
  },
  {
    label: "S400",
    value: "S400",
    brand: "Mercedes-Benz",
  },
  {
    label: "S420",
    value: "S420",
    brand: "Mercedes-Benz",
  },
  {
    label: "S430",
    value: "S430",
    brand: "Mercedes-Benz",
  },
  {
    label: "S450",
    value: "S450",
    brand: "Mercedes-Benz",
  },
  {
    label: "S500",
    value: "S500",
    brand: "Mercedes-Benz",
  },
  {
    label: "S55 AMG",
    value: "S55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "S550",
    value: "S550",
    brand: "Mercedes-Benz",
  },
  {
    label: "S560",
    value: "S560",
    brand: "Mercedes-Benz",
  },
  {
    label: "S580",
    value: "S580",
    brand: "Mercedes-Benz",
  },
  {
    label: "S600",
    value: "S600",
    brand: "Mercedes-Benz",
  },
  {
    label: "S63 AMG",
    value: "S63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "S65 AMG",
    value: "S65 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL280",
    value: "SL280",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL300",
    value: "SL300",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL320",
    value: "SL320",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL350",
    value: "SL350",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL380",
    value: "SL380",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL400",
    value: "SL400",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL420",
    value: "SL420",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL43 AMG",
    value: "SL43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL450",
    value: "SL450",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL500",
    value: "SL500",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL55 AMG",
    value: "SL55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL550",
    value: "SL550",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL560",
    value: "SL560",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL60 AMG",
    value: "SL60 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL600",
    value: "SL600",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL63 AMG",
    value: "SL63 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL65 AMG",
    value: "SL65 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL70 AMG",
    value: "SL70 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SL73 AMG",
    value: "SL73 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLC180",
    value: "SLC180",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLC200",
    value: "SLC200",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLC250",
    value: "SLC250",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLC300",
    value: "SLC300",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLC43 AMG",
    value: "SLC43 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK200",
    value: "SLK200",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK230",
    value: "SLK230",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK250",
    value: "SLK250",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK260",
    value: "SLK260",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK280",
    value: "SLK280",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK300",
    value: "SLK300",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK32 AMG",
    value: "SLK32 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK320",
    value: "SLK320",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK350",
    value: "SLK350",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLK55 AMG",
    value: "SLK55 AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLR McLaren",
    value: "SLR McLaren",
    brand: "Mercedes-Benz",
  },
  {
    label: "SLS AMG",
    value: "SLS AMG",
    brand: "Mercedes-Benz",
  },
  {
    label: "Sprinter",
    value: "Sprinter",
    brand: "Mercedes-Benz",
  },
  {
    label: "T 1",
    value: "T 1",
    brand: "Mercedes-Benz",
  },
  {
    label: "V200",
    value: "V200",
    brand: "Mercedes-Benz",
  },
  {
    label: "V220",
    value: "V220",
    brand: "Mercedes-Benz",
  },
  {
    label: "V230",
    value: "V230",
    brand: "Mercedes-Benz",
  },
  {
    label: "V250",
    value: "V250",
    brand: "Mercedes-Benz",
  },
  {
    label: "V280",
    value: "V280",
    brand: "Mercedes-Benz",
  },
  {
    label: "V300",
    value: "V300",
    brand: "Mercedes-Benz",
  },
  {
    label: "Vaneo",
    value: "Vaneo",
    brand: "Mercedes-Benz",
  },
  {
    label: "Vario",
    value: "Vario",
    brand: "Mercedes-Benz",
  },
  {
    label: "Viano",
    value: "Viano",
    brand: "Mercedes-Benz",
  },
  {
    label: "Vito",
    value: "Vito",
    brand: "Mercedes-Benz",
  },
  {
    label: "X220",
    value: "X220",
    brand: "Mercedes-Benz",
  },
  {
    label: "X250",
    value: "X250",
    brand: "Mercedes-Benz",
  },
  {
    label: "X350",
    value: "X350",
    brand: "Mercedes-Benz",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mercury",
  },
  {
    label: "Capri",
    value: "Capri",
    brand: "Mercury",
  },
  {
    label: "Cougar",
    value: "Cougar",
    brand: "Mercury",
  },
  {
    label: "Grand Marquis",
    value: "Grand Marquis",
    brand: "Mercury",
  },
  {
    label: "Marauder",
    value: "Marauder",
    brand: "Mercury",
  },
  {
    label: "Mariner",
    value: "Mariner",
    brand: "Mercury",
  },
  {
    label: "Milan",
    value: "Milan",
    brand: "Mercury",
  },
  {
    label: "Mountaineer",
    value: "Mountaineer",
    brand: "Mercury",
  },
  {
    label: "Mystique",
    value: "Mystique",
    brand: "Mercury",
  },
  {
    label: "Sable",
    value: "Sable",
    brand: "Mercury",
  },
  {
    label: "Tiffany",
    value: "Tiffany",
    brand: "Mercury",
  },
  {
    label: "Tracer",
    value: "Tracer",
    brand: "Mercury",
  },
  {
    label: "Villager",
    value: "Villager",
    brand: "Mercury",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "MG",
  },
  {
    label: "EHS",
    value: "EHS",
    brand: "MG",
  },
  {
    label: "MG4 EV",
    value: "MG4 EV",
    brand: "MG",
  },
  {
    label: "MGA",
    value: "MGA",
    brand: "MG",
  },
  {
    label: "MGB",
    value: "MGB",
    brand: "MG",
  },
  {
    label: "MGF",
    value: "MGF",
    brand: "MG",
  },
  {
    label: "MGR V8",
    value: "MGR V8",
    brand: "MG",
  },
  {
    label: "Midget",
    value: "Midget",
    brand: "MG",
  },
  {
    label: "TF",
    value: "TF",
    brand: "MG",
  },
  {
    label: "X",
    value: "X",
    brand: "MG",
  },
  {
    label: "ZR",
    value: "ZR",
    brand: "MG",
  },
  {
    label: "ZS",
    value: "ZS",
    brand: "MG",
  },
  {
    label: "ZT",
    value: "ZT",
    brand: "MG",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Microcar",
  },
  {
    label: "Mc 1",
    value: "Mc 1",
    brand: "Microcar",
  },
  {
    label: "Mgo",
    value: "Mgo",
    brand: "Microcar",
  },
  {
    label: "M8",
    value: "M8",
    brand: "Microcar",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mini",
  },
  {
    label: "1000",
    value: "1000",
    brand: "Mini",
  },
  {
    label: "1300",
    value: "1300",
    brand: "Mini",
  },
  {
    label: "Clubman",
    value: "Clubman",
    brand: "Mini",
  },
  {
    label: "Cooper",
    value: "Cooper",
    brand: "Mini",
  },
  {
    label: "Cooper S",
    value: "Cooper S",
    brand: "Mini",
  },
  {
    label: "Cooper SD",
    value: "Cooper SD",
    brand: "Mini",
  },
  {
    label: "Countryman",
    value: "Countryman",
    brand: "Mini",
  },
  {
    label: "Countryman S",
    value: "Countryman S",
    brand: "Mini",
  },
  {
    label: "One",
    value: "One",
    brand: "Mini",
  },
  {
    label: "Paceman",
    value: "Paceman",
    brand: "Mini",
  },
  {
    label: "Paceman S",
    value: "Paceman S",
    brand: "Mini",
  },
  {
    label: "Roadster",
    value: "Roadster",
    brand: "Mini",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Mitsubishi",
  },
  {
    label: "3000 GT",
    value: "3000 GT",
    brand: "Mitsubishi",
  },
  {
    label: "ASX",
    value: "ASX",
    brand: "Mitsubishi",
  },
  {
    label: "Canter",
    value: "Canter",
    brand: "Mitsubishi",
  },
  {
    label: "Carisma",
    value: "Carisma",
    brand: "Mitsubishi",
  },
  {
    label: "Colt",
    value: "Colt",
    brand: "Mitsubishi",
  },
  {
    label: "Cordia",
    value: "Cordia",
    brand: "Mitsubishi",
  },
  {
    label: "Delica",
    value: "Delica",
    brand: "Mitsubishi",
  },
  {
    label: "Diamante",
    value: "Diamante",
    brand: "Mitsubishi",
  },
  {
    label: "Eclipse",
    value: "Eclipse",
    brand: "Mitsubishi",
  },
  {
    label: "Eclipse Cross",
    value: "Eclipse Cross",
    brand: "Mitsubishi",
  },
  {
    label: "Endeavor",
    value: "Endeavor",
    brand: "Mitsubishi",
  },
  {
    label: "FTO",
    value: "FTO",
    brand: "Mitsubishi",
  },
  {
    label: "Galant",
    value: "Galant",
    brand: "Mitsubishi",
  },
  {
    label: "Galloper",
    value: "Galloper",
    brand: "Mitsubishi",
  },
  {
    label: "Grandis",
    value: "Grandis",
    brand: "Mitsubishi",
  },
  {
    label: "GTO",
    value: "GTO",
    brand: "Mitsubishi",
  },
  {
    label: "L200",
    value: "L200",
    brand: "Mitsubishi",
  },
  {
    label: "L300",
    value: "L300",
    brand: "Mitsubishi",
  },
  {
    label: "L400",
    value: "L400",
    brand: "Mitsubishi",
  },
  {
    label: "Lancer",
    value: "Lancer",
    brand: "Mitsubishi",
  },
  {
    label: "Lancer Evolution",
    value: "Lancer Evolution",
    brand: "Mitsubishi",
  },
  {
    label: "MiEV",
    value: "MiEV",
    brand: "Mitsubishi",
  },
  {
    label: "Mirage",
    value: "Mirage",
    brand: "Mitsubishi",
  },
  {
    label: "Montero",
    value: "Montero",
    brand: "Mitsubishi",
  },
  {
    label: "Outlander",
    value: "Outlander",
    brand: "Mitsubishi",
  },
  {
    label: "Pajero",
    value: "Pajero",
    brand: "Mitsubishi",
  },
  {
    label: "Pajero Pinin",
    value: "Pajero Pinin",
    brand: "Mitsubishi",
  },
  {
    label: "Pajero Sport",
    value: "Pajero Sport",
    brand: "Mitsubishi",
  },
  {
    label: "Pick-up",
    value: "Pick-up",
    brand: "Mitsubishi",
  },
  {
    label: "Pinin",
    value: "Pinin",
    brand: "Mitsubishi",
  },
  {
    label: "Santamo",
    value: "Santamo",
    brand: "Mitsubishi",
  },
  {
    label: "Sapporo",
    value: "Sapporo",
    brand: "Mitsubishi",
  },
  {
    label: "Shogun",
    value: "Shogun",
    brand: "Mitsubishi",
  },
  {
    label: "Sigma",
    value: "Sigma",
    brand: "Mitsubishi",
  },
  {
    label: "Space Gear",
    value: "Space Gear",
    brand: "Mitsubishi",
  },
  {
    label: "Space Runner",
    value: "Space Runner",
    brand: "Mitsubishi",
  },
  {
    label: "Space Star",
    value: "Space Star",
    brand: "Mitsubishi",
  },
  {
    label: "Space Wagon",
    value: "Space Wagon",
    brand: "Mitsubishi",
  },
  {
    label: "Starion",
    value: "Starion",
    brand: "Mitsubishi",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Morgan",
  },
  {
    label: "4/4",
    value: "4/4",
    brand: "Morgan",
  },
  {
    label: "Aero 8",
    value: "Aero 8",
    brand: "Morgan",
  },
  {
    label: "Roadster",
    value: "Roadster",
    brand: "Morgan",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Moskvich",
  },
  {
    label: "2125",
    value: "2125",
    brand: "Moskvich",
  },
  {
    label: "2140",
    value: "2140",
    brand: "Moskvich",
  },
  {
    label: "2141",
    value: "2141",
    brand: "Moskvich",
  },
  {
    label: "401",
    value: "401",
    brand: "Moskvich",
  },
  {
    label: "403",
    value: "403",
    brand: "Moskvich",
  },
  {
    label: "407",
    value: "407",
    brand: "Moskvich",
  },
  {
    label: "408",
    value: "408",
    brand: "Moskvich",
  },
  {
    label: "412",
    value: "412",
    brand: "Moskvich",
  },
  {
    label: "423",
    value: "423",
    brand: "Moskvich",
  },
  {
    label: "434",
    value: "434",
    brand: "Moskvich",
  },
  {
    label: "M-400",
    value: "M-400",
    brand: "Moskvich",
  },
  {
    label: "M-402",
    value: "M-402",
    brand: "Moskvich",
  },
  {
    label: "M-408",
    value: "M-408",
    brand: "Moskvich",
  },
  {
    label: "M-410",
    value: "M-410",
    brand: "Moskvich",
  },
  {
    label: "M-412",
    value: "M-412",
    brand: "Moskvich",
  },
  {
    label: "Erelis",
    value: "Erelis",
    brand: "MPM",
  },
  {
    label: "PS",
    value: "PS",
    brand: "MPM",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Nissan",
  },
  {
    label: "100 NX",
    value: "100 NX",
    brand: "Nissan",
  },
  {
    label: "200 SX",
    value: "200 SX",
    brand: "Nissan",
  },
  {
    label: "240 SX",
    value: "240 SX",
    brand: "Nissan",
  },
  {
    label: "280 ZX",
    value: "280 ZX",
    brand: "Nissan",
  },
  {
    label: "300 ZX",
    value: "300 ZX",
    brand: "Nissan",
  },
  {
    label: "350Z",
    value: "350Z",
    brand: "Nissan",
  },
  {
    label: "370Z",
    value: "370Z",
    brand: "Nissan",
  },
  {
    label: "Almera",
    value: "Almera",
    brand: "Nissan",
  },
  {
    label: "Almera Tino",
    value: "Almera Tino",
    brand: "Nissan",
  },
  {
    label: "Altima",
    value: "Altima",
    brand: "Nissan",
  },
  {
    label: "ARIYA",
    value: "ARIYA",
    brand: "Nissan",
  },
  {
    label: "Armada",
    value: "Armada",
    brand: "Nissan",
  },
  {
    label: "Atleon",
    value: "Atleon",
    brand: "Nissan",
  },
  {
    label: "Bluebird",
    value: "Bluebird",
    brand: "Nissan",
  },
  {
    label: "Cabstar",
    value: "Cabstar",
    brand: "Nissan",
  },
  {
    label: "Cargo",
    value: "Cargo",
    brand: "Nissan",
  },
  {
    label: "Cherry",
    value: "Cherry",
    brand: "Nissan",
  },
  {
    label: "Cima",
    value: "Cima",
    brand: "Nissan",
  },
  {
    label: "Cube",
    value: "Cube",
    brand: "Nissan",
  },
  {
    label: "e-NV200",
    value: "e-NV200",
    brand: "Nissan",
  },
  {
    label: "Evalia",
    value: "Evalia",
    brand: "Nissan",
  },
  {
    label: "Frontier",
    value: "Frontier",
    brand: "Nissan",
  },
  {
    label: "Fuga",
    value: "Fuga",
    brand: "Nissan",
  },
  {
    label: "GT-R",
    value: "GT-R",
    brand: "Nissan",
  },
  {
    label: "Interstar",
    value: "Interstar",
    brand: "Nissan",
  },
  {
    label: "Juke",
    value: "Juke",
    brand: "Nissan",
  },
  {
    label: "King Cab",
    value: "King Cab",
    brand: "Nissan",
  },
  {
    label: "Kubistar",
    value: "Kubistar",
    brand: "Nissan",
  },
  {
    label: "Laurel",
    value: "Laurel",
    brand: "Nissan",
  },
  {
    label: "Leaf",
    value: "Leaf",
    brand: "Nissan",
  },
  {
    label: "Liberty",
    value: "Liberty",
    brand: "Nissan",
  },
  {
    label: "Maxima",
    value: "Maxima",
    brand: "Nissan",
  },
  {
    label: "Micra",
    value: "Micra",
    brand: "Nissan",
  },
  {
    label: "Murano",
    value: "Murano",
    brand: "Nissan",
  },
  {
    label: "Navara",
    value: "Navara",
    brand: "Nissan",
  },
  {
    label: "Note",
    value: "Note",
    brand: "Nissan",
  },
  {
    label: "NP300",
    value: "NP300",
    brand: "Nissan",
  },
  {
    label: "NV200",
    value: "NV200",
    brand: "Nissan",
  },
  {
    label: "NV250",
    value: "NV250",
    brand: "Nissan",
  },
  {
    label: "NV300",
    value: "NV300",
    brand: "Nissan",
  },
  {
    label: "NV400",
    value: "NV400",
    brand: "Nissan",
  },
  {
    label: "Pathfinder",
    value: "Pathfinder",
    brand: "Nissan",
  },
  {
    label: "Patrol",
    value: "Patrol",
    brand: "Nissan",
  },
  {
    label: "Pick-Up",
    value: "Pick-Up",
    brand: "Nissan",
  },
  {
    label: "Pixo",
    value: "Pixo",
    brand: "Nissan",
  },
  {
    label: "Prairie",
    value: "Prairie",
    brand: "Nissan",
  },
  {
    label: "Primastar",
    value: "Primastar",
    brand: "Nissan",
  },
  {
    label: "Primera",
    value: "Primera",
    brand: "Nissan",
  },
  {
    label: "Pulsar",
    value: "Pulsar",
    brand: "Nissan",
  },
  {
    label: "Qashqai",
    value: "Qashqai",
    brand: "Nissan",
  },
  {
    label: "Qashqai+2",
    value: "Qashqai+2",
    brand: "Nissan",
  },
  {
    label: "Quest",
    value: "Quest",
    brand: "Nissan",
  },
  {
    label: "Rogue",
    value: "Rogue",
    brand: "Nissan",
  },
  {
    label: "Sentra",
    value: "Sentra",
    brand: "Nissan",
  },
  {
    label: "Serena",
    value: "Serena",
    brand: "Nissan",
  },
  {
    label: "Silvia",
    value: "Silvia",
    brand: "Nissan",
  },
  {
    label: "Skyline",
    value: "Skyline",
    brand: "Nissan",
  },
  {
    label: "Sunny",
    value: "Sunny",
    brand: "Nissan",
  },
  {
    label: "Terrano",
    value: "Terrano",
    brand: "Nissan",
  },
  {
    label: "Tiida",
    value: "Tiida",
    brand: "Nissan",
  },
  {
    label: "Titan",
    value: "Titan",
    brand: "Nissan",
  },
  {
    label: "Trade",
    value: "Trade",
    brand: "Nissan",
  },
  {
    label: "Urvan",
    value: "Urvan",
    brand: "Nissan",
  },
  {
    label: "Vanette",
    value: "Vanette",
    brand: "Nissan",
  },
  {
    label: "Versa",
    value: "Versa",
    brand: "Nissan",
  },
  {
    label: "Wingroad",
    value: "Wingroad",
    brand: "Nissan",
  },
  {
    label: "X-Terra",
    value: "X-Terra",
    brand: "Nissan",
  },
  {
    label: "X-Trail",
    value: "X-Trail",
    brand: "Nissan",
  },
  {
    label: "600R",
    value: "600R",
    brand: "Norster",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Nysa",
  },
  {
    label: "522",
    value: "522",
    brand: "Nysa",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Oldsmobile",
  },
  {
    label: "Achieva",
    value: "Achieva",
    brand: "Oldsmobile",
  },
  {
    label: "Alero",
    value: "Alero",
    brand: "Oldsmobile",
  },
  {
    label: "Aurora",
    value: "Aurora",
    brand: "Oldsmobile",
  },
  {
    label: "Bravada",
    value: "Bravada",
    brand: "Oldsmobile",
  },
  {
    label: "Custom Cruiser",
    value: "Custom Cruiser",
    brand: "Oldsmobile",
  },
  {
    label: "Cutlass",
    value: "Cutlass",
    brand: "Oldsmobile",
  },
  {
    label: "Delta 88",
    value: "Delta 88",
    brand: "Oldsmobile",
  },
  {
    label: "Eighty-Eight",
    value: "Eighty-Eight",
    brand: "Oldsmobile",
  },
  {
    label: "Intrigue",
    value: "Intrigue",
    brand: "Oldsmobile",
  },
  {
    label: "Silhouete",
    value: "Silhouete",
    brand: "Oldsmobile",
  },
  {
    label: "Silhouette",
    value: "Silhouette",
    brand: "Oldsmobile",
  },
  {
    label: "Super 88",
    value: "Super 88",
    brand: "Oldsmobile",
  },
  {
    label: "Toronado",
    value: "Toronado",
    brand: "Oldsmobile",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Opel",
  },
  {
    label: "Adam",
    value: "Adam",
    brand: "Opel",
  },
  {
    label: "Agila",
    value: "Agila",
    brand: "Opel",
  },
  {
    label: "Ampera",
    value: "Ampera",
    brand: "Opel",
  },
  {
    label: "Antara",
    value: "Antara",
    brand: "Opel",
  },
  {
    label: "Arena",
    value: "Arena",
    brand: "Opel",
  },
  {
    label: "Ascona",
    value: "Ascona",
    brand: "Opel",
  },
  {
    label: "Astra",
    value: "Astra",
    brand: "Opel",
  },
  {
    label: "Calibra",
    value: "Calibra",
    brand: "Opel",
  },
  {
    label: "Campo",
    value: "Campo",
    brand: "Opel",
  },
  {
    label: "Cascada",
    value: "Cascada",
    brand: "Opel",
  },
  {
    label: "Combo",
    value: "Combo",
    brand: "Opel",
  },
  {
    label: "Commodore GS",
    value: "Commodore GS",
    brand: "Opel",
  },
  {
    label: "Corsa",
    value: "Corsa",
    brand: "Opel",
  },
  {
    label: "Crossland",
    value: "Crossland",
    brand: "Opel",
  },
  {
    label: "Crossland X",
    value: "Crossland X",
    brand: "Opel",
  },
  {
    label: "Diplomat",
    value: "Diplomat",
    brand: "Opel",
  },
  {
    label: "Frontera",
    value: "Frontera",
    brand: "Opel",
  },
  {
    label: "Grandland",
    value: "Grandland",
    brand: "Opel",
  },
  {
    label: "Grandland X",
    value: "Grandland X",
    brand: "Opel",
  },
  {
    label: "GT",
    value: "GT",
    brand: "Opel",
  },
  {
    label: "Insignia",
    value: "Insignia",
    brand: "Opel",
  },
  {
    label: "Kadett",
    value: "Kadett",
    brand: "Opel",
  },
  {
    label: "Karl",
    value: "Karl",
    brand: "Opel",
  },
  {
    label: "Manta",
    value: "Manta",
    brand: "Opel",
  },
  {
    label: "Meriva",
    value: "Meriva",
    brand: "Opel",
  },
  {
    label: "Mokka",
    value: "Mokka",
    brand: "Opel",
  },
  {
    label: "Mokka X",
    value: "Mokka X",
    brand: "Opel",
  },
  {
    label: "Monterey",
    value: "Monterey",
    brand: "Opel",
  },
  {
    label: "Monza",
    value: "Monza",
    brand: "Opel",
  },
  {
    label: "Movano",
    value: "Movano",
    brand: "Opel",
  },
  {
    label: "Nova",
    value: "Nova",
    brand: "Opel",
  },
  {
    label: "Omega",
    value: "Omega",
    brand: "Opel",
  },
  {
    label: "Rekord",
    value: "Rekord",
    brand: "Opel",
  },
  {
    label: "Senator",
    value: "Senator",
    brand: "Opel",
  },
  {
    label: "Signum",
    value: "Signum",
    brand: "Opel",
  },
  {
    label: "Sintra",
    value: "Sintra",
    brand: "Opel",
  },
  {
    label: "Speedster",
    value: "Speedster",
    brand: "Opel",
  },
  {
    label: "Tigra",
    value: "Tigra",
    brand: "Opel",
  },
  {
    label: "Vectra",
    value: "Vectra",
    brand: "Opel",
  },
  {
    label: "Vivaro",
    value: "Vivaro",
    brand: "Opel",
  },
  {
    label: "Zafira",
    value: "Zafira",
    brand: "Opel",
  },
  {
    label: "Zafira Life",
    value: "Zafira Life",
    brand: "Opel",
  },
  {
    label: "Zafira tourer",
    value: "Zafira tourer",
    brand: "Opel",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Packard",
  },
  {
    label: "110",
    value: "110",
    brand: "Packard",
  },
  {
    label: "120",
    value: "120",
    brand: "Packard",
  },
  {
    label: "180",
    value: "180",
    brand: "Packard",
  },
  {
    label: "200",
    value: "200",
    brand: "Packard",
  },
  {
    label: "Caribbean",
    value: "Caribbean",
    brand: "Packard",
  },
  {
    label: "Clipper",
    value: "Clipper",
    brand: "Packard",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Peugeot",
  },
  {
    label: "1007",
    value: "1007",
    brand: "Peugeot",
  },
  {
    label: "104",
    value: "104",
    brand: "Peugeot",
  },
  {
    label: "106",
    value: "106",
    brand: "Peugeot",
  },
  {
    label: "107",
    value: "107",
    brand: "Peugeot",
  },
  {
    label: "108",
    value: "108",
    brand: "Peugeot",
  },
  {
    label: "2008",
    value: "2008",
    brand: "Peugeot",
  },
  {
    label: "204",
    value: "204",
    brand: "Peugeot",
  },
  {
    label: "205",
    value: "205",
    brand: "Peugeot",
  },
  {
    label: "206",
    value: "206",
    brand: "Peugeot",
  },
  {
    label: "206+",
    value: "206+",
    brand: "Peugeot",
  },
  {
    label: "207",
    value: "207",
    brand: "Peugeot",
  },
  {
    label: "208",
    value: "208",
    brand: "Peugeot",
  },
  {
    label: "3008",
    value: "3008",
    brand: "Peugeot",
  },
  {
    label: "301",
    value: "301",
    brand: "Peugeot",
  },
  {
    label: "304",
    value: "304",
    brand: "Peugeot",
  },
  {
    label: "305",
    value: "305",
    brand: "Peugeot",
  },
  {
    label: "306",
    value: "306",
    brand: "Peugeot",
  },
  {
    label: "307",
    value: "307",
    brand: "Peugeot",
  },
  {
    label: "308",
    value: "308",
    brand: "Peugeot",
  },
  {
    label: "309",
    value: "309",
    brand: "Peugeot",
  },
  {
    label: "4007",
    value: "4007",
    brand: "Peugeot",
  },
  {
    label: "4008",
    value: "4008",
    brand: "Peugeot",
  },
  {
    label: "404",
    value: "404",
    brand: "Peugeot",
  },
  {
    label: "405",
    value: "405",
    brand: "Peugeot",
  },
  {
    label: "406",
    value: "406",
    brand: "Peugeot",
  },
  {
    label: "407",
    value: "407",
    brand: "Peugeot",
  },
  {
    label: "408",
    value: "408",
    brand: "Peugeot",
  },
  {
    label: "5008",
    value: "5008",
    brand: "Peugeot",
  },
  {
    label: "504",
    value: "504",
    brand: "Peugeot",
  },
  {
    label: "505",
    value: "505",
    brand: "Peugeot",
  },
  {
    label: "508",
    value: "508",
    brand: "Peugeot",
  },
  {
    label: "508 RXH",
    value: "508 RXH",
    brand: "Peugeot",
  },
  {
    label: "604",
    value: "604",
    brand: "Peugeot",
  },
  {
    label: "605",
    value: "605",
    brand: "Peugeot",
  },
  {
    label: "607",
    value: "607",
    brand: "Peugeot",
  },
  {
    label: "806",
    value: "806",
    brand: "Peugeot",
  },
  {
    label: "807",
    value: "807",
    brand: "Peugeot",
  },
  {
    label: "Bipper",
    value: "Bipper",
    brand: "Peugeot",
  },
  {
    label: "Boxer",
    value: "Boxer",
    brand: "Peugeot",
  },
  {
    label: "Expert",
    value: "Expert",
    brand: "Peugeot",
  },
  {
    label: "iOn",
    value: "iOn",
    brand: "Peugeot",
  },
  {
    label: "J5",
    value: "J5",
    brand: "Peugeot",
  },
  {
    label: "Partner",
    value: "Partner",
    brand: "Peugeot",
  },
  {
    label: "RCZ",
    value: "RCZ",
    brand: "Peugeot",
  },
  {
    label: "Rifter",
    value: "Rifter",
    brand: "Peugeot",
  },
  {
    label: "Traveller",
    value: "Traveller",
    brand: "Peugeot",
  },
  {
    label: "Vivacity",
    value: "Vivacity",
    brand: "Peugeot",
  },
  {
    label: "Porter",
    value: "Porter",
    brand: "Piaggio",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Plymouth",
  },
  {
    label: "Breeze",
    value: "Breeze",
    brand: "Plymouth",
  },
  {
    label: "Grand Voyager",
    value: "Grand Voyager",
    brand: "Plymouth",
  },
  {
    label: "Neon",
    value: "Neon",
    brand: "Plymouth",
  },
  {
    label: "Prowler",
    value: "Prowler",
    brand: "Plymouth",
  },
  {
    label: "Voyager",
    value: "Voyager",
    brand: "Plymouth",
  },
  {
    label: "Polestar 1",
    value: "Polestar 1",
    brand: "Polestar",
  },
  {
    label: "Polestar 2",
    value: "Polestar 2",
    brand: "Polestar",
  },
  {
    label: "Polestar 3",
    value: "Polestar 3",
    brand: "Polestar",
  },
  {
    label: " G5",
    value: " G5",
    brand: "Pontiac",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Pontiac",
  },
  {
    label: "6000",
    value: "6000",
    brand: "Pontiac",
  },
  {
    label: "Aztek",
    value: "Aztek",
    brand: "Pontiac",
  },
  {
    label: "Bonneville",
    value: "Bonneville",
    brand: "Pontiac",
  },
  {
    label: "Catalina",
    value: "Catalina",
    brand: "Pontiac",
  },
  {
    label: "Fiero",
    value: "Fiero",
    brand: "Pontiac",
  },
  {
    label: "Firebird",
    value: "Firebird",
    brand: "Pontiac",
  },
  {
    label: "G6",
    value: "G6",
    brand: "Pontiac",
  },
  {
    label: "G8",
    value: "G8",
    brand: "Pontiac",
  },
  {
    label: "Grand-Am",
    value: "Grand-Am",
    brand: "Pontiac",
  },
  {
    label: "Grand-Prix",
    value: "Grand-Prix",
    brand: "Pontiac",
  },
  {
    label: "GTO",
    value: "GTO",
    brand: "Pontiac",
  },
  {
    label: "Montana",
    value: "Montana",
    brand: "Pontiac",
  },
  {
    label: "Solstice",
    value: "Solstice",
    brand: "Pontiac",
  },
  {
    label: "Sunbird",
    value: "Sunbird",
    brand: "Pontiac",
  },
  {
    label: "Sunfire",
    value: "Sunfire",
    brand: "Pontiac",
  },
  {
    label: "Targa",
    value: "Targa",
    brand: "Pontiac",
  },
  {
    label: "Trans Am",
    value: "Trans Am",
    brand: "Pontiac",
  },
  {
    label: "Trans Sport",
    value: "Trans Sport",
    brand: "Pontiac",
  },
  {
    label: "Vibe",
    value: "Vibe",
    brand: "Pontiac",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Porsche",
  },
  {
    label: "356",
    value: "356",
    brand: "Porsche",
  },
  {
    label: "911",
    value: "911",
    brand: "Porsche",
  },
  {
    label: "912",
    value: "912",
    brand: "Porsche",
  },
  {
    label: "914",
    value: "914",
    brand: "Porsche",
  },
  {
    label: "924",
    value: "924",
    brand: "Porsche",
  },
  {
    label: "928",
    value: "928",
    brand: "Porsche",
  },
  {
    label: "944",
    value: "944",
    brand: "Porsche",
  },
  {
    label: "959",
    value: "959",
    brand: "Porsche",
  },
  {
    label: "968",
    value: "968",
    brand: "Porsche",
  },
  {
    label: "996",
    value: "996",
    brand: "Porsche",
  },
  {
    label: "Boxster",
    value: "Boxster",
    brand: "Porsche",
  },
  {
    label: "Carrera GT",
    value: "Carrera GT",
    brand: "Porsche",
  },
  {
    label: "Cayenne",
    value: "Cayenne",
    brand: "Porsche",
  },
  {
    label: "Cayenne Coupe",
    value: "Cayenne Coupe",
    brand: "Porsche",
  },
  {
    label: "Cayman",
    value: "Cayman",
    brand: "Porsche",
  },
  {
    label: "Macan",
    value: "Macan",
    brand: "Porsche",
  },
  {
    label: "Panamera",
    value: "Panamera",
    brand: "Porsche",
  },
  {
    label: "Taycan",
    value: "Taycan",
    brand: "Porsche",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Proton",
  },
  {
    label: "313",
    value: "313",
    brand: "Proton",
  },
  {
    label: "315",
    value: "315",
    brand: "Proton",
  },
  {
    label: "416",
    value: "416",
    brand: "Proton",
  },
  {
    label: "Gen-2",
    value: "Gen-2",
    brand: "Proton",
  },
  {
    label: "Persona",
    value: "Persona",
    brand: "Proton",
  },
  {
    label: "Satria",
    value: "Satria",
    brand: "Proton",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Renault",
  },
  {
    label: "11",
    value: "11",
    brand: "Renault",
  },
  {
    label: "19",
    value: "19",
    brand: "Renault",
  },
  {
    label: "21",
    value: "21",
    brand: "Renault",
  },
  {
    label: "25",
    value: "25",
    brand: "Renault",
  },
  {
    label: "4",
    value: "4",
    brand: "Renault",
  },
  {
    label: "5",
    value: "5",
    brand: "Renault",
  },
  {
    label: "9",
    value: "9",
    brand: "Renault",
  },
  {
    label: "Alaskan",
    value: "Alaskan",
    brand: "Renault",
  },
  {
    label: "Alpine",
    value: "Alpine",
    brand: "Renault",
  },
  {
    label: "Arkana",
    value: "Arkana",
    brand: "Renault",
  },
  {
    label: "Austral",
    value: "Austral",
    brand: "Renault",
  },
  {
    label: "Avantime",
    value: "Avantime",
    brand: "Renault",
  },
  {
    label: "Captur",
    value: "Captur",
    brand: "Renault",
  },
  {
    label: "Clio",
    value: "Clio",
    brand: "Renault",
  },
  {
    label: "Espace",
    value: "Espace",
    brand: "Renault",
  },
  {
    label: "Express",
    value: "Express",
    brand: "Renault",
  },
  {
    label: "Fluence",
    value: "Fluence",
    brand: "Renault",
  },
  {
    label: "Fuego",
    value: "Fuego",
    brand: "Renault",
  },
  {
    label: "Grand Espace",
    value: "Grand Espace",
    brand: "Renault",
  },
  {
    label: "Grand Modus",
    value: "Grand Modus",
    brand: "Renault",
  },
  {
    label: "Grand Scenic",
    value: "Grand Scenic",
    brand: "Renault",
  },
  {
    label: "Kadjar",
    value: "Kadjar",
    brand: "Renault",
  },
  {
    label: "Kangoo",
    value: "Kangoo",
    brand: "Renault",
  },
  {
    label: "Koleos",
    value: "Koleos",
    brand: "Renault",
  },
  {
    label: "Laguna",
    value: "Laguna",
    brand: "Renault",
  },
  {
    label: "Latitude",
    value: "Latitude",
    brand: "Renault",
  },
  {
    label: "Logan",
    value: "Logan",
    brand: "Renault",
  },
  {
    label: "Magnum",
    value: "Magnum",
    brand: "Renault",
  },
  {
    label: "Mascott",
    value: "Mascott",
    brand: "Renault",
  },
  {
    label: "Master",
    value: "Master",
    brand: "Renault",
  },
  {
    label: "Maxity",
    value: "Maxity",
    brand: "Renault",
  },
  {
    label: "Megane",
    value: "Megane",
    brand: "Renault",
  },
  {
    label: "Megane E-Tech",
    value: "Megane E-Tech",
    brand: "Renault",
  },
  {
    label: "Messenger",
    value: "Messenger",
    brand: "Renault",
  },
  {
    label: "Modus",
    value: "Modus",
    brand: "Renault",
  },
  {
    label: "Nevada",
    value: "Nevada",
    brand: "Renault",
  },
  {
    label: "Rapid",
    value: "Rapid",
    brand: "Renault",
  },
  {
    label: "Safrane",
    value: "Safrane",
    brand: "Renault",
  },
  {
    label: "Scenic",
    value: "Scenic",
    brand: "Renault",
  },
  {
    label: "Spacetourer",
    value: "Spacetourer",
    brand: "Renault",
  },
  {
    label: "Sport Spider",
    value: "Sport Spider",
    brand: "Renault",
  },
  {
    label: "Super 5",
    value: "Super 5",
    brand: "Renault",
  },
  {
    label: "Symbol",
    value: "Symbol",
    brand: "Renault",
  },
  {
    label: "Talisman",
    value: "Talisman",
    brand: "Renault",
  },
  {
    label: "Thalia",
    value: "Thalia",
    brand: "Renault",
  },
  {
    label: "Trafic",
    value: "Trafic",
    brand: "Renault",
  },
  {
    label: "Twingo",
    value: "Twingo",
    brand: "Renault",
  },
  {
    label: "Twizy",
    value: "Twizy",
    brand: "Renault",
  },
  {
    label: "Vel Satis",
    value: "Vel Satis",
    brand: "Renault",
  },
  {
    label: "Wind",
    value: "Wind",
    brand: "Renault",
  },
  {
    label: "Zoe",
    value: "Zoe",
    brand: "Renault",
  },
  {
    label: "Renault Scenic RX4",
    value: "Renault Scenic RX4",
    brand: "Renault",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Roosevelt",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Rover",
  },
  {
    label: "100",
    value: "100",
    brand: "Rover",
  },
  {
    label: "200",
    value: "200",
    brand: "Rover",
  },
  {
    label: "211",
    value: "211",
    brand: "Rover",
  },
  {
    label: "213",
    value: "213",
    brand: "Rover",
  },
  {
    label: "214",
    value: "214",
    brand: "Rover",
  },
  {
    label: "216",
    value: "216",
    brand: "Rover",
  },
  {
    label: "218",
    value: "218",
    brand: "Rover",
  },
  {
    label: "220",
    value: "220",
    brand: "Rover",
  },
  {
    label: "25",
    value: "25",
    brand: "Rover",
  },
  {
    label: "3500",
    value: "3500",
    brand: "Rover",
  },
  {
    label: "400",
    value: "400",
    brand: "Rover",
  },
  {
    label: "414",
    value: "414",
    brand: "Rover",
  },
  {
    label: "416",
    value: "416",
    brand: "Rover",
  },
  {
    label: "418",
    value: "418",
    brand: "Rover",
  },
  {
    label: "420",
    value: "420",
    brand: "Rover",
  },
  {
    label: "45",
    value: "45",
    brand: "Rover",
  },
  {
    label: "600",
    value: "600",
    brand: "Rover",
  },
  {
    label: "618",
    value: "618",
    brand: "Rover",
  },
  {
    label: "620",
    value: "620",
    brand: "Rover",
  },
  {
    label: "623",
    value: "623",
    brand: "Rover",
  },
  {
    label: "75",
    value: "75",
    brand: "Rover",
  },
  {
    label: "800",
    value: "800",
    brand: "Rover",
  },
  {
    label: "820",
    value: "820",
    brand: "Rover",
  },
  {
    label: "825",
    value: "825",
    brand: "Rover",
  },
  {
    label: "827",
    value: "827",
    brand: "Rover",
  },
  {
    label: "Discovery",
    value: "Discovery",
    brand: "Rover",
  },
  {
    label: "Metro",
    value: "Metro",
    brand: "Rover",
  },
  {
    label: "MGF",
    value: "MGF",
    brand: "Rover",
  },
  {
    label: "Mini",
    value: "Mini",
    brand: "Rover",
  },
  {
    label: "Montego",
    value: "Montego",
    brand: "Rover",
  },
  {
    label: "SD1",
    value: "SD1",
    brand: "Rover",
  },
  {
    label: "Streetwise",
    value: "Streetwise",
    brand: "Rover",
  },
  {
    label: "J1",
    value: "J1",
    brand: "RUNHORSE",
  },
  {
    label: "J1-C",
    value: "J1-C",
    brand: "RUNHORSE",
  },
  {
    label: "J1-D",
    value: "J1-D",
    brand: "RUNHORSE",
  },
  {
    label: "J2",
    value: "J2",
    brand: "RUNHORSE",
  },
  {
    label: "J2-C",
    value: "J2-C",
    brand: "RUNHORSE",
  },
  {
    label: "J2-D",
    value: "J2-D",
    brand: "RUNHORSE",
  },
  {
    label: "J2-P",
    value: "J2-P",
    brand: "RUNHORSE",
  },
  {
    label: "J2-W",
    value: "J2-W",
    brand: "RUNHORSE",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Saab",
  },
  {
    label: "9-2X",
    value: "9-2X",
    brand: "Saab",
  },
  {
    label: "9-3",
    value: "9-3",
    brand: "Saab",
  },
  {
    label: "9-4X",
    value: "9-4X",
    brand: "Saab",
  },
  {
    label: "9-5",
    value: "9-5",
    brand: "Saab",
  },
  {
    label: "9-7X",
    value: "9-7X",
    brand: "Saab",
  },
  {
    label: "90",
    value: "90",
    brand: "Saab",
  },
  {
    label: "900",
    value: "900",
    brand: "Saab",
  },
  {
    label: "9000",
    value: "9000",
    brand: "Saab",
  },
  {
    label: "92",
    value: "92",
    brand: "Saab",
  },
  {
    label: "96",
    value: "96",
    brand: "Saab",
  },
  {
    label: "97",
    value: "97",
    brand: "Saab",
  },
  {
    label: "99",
    value: "99",
    brand: "Saab",
  },
  {
    label: "MAXUS EV80",
    value: "MAXUS EV80",
    brand: "SAIC",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Santana",
  },
  {
    label: "300",
    value: "300",
    brand: "Santana",
  },
  {
    label: "350",
    value: "350",
    brand: "Santana",
  },
  {
    label: "PS-10",
    value: "PS-10",
    brand: "Santana",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Saturn",
  },
  {
    label: "Aura",
    value: "Aura",
    brand: "Saturn",
  },
  {
    label: "ION",
    value: "ION",
    brand: "Saturn",
  },
  {
    label: "LS",
    value: "LS",
    brand: "Saturn",
  },
  {
    label: "LW",
    value: "LW",
    brand: "Saturn",
  },
  {
    label: "Outlook",
    value: "Outlook",
    brand: "Saturn",
  },
  {
    label: "Relay",
    value: "Relay",
    brand: "Saturn",
  },
  {
    label: "SC",
    value: "SC",
    brand: "Saturn",
  },
  {
    label: "Sky",
    value: "Sky",
    brand: "Saturn",
  },
  {
    label: "SL",
    value: "SL",
    brand: "Saturn",
  },
  {
    label: "VUE",
    value: "VUE",
    brand: "Saturn",
  },
  {
    label: "FR-S",
    value: "FR-S",
    brand: "Scion",
  },
  {
    label: "iM",
    value: "iM",
    brand: "Scion",
  },
  {
    label: "IQ",
    value: "IQ",
    brand: "Scion",
  },
  {
    label: "TC",
    value: "TC",
    brand: "Scion",
  },
  {
    label: "XA",
    value: "XA",
    brand: "Scion",
  },
  {
    label: "XB",
    value: "XB",
    brand: "Scion",
  },
  {
    label: "XD",
    value: "XD",
    brand: "Scion",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Seat",
  },
  {
    label: "Alhambra",
    value: "Alhambra",
    brand: "Seat",
  },
  {
    label: "Altea",
    value: "Altea",
    brand: "Seat",
  },
  {
    label: "Altea XL",
    value: "Altea XL",
    brand: "Seat",
  },
  {
    label: "Arona",
    value: "Arona",
    brand: "Seat",
  },
  {
    label: "Arosa",
    value: "Arosa",
    brand: "Seat",
  },
  {
    label: "Ateca",
    value: "Ateca",
    brand: "Seat",
  },
  {
    label: "Cordoba",
    value: "Cordoba",
    brand: "Seat",
  },
  {
    label: "Exeo",
    value: "Exeo",
    brand: "Seat",
  },
  {
    label: "Ibiza",
    value: "Ibiza",
    brand: "Seat",
  },
  {
    label: "Inca",
    value: "Inca",
    brand: "Seat",
  },
  {
    label: "Leon",
    value: "Leon",
    brand: "Seat",
  },
  {
    label: "Malaga",
    value: "Malaga",
    brand: "Seat",
  },
  {
    label: "Marbella",
    value: "Marbella",
    brand: "Seat",
  },
  {
    label: "Mii",
    value: "Mii",
    brand: "Seat",
  },
  {
    label: "Tarraco",
    value: "Tarraco",
    brand: "Seat",
  },
  {
    label: "Terra",
    value: "Terra",
    brand: "Seat",
  },
  {
    label: "Toledo",
    value: "Toledo",
    brand: "Seat",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Secma",
  },
  {
    label: "Extrem 500",
    value: "Extrem 500",
    brand: "Secma",
  },
  {
    label: "F16",
    value: "F16",
    brand: "Secma",
  },
  {
    label: "Fun Family",
    value: "Fun Family",
    brand: "Secma",
  },
  {
    label: "3",
    value: "3",
    brand: "Seres",
  },
  {
    label: "SF5",
    value: "SF5",
    brand: "Seres",
  },
  {
    label: "SF7",
    value: "SF7",
    brand: "Seres",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Shuanghuan",
  },
  {
    label: "CEO",
    value: "CEO",
    brand: "Shuanghuan",
  },
  {
    label: "Sceo",
    value: "Sceo",
    brand: "Shuanghuan",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Skoda",
  },
  {
    label: "105",
    value: "105",
    brand: "Skoda",
  },
  {
    label: "120",
    value: "120",
    brand: "Skoda",
  },
  {
    label: "130",
    value: "130",
    brand: "Skoda",
  },
  {
    label: "135",
    value: "135",
    brand: "Skoda",
  },
  {
    label: "Citigo",
    value: "Citigo",
    brand: "Skoda",
  },
  {
    label: "Enyaq iV",
    value: "Enyaq iV",
    brand: "Skoda",
  },
  {
    label: "Fabia",
    value: "Fabia",
    brand: "Skoda",
  },
  {
    label: "Fabia Scout",
    value: "Fabia Scout",
    brand: "Skoda",
  },
  {
    label: "Favorit",
    value: "Favorit",
    brand: "Skoda",
  },
  {
    label: "Felicia",
    value: "Felicia",
    brand: "Skoda",
  },
  {
    label: "Forman",
    value: "Forman",
    brand: "Skoda",
  },
  {
    label: "Kamiq",
    value: "Kamiq",
    brand: "Skoda",
  },
  {
    label: "Karoq",
    value: "Karoq",
    brand: "Skoda",
  },
  {
    label: "Kodiaq",
    value: "Kodiaq",
    brand: "Skoda",
  },
  {
    label: "Octavia",
    value: "Octavia",
    brand: "Skoda",
  },
  {
    label: "Octavia Scout",
    value: "Octavia Scout",
    brand: "Skoda",
  },
  {
    label: "Pick Up",
    value: "Pick Up",
    brand: "Skoda",
  },
  {
    label: "Praktik",
    value: "Praktik",
    brand: "Skoda",
  },
  {
    label: "Rapid",
    value: "Rapid",
    brand: "Skoda",
  },
  {
    label: "Roomster",
    value: "Roomster",
    brand: "Skoda",
  },
  {
    label: "Roomster Scout",
    value: "Roomster Scout",
    brand: "Skoda",
  },
  {
    label: "Scala",
    value: "Scala",
    brand: "Skoda",
  },
  {
    label: "Superb",
    value: "Superb",
    brand: "Skoda",
  },
  {
    label: "Yeti",
    value: "Yeti",
    brand: "Skoda",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Skywell",
  },
  {
    label: "ET5",
    value: "ET5",
    brand: "Skywell",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Smart",
  },
  {
    label: "City",
    value: "City",
    brand: "Smart",
  },
  {
    label: "Coupe",
    value: "Coupe",
    brand: "Smart",
  },
  {
    label: "Crossblade",
    value: "Crossblade",
    brand: "Smart",
  },
  {
    label: "Forfour",
    value: "Forfour",
    brand: "Smart",
  },
  {
    label: "Fortwo",
    value: "Fortwo",
    brand: "Smart",
  },
  {
    label: "Fortwo Coupe",
    value: "Fortwo Coupe",
    brand: "Smart",
  },
  {
    label: "Fortwo Coupe BRABUS",
    value: "Fortwo Coupe BRABUS",
    brand: "Smart",
  },
  {
    label: "MCC",
    value: "MCC",
    brand: "Smart",
  },
  {
    label: "Roadster",
    value: "Roadster",
    brand: "Smart",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Spartan",
  },
  {
    label: "Starcraft",
    value: "Starcraft",
    brand: "Spartan",
  },
  {
    label: "C8 Laviolette",
    value: "C8 Laviolette",
    brand: "Spyker",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "SsangYong",
  },
  {
    label: "Actyon",
    value: "Actyon",
    brand: "SsangYong",
  },
  {
    label: "Actyon Sports",
    value: "Actyon Sports",
    brand: "SsangYong",
  },
  {
    label: "Chairman",
    value: "Chairman",
    brand: "SsangYong",
  },
  {
    label: "Family",
    value: "Family",
    brand: "SsangYong",
  },
  {
    label: "Istana",
    value: "Istana",
    brand: "SsangYong",
  },
  {
    label: "Korando",
    value: "Korando",
    brand: "SsangYong",
  },
  {
    label: "Kyron",
    value: "Kyron",
    brand: "SsangYong",
  },
  {
    label: "Musso",
    value: "Musso",
    brand: "SsangYong",
  },
  {
    label: "Rexton",
    value: "Rexton",
    brand: "SsangYong",
  },
  {
    label: "Rodius",
    value: "Rodius",
    brand: "SsangYong",
  },
  {
    label: "Tivoli",
    value: "Tivoli",
    brand: "SsangYong",
  },
  {
    label: "XLV",
    value: "XLV",
    brand: "SsangYong",
  },
  {
    label: "Champion",
    value: "Champion",
    brand: "Studebaker",
  },
  {
    label: "Conestoga",
    value: "Conestoga",
    brand: "Studebaker",
  },
  {
    label: "Daytona",
    value: "Daytona",
    brand: "Studebaker",
  },
  {
    label: "Dictator",
    value: "Dictator",
    brand: "Studebaker",
  },
  {
    label: "Golden Hawk",
    value: "Golden Hawk",
    brand: "Studebaker",
  },
  {
    label: "Gran Turismo Hawk",
    value: "Gran Turismo Hawk",
    brand: "Studebaker",
  },
  {
    label: "Light Four",
    value: "Light Four",
    brand: "Studebaker",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Subaru",
  },
  {
    label: "Ascent",
    value: "Ascent",
    brand: "Subaru",
  },
  {
    label: "Baja",
    value: "Baja",
    brand: "Subaru",
  },
  {
    label: "BRZ",
    value: "BRZ",
    brand: "Subaru",
  },
  {
    label: "Crosstrek",
    value: "Crosstrek",
    brand: "Subaru",
  },
  {
    label: "Forester",
    value: "Forester",
    brand: "Subaru",
  },
  {
    label: "Impreza",
    value: "Impreza",
    brand: "Subaru",
  },
  {
    label: "Impreza  WRX",
    value: "Impreza  WRX",
    brand: "Subaru",
  },
  {
    label: "Justy",
    value: "Justy",
    brand: "Subaru",
  },
  {
    label: "Legacy",
    value: "Legacy",
    brand: "Subaru",
  },
  {
    label: "Leone",
    value: "Leone",
    brand: "Subaru",
  },
  {
    label: "Levorg",
    value: "Levorg",
    brand: "Subaru",
  },
  {
    label: "Libero",
    value: "Libero",
    brand: "Subaru",
  },
  {
    label: "Outback",
    value: "Outback",
    brand: "Subaru",
  },
  {
    label: "R1",
    value: "R1",
    brand: "Subaru",
  },
  {
    label: "Solterra",
    value: "Solterra",
    brand: "Subaru",
  },
  {
    label: "SVX",
    value: "SVX",
    brand: "Subaru",
  },
  {
    label: "Trezia",
    value: "Trezia",
    brand: "Subaru",
  },
  {
    label: "Tribeca",
    value: "Tribeca",
    brand: "Subaru",
  },
  {
    label: "Vivio",
    value: "Vivio",
    brand: "Subaru",
  },
  {
    label: "XT Coupe",
    value: "XT Coupe",
    brand: "Subaru",
  },
  {
    label: "XV",
    value: "XV",
    brand: "Subaru",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Suzuki",
  },
  {
    label: "Aerio",
    value: "Aerio",
    brand: "Suzuki",
  },
  {
    label: "Alto",
    value: "Alto",
    brand: "Suzuki",
  },
  {
    label: "Baleno",
    value: "Baleno",
    brand: "Suzuki",
  },
  {
    label: "Cappuccino",
    value: "Cappuccino",
    brand: "Suzuki",
  },
  {
    label: "Carry",
    value: "Carry",
    brand: "Suzuki",
  },
  {
    label: "Celerio",
    value: "Celerio",
    brand: "Suzuki",
  },
  {
    label: "Esteem",
    value: "Esteem",
    brand: "Suzuki",
  },
  {
    label: "Forenza",
    value: "Forenza",
    brand: "Suzuki",
  },
  {
    label: "Grand Vitara",
    value: "Grand Vitara",
    brand: "Suzuki",
  },
  {
    label: "Ignis",
    value: "Ignis",
    brand: "Suzuki",
  },
  {
    label: "Jimny",
    value: "Jimny",
    brand: "Suzuki",
  },
  {
    label: "Kei",
    value: "Kei",
    brand: "Suzuki",
  },
  {
    label: "Kizashi  ",
    value: "Kizashi  ",
    brand: "Suzuki",
  },
  {
    label: "Liana",
    value: "Liana",
    brand: "Suzuki",
  },
  {
    label: "LJ",
    value: "LJ",
    brand: "Suzuki",
  },
  {
    label: "Reno",
    value: "Reno",
    brand: "Suzuki",
  },
  {
    label: "Samurai",
    value: "Samurai",
    brand: "Suzuki",
  },
  {
    label: "SJ",
    value: "SJ",
    brand: "Suzuki",
  },
  {
    label: "Splash",
    value: "Splash",
    brand: "Suzuki",
  },
  {
    label: "Super-Carry",
    value: "Super-Carry",
    brand: "Suzuki",
  },
  {
    label: "Swace",
    value: "Swace",
    brand: "Suzuki",
  },
  {
    label: "Swift",
    value: "Swift",
    brand: "Suzuki",
  },
  {
    label: "SX4",
    value: "SX4",
    brand: "Suzuki",
  },
  {
    label: "SX4 S-Cross",
    value: "SX4 S-Cross",
    brand: "Suzuki",
  },
  {
    label: "Twin",
    value: "Twin",
    brand: "Suzuki",
  },
  {
    label: "Verona",
    value: "Verona",
    brand: "Suzuki",
  },
  {
    label: "Vitara",
    value: "Vitara",
    brand: "Suzuki",
  },
  {
    label: "Wagon R+",
    value: "Wagon R+",
    brand: "Suzuki",
  },
  {
    label: "X-90",
    value: "X-90",
    brand: "Suzuki",
  },
  {
    label: "XL7",
    value: "XL7",
    brand: "Suzuki",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Talbot",
  },
  {
    label: "Andere",
    value: "Andere",
    brand: "Talbot",
  },
  {
    label: "Horizon",
    value: "Horizon",
    brand: "Talbot",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Tartan",
  },
  {
    label: "Prancer",
    value: "Prancer",
    brand: "Tartan",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Tata",
  },
  {
    label: "Indica",
    value: "Indica",
    brand: "Tata",
  },
  {
    label: "Indigo",
    value: "Indigo",
    brand: "Tata",
  },
  {
    label: "Nano",
    value: "Nano",
    brand: "Tata",
  },
  {
    label: "Safari",
    value: "Safari",
    brand: "Tata",
  },
  {
    label: "Sumo",
    value: "Sumo",
    brand: "Tata",
  },
  {
    label: "Telcoline",
    value: "Telcoline",
    brand: "Tata",
  },
  {
    label: "Telcosport",
    value: "Telcosport",
    brand: "Tata",
  },
  {
    label: "Xenon",
    value: "Xenon",
    brand: "Tata",
  },
  {
    label: "603",
    value: "603",
    brand: "Tatra",
  },
  {
    label: "613",
    value: "613",
    brand: "Tatra",
  },
  {
    label: "700",
    value: "700",
    brand: "Tatra",
  },
  {
    label: "77",
    value: "77",
    brand: "Tatra",
  },
  {
    label: "Zero",
    value: "Zero",
    brand: "Tazzari",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Think",
  },
  {
    label: "City",
    value: "City",
    brand: "Think",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Toyota",
  },
  {
    label: "4Runner",
    value: "4Runner",
    brand: "Toyota",
  },
  {
    label: "Allion",
    value: "Allion",
    brand: "Toyota",
  },
  {
    label: "Alphard",
    value: "Alphard",
    brand: "Toyota",
  },
  {
    label: "Auris",
    value: "Auris",
    brand: "Toyota",
  },
  {
    label: "Avalon",
    value: "Avalon",
    brand: "Toyota",
  },
  {
    label: "Avensis",
    value: "Avensis",
    brand: "Toyota",
  },
  {
    label: "Avensis Verso",
    value: "Avensis Verso",
    brand: "Toyota",
  },
  {
    label: "Aygo",
    value: "Aygo",
    brand: "Toyota",
  },
  {
    label: "Aygo X",
    value: "Aygo X",
    brand: "Toyota",
  },
  {
    label: "bZ4X",
    value: "bZ4X",
    brand: "Toyota",
  },
  {
    label: "C-HR",
    value: "C-HR",
    brand: "Toyota",
  },
  {
    label: "Camry",
    value: "Camry",
    brand: "Toyota",
  },
  {
    label: "Carina",
    value: "Carina",
    brand: "Toyota",
  },
  {
    label: "Celica",
    value: "Celica",
    brand: "Toyota",
  },
  {
    label: "Chaser",
    value: "Chaser",
    brand: "Toyota",
  },
  {
    label: "Corolla",
    value: "Corolla",
    brand: "Toyota",
  },
  {
    label: "Corolla Cross",
    value: "Corolla Cross",
    brand: "Toyota",
  },
  {
    label: "Corolla Verso",
    value: "Corolla Verso",
    brand: "Toyota",
  },
  {
    label: "Cressida",
    value: "Cressida",
    brand: "Toyota",
  },
  {
    label: "Crown",
    value: "Crown",
    brand: "Toyota",
  },
  {
    label: "Dyna",
    value: "Dyna",
    brand: "Toyota",
  },
  {
    label: "Echo",
    value: "Echo",
    brand: "Toyota",
  },
  {
    label: "Estima",
    value: "Estima",
    brand: "Toyota",
  },
  {
    label: "FJ Cruiser",
    value: "FJ Cruiser",
    brand: "Toyota",
  },
  {
    label: "GR 86",
    value: "GR 86",
    brand: "Toyota",
  },
  {
    label: "GT 86",
    value: "GT 86",
    brand: "Toyota",
  },
  {
    label: "Hiace",
    value: "Hiace",
    brand: "Toyota",
  },
  {
    label: "Highlander",
    value: "Highlander",
    brand: "Toyota",
  },
  {
    label: "Hilux",
    value: "Hilux",
    brand: "Toyota",
  },
  {
    label: "Ipsum",
    value: "Ipsum",
    brand: "Toyota",
  },
  {
    label: "iQ",
    value: "iQ",
    brand: "Toyota",
  },
  {
    label: "Kluger V",
    value: "Kluger V",
    brand: "Toyota",
  },
  {
    label: "Land Cruiser",
    value: "Land Cruiser",
    brand: "Toyota",
  },
  {
    label: "Liteace",
    value: "Liteace",
    brand: "Toyota",
  },
  {
    label: "Matrix",
    value: "Matrix",
    brand: "Toyota",
  },
  {
    label: "Mega Cruiser",
    value: "Mega Cruiser",
    brand: "Toyota",
  },
  {
    label: "Mirai",
    value: "Mirai",
    brand: "Toyota",
  },
  {
    label: "MR 2",
    value: "MR 2",
    brand: "Toyota",
  },
  {
    label: "Paseo",
    value: "Paseo",
    brand: "Toyota",
  },
  {
    label: "Picnic",
    value: "Picnic",
    brand: "Toyota",
  },
  {
    label: "Previa",
    value: "Previa",
    brand: "Toyota",
  },
  {
    label: "Prius",
    value: "Prius",
    brand: "Toyota",
  },
  {
    label: "Prius C",
    value: "Prius C",
    brand: "Toyota",
  },
  {
    label: "Prius Prime",
    value: "Prius Prime",
    brand: "Toyota",
  },
  {
    label: "Prius+",
    value: "Prius+",
    brand: "Toyota",
  },
  {
    label: "Proace",
    value: "Proace",
    brand: "Toyota",
  },
  {
    label: "Proace City",
    value: "Proace City",
    brand: "Toyota",
  },
  {
    label: "Proace City Verso",
    value: "Proace City Verso",
    brand: "Toyota",
  },
  {
    label: "Proace Verso",
    value: "Proace Verso",
    brand: "Toyota",
  },
  {
    label: "RAV4",
    value: "RAV4",
    brand: "Toyota",
  },
  {
    label: "Rocky",
    value: "Rocky",
    brand: "Toyota",
  },
  {
    label: "Rush",
    value: "Rush",
    brand: "Toyota",
  },
  {
    label: "Sequoia",
    value: "Sequoia",
    brand: "Toyota",
  },
  {
    label: "Sera",
    value: "Sera",
    brand: "Toyota",
  },
  {
    label: "Sienna",
    value: "Sienna",
    brand: "Toyota",
  },
  {
    label: "Soarer",
    value: "Soarer",
    brand: "Toyota",
  },
  {
    label: "Solara",
    value: "Solara",
    brand: "Toyota",
  },
  {
    label: "Starlet",
    value: "Starlet",
    brand: "Toyota",
  },
  {
    label: "Supra",
    value: "Supra",
    brand: "Toyota",
  },
  {
    label: "Tacoma",
    value: "Tacoma",
    brand: "Toyota",
  },
  {
    label: "Tercel",
    value: "Tercel",
    brand: "Toyota",
  },
  {
    label: "Tundra",
    value: "Tundra",
    brand: "Toyota",
  },
  {
    label: "Urban Cruiser",
    value: "Urban Cruiser",
    brand: "Toyota",
  },
  {
    label: "Venza",
    value: "Venza",
    brand: "Toyota",
  },
  {
    label: "Verso",
    value: "Verso",
    brand: "Toyota",
  },
  {
    label: "Verso-S",
    value: "Verso-S",
    brand: "Toyota",
  },
  {
    label: "Yaris",
    value: "Yaris",
    brand: "Toyota",
  },
  {
    label: "Yaris Cross",
    value: "Yaris Cross",
    brand: "Toyota",
  },
  {
    label: "Yaris Verso",
    value: "Yaris Verso",
    brand: "Toyota",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Trabant",
  },
  {
    label: "601",
    value: "601",
    brand: "Trabant",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Triumph",
  },
  {
    label: "Dolomite",
    value: "Dolomite",
    brand: "Triumph",
  },
  {
    label: "Moss",
    value: "Moss",
    brand: "Triumph",
  },
  {
    label: "Spitfire",
    value: "Spitfire",
    brand: "Triumph",
  },
  {
    label: "TR6",
    value: "TR6",
    brand: "Triumph",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "UAZ",
  },
  {
    label: "2101",
    value: "2101",
    brand: "UAZ",
  },
  {
    label: "2103",
    value: "2103",
    brand: "UAZ",
  },
  {
    label: "2206",
    value: "2206",
    brand: "UAZ",
  },
  {
    label: "3151",
    value: "3151",
    brand: "UAZ",
  },
  {
    label: "3303",
    value: "3303",
    brand: "UAZ",
  },
  {
    label: "3741",
    value: "3741",
    brand: "UAZ",
  },
  {
    label: "3909",
    value: "3909",
    brand: "UAZ",
  },
  {
    label: "3962",
    value: "3962",
    brand: "UAZ",
  },
  {
    label: "452",
    value: "452",
    brand: "UAZ",
  },
  {
    label: "469",
    value: "469",
    brand: "UAZ",
  },
  {
    label: "Hunter",
    value: "Hunter",
    brand: "UAZ",
  },
  {
    label: "Patriot",
    value: "Patriot",
    brand: "UAZ",
  },
  {
    label: "Pickup",
    value: "Pickup",
    brand: "UAZ",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Vauxhall",
  },
  {
    label: "Astra",
    value: "Astra",
    brand: "Vauxhall",
  },
  {
    label: "Insignia",
    value: "Insignia",
    brand: "Vauxhall",
  },
  {
    label: "Vectra",
    value: "Vectra",
    brand: "Vauxhall",
  },
  {
    label: "210",
    value: "210",
    brand: "Venturi",
  },
  {
    label: "260",
    value: "260",
    brand: "Venturi",
  },
  {
    label: "300",
    value: "300",
    brand: "Venturi",
  },
  {
    label: "400",
    value: "400",
    brand: "Venturi",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Volkswagen",
  },
  {
    label: "Amarok",
    value: "Amarok",
    brand: "Volkswagen",
  },
  {
    label: "Arteon",
    value: "Arteon",
    brand: "Volkswagen",
  },
  {
    label: "Atlas",
    value: "Atlas",
    brand: "Volkswagen",
  },
  {
    label: "Beetle",
    value: "Beetle",
    brand: "Volkswagen",
  },
  {
    label: "Bora",
    value: "Bora",
    brand: "Volkswagen",
  },
  {
    label: "Buggy",
    value: "Buggy",
    brand: "Volkswagen",
  },
  {
    label: "Caddy",
    value: "Caddy",
    brand: "Volkswagen",
  },
  {
    label: "Caddy Maxi",
    value: "Caddy Maxi",
    brand: "Volkswagen",
  },
  {
    label: "California",
    value: "California",
    brand: "Volkswagen",
  },
  {
    label: "Caravelle",
    value: "Caravelle",
    brand: "Volkswagen",
  },
  {
    label: "CC",
    value: "CC",
    brand: "Volkswagen",
  },
  {
    label: "Corrado",
    value: "Corrado",
    brand: "Volkswagen",
  },
  {
    label: "Crafter",
    value: "Crafter",
    brand: "Volkswagen",
  },
  {
    label: "Cross Touran",
    value: "Cross Touran",
    brand: "Volkswagen",
  },
  {
    label: "Derby",
    value: "Derby",
    brand: "Volkswagen",
  },
  {
    label: "Eos",
    value: "Eos",
    brand: "Volkswagen",
  },
  {
    label: "Fox",
    value: "Fox",
    brand: "Volkswagen",
  },
  {
    label: "Golf",
    value: "Golf",
    brand: "Volkswagen",
  },
  {
    label: "Golf Alltrack",
    value: "Golf Alltrack",
    brand: "Volkswagen",
  },
  {
    label: "Golf Plus",
    value: "Golf Plus",
    brand: "Volkswagen",
  },
  {
    label: "Golf Sportsvan",
    value: "Golf Sportsvan",
    brand: "Volkswagen",
  },
  {
    label: "Golf SportWagen",
    value: "Golf SportWagen",
    brand: "Volkswagen",
  },
  {
    label: "Grand California",
    value: "Grand California",
    brand: "Volkswagen",
  },
  {
    label: "ID.3",
    value: "ID.3",
    brand: "Volkswagen",
  },
  {
    label: "ID.4",
    value: "ID.4",
    brand: "Volkswagen",
  },
  {
    label: "ID.5",
    value: "ID.5",
    brand: "Volkswagen",
  },
  {
    label: "ID.6",
    value: "ID.6",
    brand: "Volkswagen",
  },
  {
    label: "ID. Buzz",
    value: "ID. Buzz",
    brand: "Volkswagen",
  },
  {
    label: "Iltia",
    value: "Iltia",
    brand: "Volkswagen",
  },
  {
    label: "Jetta",
    value: "Jetta",
    brand: "Volkswagen",
  },
  {
    label: "Kaefer",
    value: "Kaefer",
    brand: "Volkswagen",
  },
  {
    label: "Karmann Ghia",
    value: "Karmann Ghia",
    brand: "Volkswagen",
  },
  {
    label: "Kombi",
    value: "Kombi",
    brand: "Volkswagen",
  },
  {
    label: "LT",
    value: "LT",
    brand: "Volkswagen",
  },
  {
    label: "LT46",
    value: "LT46",
    brand: "Volkswagen",
  },
  {
    label: "Lupo",
    value: "Lupo",
    brand: "Volkswagen",
  },
  {
    label: "Multivan",
    value: "Multivan",
    brand: "Volkswagen",
  },
  {
    label: "Passat",
    value: "Passat",
    brand: "Volkswagen",
  },
  {
    label: "Passat Alltrack",
    value: "Passat Alltrack",
    brand: "Volkswagen",
  },
  {
    label: "Passat CC",
    value: "Passat CC",
    brand: "Volkswagen",
  },
  {
    label: "Phaeton",
    value: "Phaeton",
    brand: "Volkswagen",
  },
  {
    label: "Pick-up",
    value: "Pick-up",
    brand: "Volkswagen",
  },
  {
    label: "Polo",
    value: "Polo",
    brand: "Volkswagen",
  },
  {
    label: "Polo Cross",
    value: "Polo Cross",
    brand: "Volkswagen",
  },
  {
    label: "Rabbit",
    value: "Rabbit",
    brand: "Volkswagen",
  },
  {
    label: "Routan",
    value: "Routan",
    brand: "Volkswagen",
  },
  {
    label: "Santana",
    value: "Santana",
    brand: "Volkswagen",
  },
  {
    label: "Scirocco",
    value: "Scirocco",
    brand: "Volkswagen",
  },
  {
    label: "Sharan",
    value: "Sharan",
    brand: "Volkswagen",
  },
  {
    label: "T-Cross",
    value: "T-Cross",
    brand: "Volkswagen",
  },
  {
    label: "T-Roc",
    value: "T-Roc",
    brand: "Volkswagen",
  },
  {
    label: "Taigo",
    value: "Taigo",
    brand: "Volkswagen",
  },
  {
    label: "Taos",
    value: "Taos",
    brand: "Volkswagen",
  },
  {
    label: "Taro",
    value: "Taro",
    brand: "Volkswagen",
  },
  {
    label: "Tiguan",
    value: "Tiguan",
    brand: "Volkswagen",
  },
  {
    label: "Tiguan Allspace",
    value: "Tiguan Allspace",
    brand: "Volkswagen",
  },
  {
    label: "Touareg",
    value: "Touareg",
    brand: "Volkswagen",
  },
  {
    label: "Touran",
    value: "Touran",
    brand: "Volkswagen",
  },
  {
    label: "Transporter",
    value: "Transporter",
    brand: "Volkswagen",
  },
  {
    label: "Type 3 Fastback",
    value: "Type 3 Fastback",
    brand: "Volkswagen",
  },
  {
    label: "Up",
    value: "Up",
    brand: "Volkswagen",
  },
  {
    label: "Vento",
    value: "Vento",
    brand: "Volkswagen",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Volvo",
  },
  {
    label: "240",
    value: "240",
    brand: "Volvo",
  },
  {
    label: "244",
    value: "244",
    brand: "Volvo",
  },
  {
    label: "245",
    value: "245",
    brand: "Volvo",
  },
  {
    label: "262",
    value: "262",
    brand: "Volvo",
  },
  {
    label: "264",
    value: "264",
    brand: "Volvo",
  },
  {
    label: "340",
    value: "340",
    brand: "Volvo",
  },
  {
    label: "360",
    value: "360",
    brand: "Volvo",
  },
  {
    label: "440",
    value: "440",
    brand: "Volvo",
  },
  {
    label: "460",
    value: "460",
    brand: "Volvo",
  },
  {
    label: "480",
    value: "480",
    brand: "Volvo",
  },
  {
    label: "740",
    value: "740",
    brand: "Volvo",
  },
  {
    label: "744",
    value: "744",
    brand: "Volvo",
  },
  {
    label: "745",
    value: "745",
    brand: "Volvo",
  },
  {
    label: "760",
    value: "760",
    brand: "Volvo",
  },
  {
    label: "780",
    value: "780",
    brand: "Volvo",
  },
  {
    label: "850",
    value: "850",
    brand: "Volvo",
  },
  {
    label: "855",
    value: "855",
    brand: "Volvo",
  },
  {
    label: "940",
    value: "940",
    brand: "Volvo",
  },
  {
    label: "944",
    value: "944",
    brand: "Volvo",
  },
  {
    label: "945",
    value: "945",
    brand: "Volvo",
  },
  {
    label: "960",
    value: "960",
    brand: "Volvo",
  },
  {
    label: "965",
    value: "965",
    brand: "Volvo",
  },
  {
    label: "Amazon",
    value: "Amazon",
    brand: "Volvo",
  },
  {
    label: "C30",
    value: "C30",
    brand: "Volvo",
  },
  {
    label: "C303",
    value: "C303",
    brand: "Volvo",
  },
  {
    label: "C40 Recharge",
    value: "C40 Recharge",
    brand: "Volvo",
  },
  {
    label: "C70",
    value: "C70",
    brand: "Volvo",
  },
  {
    label: "Polar",
    value: "Polar",
    brand: "Volvo",
  },
  {
    label: "Polestar",
    value: "Polestar",
    brand: "Volvo",
  },
  {
    label: "S40",
    value: "S40",
    brand: "Volvo",
  },
  {
    label: "S60",
    value: "S60",
    brand: "Volvo",
  },
  {
    label: "S60 Cross Country",
    value: "S60 Cross Country",
    brand: "Volvo",
  },
  {
    label: "S70",
    value: "S70",
    brand: "Volvo",
  },
  {
    label: "S80",
    value: "S80",
    brand: "Volvo",
  },
  {
    label: "S90",
    value: "S90",
    brand: "Volvo",
  },
  {
    label: "V40",
    value: "V40",
    brand: "Volvo",
  },
  {
    label: "V40 Cross Country",
    value: "V40 Cross Country",
    brand: "Volvo",
  },
  {
    label: "V50",
    value: "V50",
    brand: "Volvo",
  },
  {
    label: "V60",
    value: "V60",
    brand: "Volvo",
  },
  {
    label: "V60 Cross Country",
    value: "V60 Cross Country",
    brand: "Volvo",
  },
  {
    label: "V70",
    value: "V70",
    brand: "Volvo",
  },
  {
    label: "V90",
    value: "V90",
    brand: "Volvo",
  },
  {
    label: "V90 Cross Country",
    value: "V90 Cross Country",
    brand: "Volvo",
  },
  {
    label: "XC40",
    value: "XC40",
    brand: "Volvo",
  },
  {
    label: "XC60",
    value: "XC60",
    brand: "Volvo",
  },
  {
    label: "XC70",
    value: "XC70",
    brand: "Volvo",
  },
  {
    label: "XC90",
    value: "XC90",
    brand: "Volvo",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Wanderer",
  },
  {
    label: "W 25K",
    value: "W 25K",
    brand: "Wanderer",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Wartburg",
  },
  {
    label: "311",
    value: "311",
    brand: "Wartburg",
  },
  {
    label: "353",
    value: "353",
    brand: "Wartburg",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "ZAZ",
  },
  {
    label: "965",
    value: "965",
    brand: "ZAZ",
  },
  {
    label: "966",
    value: "966",
    brand: "ZAZ",
  },
  {
    label: "968",
    value: "968",
    brand: "ZAZ",
  },
  {
    label: "968A",
    value: "968A",
    brand: "ZAZ",
  },
  {
    label: "968M",
    value: "968M",
    brand: "ZAZ",
  },
  {
    label: "Tavrija",
    value: "Tavrija",
    brand: "ZAZ",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Zimmer",
  },
  {
    label: "Golden Spirit",
    value: "Golden Spirit",
    brand: "Zimmer",
  },
  {
    label: "-Other-",
    value: "-Other-",
    brand: "Tesla",
  },
  {
    label: "Andere",
    value: "Andere",
    brand: "Tesla",
  },
  {
    label: "Model 3",
    value: "Model 3",
    brand: "Tesla",
  },
  {
    label: "Model S",
    value: "Model S",
    brand: "Tesla",
  },
  {
    label: "Model X",
    value: "Model X",
    brand: "Tesla",
  },
  {
    label: "Model Y",
    value: "Model Y",
    brand: "Tesla",
  },
  {
    label: "Roadster",
    value: "Roadster",
    brand: "Tesla",
  },
].filter((f) => !f.label?.toLowerCase().includes("other"));
