import axios from "axios";
import { BACKEND_URL, PROD_URL } from "../util/const";
import { toast } from "react-toastify"; // Import react-toastify

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: PROD_URL,
  headers: {
    "ngrok-skip-browser-warning": "true",
  },
});

// Add a request interceptor to set Bearer token from localStorage
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle success and error responses
axiosInstance.interceptors.response.use(
  (response) => {
    // Display toast message for success response
    //toast.success("Request successful");
    return response;
  },
  (error) => {
    console.error(error);

    toast.error(error?.response?.data?.error || "Error");
    return Promise.reject(error);
  }
);

export default axiosInstance;
