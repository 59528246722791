import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import CopyToClipboard from "./tooltip";

const TelegramComponent = ({ onSubmit, telegram }) => {
  const [userInput, setUserInput] = useState(telegram?.username);
  const [isVerified, setIsVerified] = useState(telegram?.verified);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    if (telegram) {
      setIsVerified(telegram?.verified);
    }
  }, [telegram]);
  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.post("/telegram-data", {
        username: userInput,
      });

      if (response.data.message) {
        setMessage(response.data.message);
      }
      onSubmit && onSubmit();
    } catch (error) {
      console.error("Error updating username:", error);
      setMessage("Error updating username");
    }
  };

  return (
    <div className="p-4 bg-gray-800 text-white rounded shadow-md max-w-sm mx-auto">
      <div className="flex flex-row justify-between flex-wrap">
        <h2 className="text-xl font-semibold mb-4">Telegram Settings</h2>
        <div className="flex items-center mb-4">
          <div
            className={`w-3 h-3 rounded-full ${
              isVerified ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          <span className="ml-2 text-sm">
            {isVerified ? "Verified" : "Awaiting username"}
          </span>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Telegram Username
        </label>
        <input
          disabled={telegram?.verified}
          type="text"
          placeholder="username"
          value={telegram?.verified ? telegram?.username : userInput}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-600 bg-gray-700 text-white rounded focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      {!telegram?.verified && (
        <button
          disabled={telegram?.verified}
          onClick={handleSubmit}
          className="w-full px-3 py-2 bg-primary text-white  rounded hover:bg-blue-600 focus:outline-none focus:ring transition-all"
        >
          Save
        </button>
      )}
      {message && <div className="mt-4 text-sm text-gray-300">{message}</div>}
      {!isVerified && telegram?.username && (
        <div className="mt-4 text-sm text-gray-300">
          Please verify your account by entering your code at our Telegram bot:
          <a
            href="https://t.me/topautodealseu_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline ml-1"
          >
            @topautodealseu_bot
          </a>
          <div className="text-center mt-3 border-t border-primary pt-3">
            Give him your code via the command{" "}
            <span className="text-green-500">/activate </span>:{" "}
            <div className="mx-auto mt-1 flex items-center justify-center">
              <CopyToClipboard
                showValue
                value={`/activate ${localStorage.getItem("token")}` || ""}
              />
            </div>
            <div> And after successful activation refresh this page </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TelegramComponent;
